.ng-select{
  .ng-select-container{
    border: @input-border!important;
    border-radius: 0.8rem!important;
    height: @input-height!important;

    .auto-height &{
      height: auto !important; 
    }
    
    .ng-value-container{
      .ng-value{
        .font-size(1.4);
      }

      .ng-input{
        top: 0!important;

        > input{
          margin: 0;
          padding-left: 0;
        }
      }
    }
  }

  &.ng-select-multiple .ng-select-container .ng-value-container{
    padding-top: 0!important;

    .ng-value{
      border: 1px solid #c2e0ff;

      .font-size(1.4)!important;
      background-color: @body-background!important;

      .ng-value-label{
        line-height: 2.5rem;
      }
    }

    .ng-input{
      padding-bottom: 0!important;
    }
  }
}

.field--autocomplete-small {
  .ng-select{
    height: 3rem !important;
    margin-top: 0.2rem;

    .ng-select-container {
      border-radius: 0 !important;
      height: 3rem !important;
      min-height: 3rem;

      .ng-value-container{
        .ng-value{
          .font-size(1.4);
          padding-right: 3.6rem;
          .truncate();
        }

        .ng-input{
          top: 0!important;

          > input{
            margin: 0;
            padding-left: 0;
            height: 2rem;
          }
        }
      }
    }
  }
}

.ng-dropdown-panel-items{
  min-height: 11rem;

  .ng-option{
    .font-size-small;
    color: @dark!important;

    &-disabled{
      opacity: 0.5;
      background-color: @grey!important;
    }

    &-marked{
      background-color: fade(@grey, 60%)!important;
    }

    &-selected{
      background-color: @primary!important;
    }

    span{
      white-space: normal;
    }
  }
}
