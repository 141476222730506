#page-welcome {
  //width: 100%;
  min-height: 100%;
  background-color: @dark;
  background-image: url('/assets/images/iraupen-welcome-bg.jpg');
  background-position: right center;
  background-size: cover;
  display: flex;

  * {
    color: @primary;
  }

  .container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    //row-gap: 6.5%;

    &:after, &:before {
      //display: none;
    }
  }

  .heading__title {
    font-size: 2.1rem;
    .special-font;
  }

  .panels {
    margin-top: 2.8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 6.5rem;
    flex: 1 auto;

    @media @medium-down {
      margin-bottom: 4.5rem;
    }

    @media @small-down {
      margin-bottom: 3.5rem;
    }

    @media @medium-small-down {
      margin-bottom: 5rem;
      row-gap: 3.5rem;
      flex-direction: column;
    }

    &__title {
      font-size: 2.1rem;
      .special-font;
    }

    &__notifications {
      width: 70%;
      //height: 59rem;
      overflow: auto;
      padding: 2.8rem;
      background-color: @primary;

      @media @small-down {
        max-height: 60vh;
      }

      @media @medium-small-down {
        width: 100%;
        max-height: none;
        flex: 1 auto;
      }

      & * {
        color: @darker;
      }
    }

    &__column {
      width: 27.5%;
      display: flex;
      flex-wrap: wrap;
      align-content: stretch;

      @media @medium-small-down {
        width: 100%;
        justify-content: space-between;
        max-height: 25rem;
      }
    }

    &__documents {
      margin-bottom: 3.4rem;

      @media @medium-small-down {
        margin-bottom: 0;
      }
    }

    &__documents,
    &__tests {
      width: 100%;
      border: 3px solid @primary;
      //position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @media @medium-small-down {
        width: 47.5%;
        padding: 2.5rem;
      }
    }

    &__icon {
      // .center;
      text-align: center;
      margin: 3rem auto;

      span:before {
        font-size: 4.8rem;
        display: inline-block;
        margin-right: 0;
        margin-bottom: 1.5rem;
      }
    }
  }

  .notification {

    @media @small-down {
      &:last-child {
        //display: none;
      }
    }

    &__tel {

      span:before {
        //color: @darker;
        font-size: 1.8rem;
        padding: 0.8rem;
        border-radius: 50%;
        border: 1px solid;
        text-align: center;
      }
    }

  }

  .notification--empty {
    border-bottom: none;
    border-top: 1px solid;

    > td {
      text-align: center;
    }
  }

  .projects-state {

      &__container {
        display: flex;
        justify-content: space-between;
        padding: 5rem 0 3.5rem;

        @media @small-down {
          padding: 2.5rem 0;
          align-content: stretch;
        }
      }

      &__item {
        width: 22%;
        text-align: center;
      }

      &__number {
        font-size: 4.8rem;
        width: 13.5rem;
        height: 13.5rem;
        margin-bottom: 2.2rem;
        display: inline-flex;
        background-color: fade(#D0D0D0, 10%);
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        .special-font;


        @media @medium-down {
          font-size: 4.2rem;
          width: 12rem;
          height: 12rem;
        }

        @media @small-down {
          font-size: 3.8rem;
          width: 10rem;
          height: 10rem;
        }
      }

      &__title {
        text-transform: uppercase;
        font-size: 1.6rem;
        .bold-font;
      }

  }
}