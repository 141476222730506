/*
 * Float
 */

//Right
.right {
	float: right !important;
}

.clear-right-xlarge {

	@media @xlarge {
		float: none !important;
	}
}

.clear-right-large {

	@media @large {
		float: none !important;
	}
}

.clear-right-medium {

	@media @medium {
		float: none !important;
	}
}

.clear-right-small {

	@media @small {
		float: none !important;
	}
}

.clear-right-xsmall {

	@media @xsmall {
		float: none !important;
	}
}

//Left
.left {
	float: left !important;
}

.clear-left-xlarge {

	@media @xlarge {
		float: none !important;
	}
}

.clear-left-large {

	@media @large {
		float: none !important;
	}
}

.clear-left-medium {

	@media @medium {
		float: none !important;
	}
}

.clear-left-small {

	@media @small {
		float: none !important;
	}
}

.clear-left-xsmall {

	@media @xsmall {
		float: none !important;
	}
}