.boxes {
  display: flex;
  justify-content: space-between;
  gap: 1.8rem;
  width: 100%;
  margin-top: 2rem;

  .box {
    width: 25%;
    padding: 1rem 3.2rem;
    display: flex;
    column-gap: 13.5%;
    justify-content: space-between;
    align-items: center;



    * {
      color: @darker;
    }

    &__title {
      color: @darker;
      width: 20%;
      font-size: 1.4rem;
      line-height: 1.2;
      text-transform: uppercase;
      .regular-font;
    }

    &__number {
      font-size: 2.4rem;
      .bold-font;
    }

    &__data {
      width: 60%;
      flex: 1 auto;


      p {
        .font-size(1.4);
        display: flex;
        justify-content: space-between;
        column-gap: 2rem;
        margin: 0;

        span:last-child {
          text-align: right;
          flex: 1 auto;
        }

        &.margin-top-small {
          margin-top: 0.8rem;
        }
      }

    }

  }

  .box--simple {
    width: 30%;
    padding: 0;
    display: flex;
    column-gap: 2rem;
    justify-content: space-between;
    align-items: center;
  }

  .box--big {
    width: 35%;
  }

  .box--dark {
    color: @white;
    background: @dark;

    * {
      color:  @white;
    }
  }

  .box--grey {
    //background-color: fade(@dark, 20%);
    background-color: @alt-grey;
  }

  .box--primary  {
    background: @primary;
  }

  .box--text-large {
    * {
      font-size: 1.8rem;
    }

    .box__title {
      text-transform: none;
      .bold-font;
    }
  }

  .box--resume {
    width: 33%;
    column-gap: 5%;
    flex: 1 auto;

    .box__title {
      text-transform: none;
      font-size: 2rem;
      margin-bottom: 0.4rem;
      .bold-font;
    }

    .box__title,
    .box__data {
      width: 50%;
    }
  }

  @media @small-down {

    .box {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 1.2rem;

      &__title,  &__data {
        width: 100%;
        flex: 1 auto;
      }
    }

    .box--text-large {

      * {
        text-align: left !important;
      }
    }

    .box--resume {
      padding: 1rem 2.4rem;
      align-items: flex-start;
      row-gap: 0;

      .box__title,
      .box__data {
        width: 100%;
      }
    }

  }

}

