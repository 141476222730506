#page-work-groups{
  padding-top: 1rem;

  .form{
    max-width: 79rem;
    margin: auto;

    .title{
      max-width: 36.5rem;
      margin: auto auto 2rem;
    }

    .users{
      column-count: 3;
      margin-top: 2rem;

      .field{
        margin-bottom: 1rem;

        input:checked + label{
          background: @primary;

          &:after{
            .fonticon_check();
            background: none;
            z-index: 2;
            top: 60%;
            .transform(translateY(-50%));
            .font-size(1);
            left: 1.2rem;
          }
        }

        label{
          background: @white;
          //display: block;
          width: 100%;
          margin: 0;
          border-radius: @input-border-radius;
          .font-size(@input-font-size);
          padding: 0.5rem 1rem 0.5rem 3rem;
          border: @input-border;
          cursor: pointer;

          &:before{
            height: 1.5rem;
            width: 1.5rem;
            top: 50%;
            .transform(translateY(-50%));
            left: 1rem;
            background: @body-background;
          }
        }
      }
    }
  }
}
