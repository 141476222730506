.fonticon(@code) {
  content: @code;
  font-family: @icoFont;
  .font-size(@font-size-small);
  line-height: @line-height-small;
  font-weight: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin-right:0.25em;
  text-indent: 0;
}

.fonticon_arrow-right() {
  .fonticon("\e900");
}

.fonticon_alert() {
  .fonticon("\e901");
}

.fonticon_buy-back() {
  .fonticon("\e902");
}

.fonticon_calendar() {
  .fonticon("\e903");
}

.fonticon_check() {
  .fonticon("\e904");
}

.fonticon_comment() {
  .fonticon("\e905");
}

.fonticon_dashboard() {
  .fonticon("\e906");
}

.fonticon_documents() {
  .fonticon("\e907");
}

.fonticon_edit() {
  .fonticon("\e908");
}

.fonticon_eye() {
  .fonticon("\e909");
}

.fonticon_phone() {
  .fonticon("\e90a");
}

.fonticon_report() {
  .fonticon("\e90b");
}

.fonticon_roi() {
  .fonticon("\e90c");
}

.fonticon_trash() {
  .fonticon("\e90d");
}

.fonticon_gear() {
  .fonticon("\e90e");
}

.fonticon_cloud() {
  .fonticon("\e90f");
}

.fonticon_logout() {
  .fonticon("\e910");
}

.fonticon_options() {
  .fonticon("\e911");
}

.fonticon_time() {
  .fonticon("\e912");
}

.fonticon_collapse-down() {
  .fonticon("\e913");
}

.fonticon_collapse-up() {
  .fonticon("\e914");
}

.fonticon_zoom-in() {
  .fonticon("\e915");
}

.fonticon_zoom-out() {
  .fonticon("\e916");
}

.fonticon_salesman() {
  .fonticon("\e917");
}

.fonticon_upload() {
  .fonticon("\e918");
}

.fonticon_trial() {
  .fonticon("\e919");
}

.fonticon_copy() {
  .fonticon("\e91a");
}

.fonticon_users() {
  .fonticon("\e91b");
}

.fonticon_visit() {
  .fonticon("\e921");
}

.fonticon_supplier() {
  .fonticon("\e920");
}

.fonticon_studio() {
  .fonticon("\e91f");
}

.fonticon_machine() {
  .fonticon("\e91e");
}

.fonticon_lab() {
  .fonticon("\e91d");
}

.fonticon_envelope() {
  .fonticon("\e91c");
}

.fonticon_users() {
  .fonticon("\e91b");
}

.fonticon_internal-action() {
  .fonticon("\e923");
}

.fonticon_arrow-left() {
  .fonticon("\e922");
}

.fonticon_prospecting() {
  .fonticon("\e924");
}

.fonticon_plus() {
  .fonticon("\e925");
}

.fonticon_invoiced() {
  .fonticon("\e926");
}

.fonticon_sync() {
  .fonticon("\e928");
}

.fonticon_file() {
  .fonticon("\e92a");
}

.fonticon_chevron-up() {
  .fonticon("\e92b");
}

.fonticon_chevron-down() {
  .fonticon("\e929");
}

.fonticon_chevron-left() {
  .fonticon("\e92c");
}

.fonticon_chevron-right() {
  .fonticon("\e927");
}

.fonticon_hour() {
  .fonticon("\e92d");
}

.fonticon_close() {
  .fonticon("\e92e");
}

.fonticon_options() {
  .fonticon("\e92f");
}

.fonticon_small-chevron-right() {
  .fonticon("\e930");
}

.fonticon_zoom-out() {
  .fonticon("\e931");
}

.fonticon_small-chevron-up() {
  .fonticon("\e934");
}

.fonticon_small-chevron-down() {
  .fonticon("\e932");
}

.fonticon_export-xls() {
  .fonticon("\e933");
}

.fonticon_report-alt() {
  .fonticon("\e935");
}

.fonticon_dashboard-alt() {
  .fonticon("\e936");
}

.fonticon_actions() {
  .fonticon("\e937");
}

.fonticon_budget() {
  .fonticon("\e938");
}

.fonticon_diamond() {
  .fonticon("\e939");
}

.fonticon_logout-alt() {
  .fonticon("\e93a");
}

.fonticon_gear-alt() {
  .fonticon("\e93b");
}

.fonticon_alert-alt() {
  .fonticon("\e93c");
}

.fonticon_search() {
  .fonticon("\e93d");
}

.fonticon_result() {
  .fonticon("\e93e");
}

.fonticon_result-filled() {
  .fonticon("\e93f");
}

.fonticon_calculator() {
  .fonticon("\e940");
}

.fonticon_offline() {
  .fonticon("\e941");
}

.fonticon_online() {
  .fonticon("\e942");
}

.fonticon_star() {
  .fonticon("\e943");
}

.fonticon_star-filled() {
  .fonticon("\e944");
}

.fonticon_collapse-left() {
  .fonticon("\e945");
}

.fonticon_collapse-right() {
  .fonticon("\e946");
}

.fonticon_lock() {
  .fonticon("\e947");
}

.fonticon_unlock() {
  .fonticon("\e948");
}

