/*
 * Column Push Classes
 */

.column-push-1 {
	left: @column-1;
}

.column-push-1-2,
.column-push-2-4,
.column-push-3-6,
.column-push-4-8,
.column-push-5-10,
.column-push-6-12  {
	left: @column-1-2;
}

.column-push-1-3,
.column-push-2-6,
.column-push-3-9,
.column-push-4-12 {
	left: @column-1-3;
}

.column-push-2-3,
.column-push-4-6,
.column-push-6-9,
.column-push-8-12 {
	left: @column-1-3 * 2;
}

.column-push-1-4,
.column-push-2-8,
.column-push-3-12 {
	left: @column-1-4;
}

.column-push-3-4,
.column-push-6-8,
.column-push-9-12 {
	left: @column-1-4 * 3;
}

.column-push-1-5,
.column-push-2-10 {
	left: @column-1-5;
}

.column-push-2-5,
.column-push-4-10 {
	left: @column-1-5 * 2;
}

.column-push-3-5,
.column-push-6-10 {
	left: @column-1-5 * 3;
}

.column-push-4-5,
.column-push-8-10 {
	left: @column-1-5 * 4;
}

.column-push-1-6,
.column-push-2-12 {
	left: @column-1-6;
}

.column-push-5-6 {
	left: @column-1-6 * 5;
}

.column-push-1-7 {
	left: @column-1-7;
}

.column-push-2-7 {
	left: @column-1-7 * 2;
}

.column-push-3-7 {
	left: @column-1-7 * 3;
}

.column-push-4-7 {
	left: @column-1-7 * 4;
}

.column-push-5-7 {
	left: @column-1-7 * 5;
}

.column-push-6-7 {
	left: @column-1-7 * 6;
}

.column-push-1-8 {
	left: @column-1-8;
}

.column-push-3-8 {
	left: @column-1-8 * 3;
}

.column-push-5-8 {
	left: @column-1-8 * 5;
}

.column-push-7-8 {
	left: @column-1-8 * 7;
}

.column-push-1-9 {
	left: @column-1-9;
}

.column-push-2-9 {
	left: @column-1-9 * 2;
}

.column-push-4-9 {
	left: @column-1-9 * 4;
}

.column-push-5-9 {
	left: @column-1-9 * 5;
}

.column-push-7-9 {
	left: @column-1-9 * 7;
}

.column-push-8-9 {
	left: @column-1-9 * 8;
}

.column-push-1-10 {
	left: @column-1-10;
}

.column-push-3-10 {
	left: @column-1-10 * 3;
}

.column-push-7-10 {
	left: @column-1-10 * 7;
}

.column-push-9-10 {
	left: @column-1-10 * 9;
}

.column-push-1-11 {
	left: @column-1-11;
}

.column-push-2-11 {
	left: @column-1-11 * 2;
}

.column-push-3-11 {
	left: @column-1-11 * 3;
}

.column-push-4-11 {
	left: @column-1-11 * 4;
}

.column-push-5-11 {
	left: @column-1-11 * 5;
}

.column-push-6-11 {
	left: @column-1-11 * 6;
}

.column-push-7-11 {
	left: @column-1-11 * 7;
}

.column-push-8-11 {
	left: @column-1-11 * 8;
}

.column-push-9-11 {
	left: @column-1-11 * 9;
}

.column-push-10-11 {
	left: @column-1-11 * 10;
}

.column-push-1-12 {
	left: @column-1-12;
}

.column-push-5-12 {
	left: @column-1-12 * 5;
}

.column-push-7-12 {
	left: @column-1-12 * 7;
}

.column-push-10-12 {
	left: @column-1-12 * 10;
}

.column-push-11-12 {
	left: @column-1-12 * 11;
}


/*
 * Column Push Extra Large Classes
 */

@media @xlarge {

	//Column Push Reset
	.column-push-xlarge-reset {
		left: 0;
	}

	.column-push-xlarge-1 {
		left: @column-1;
	}

	.column-push-xlarge-1-2,
	.column-push-xlarge-2-4,
	.column-push-xlarge-3-6,
	.column-push-xlarge-4-8,
	.column-push-xlarge-5-10,
	.column-push-xlarge-6-12  {
		left: @column-1-2;
	}

	.column-push-xlarge-1-3,
	.column-push-xlarge-2-6,
	.column-push-xlarge-3-9,
	.column-push-xlarge-4-12 {
		left: @column-1-3;
	}

	.column-push-xlarge-2-3,
	.column-push-xlarge-4-6,
	.column-push-xlarge-6-9,
	.column-push-xlarge-8-12 {
		left: @column-1-3 * 2;
	}

	.column-push-xlarge-1-4,
	.column-push-xlarge-2-8,
	.column-push-xlarge-3-12 {
		left: @column-1-4;
	}

	.column-push-xlarge-3-4,
	.column-push-xlarge-6-8,
	.column-push-xlarge-9-12 {
		left: @column-1-4 * 3;
	}

	.column-push-xlarge-1-5,
	.column-push-xlarge-2-10 {
		left: @column-1-5;
	}

	.column-push-xlarge-2-5,
	.column-push-xlarge-4-10 {
		left: @column-1-5 * 2;
	}

	.column-push-xlarge-3-5,
	.column-push-xlarge-6-10 {
		left: @column-1-5 * 3;
	}

	.column-push-xlarge-4-5,
	.column-push-xlarge-8-10 {
		left: @column-1-5 * 4;
	}

	.column-push-xlarge-1-6,
	.column-push-xlarge-2-12 {
		left: @column-1-6;
	}

	.column-push-xlarge-5-6 {
		left: @column-1-6 * 5;
	}

	.column-push-xlarge-1-7 {
		left: @column-1-7;
	}

	.column-push-xlarge-2-7 {
		left: @column-1-7 * 2;
	}

	.column-push-xlarge-3-7 {
		left: @column-1-7 * 3;
	}

	.column-push-xlarge-4-7 {
		left: @column-1-7 * 4;
	}

	.column-push-xlarge-5-7 {
		left: @column-1-7 * 5;
	}

	.column-push-xlarge-6-7 {
		left: @column-1-7 * 6;
	}

	.column-push-xlarge-1-8 {
		left: @column-1-8;
	}

	.column-push-xlarge-3-8 {
		left: @column-1-8 * 3;
	}

	.column-push-xlarge-5-8 {
		left: @column-1-8 * 5;
	}

	.column-push-xlarge-7-8 {
		left: @column-1-8 * 7;
	}

	.column-push-xlarge-1-9 {
		left: @column-1-9;
	}

	.column-push-xlarge-2-9 {
		left: @column-1-9 * 2;
	}

	.column-push-xlarge-4-9 {
		left: @column-1-9 * 4;
	}

	.column-push-xlarge-5-9 {
		left: @column-1-9 * 5;
	}

	.column-push-xlarge-7-9 {
		left: @column-1-9 * 7;
	}

	.column-push-xlarge-8-9 {
		left: @column-1-9 * 8;
	}

	.column-push-xlarge-1-10 {
		left: @column-1-10;
	}

	.column-push-xlarge-3-10 {
		left: @column-1-10 * 3;
	}

	.column-push-xlarge-7-10 {
		left: @column-1-10 * 7;
	}

	.column-push-xlarge-9-10 {
		left: @column-1-10 * 9;
	}

	.column-push-xlarge-1-11 {
		left: @column-1-11;
	}

	.column-push-xlarge-2-11 {
		left: @column-1-11 * 2;
	}

	.column-push-xlarge-3-11 {
		left: @column-1-11 * 3;
	}

	.column-push-xlarge-4-11 {
		left: @column-1-11 * 4;
	}

	.column-push-xlarge-5-11 {
		left: @column-1-11 * 5;
	}

	.column-push-xlarge-6-11 {
		left: @column-1-11 * 6;
	}

	.column-push-xlarge-7-11 {
		left: @column-1-11 * 7;
	}

	.column-push-xlarge-8-11 {
		left: @column-1-11 * 8;
	}

	.column-push-xlarge-9-11 {
		left: @column-1-11 * 9;
	}

	.column-push-xlarge-10-11 {
		left: @column-1-11 * 10;
	}

	.column-push-xlarge-1-12 {
		left: @column-1-12;
	}

	.column-push-xlarge-5-12 {
		left: @column-1-12 * 5;
	}

	.column-push-xlarge-7-12 {
		left: @column-1-12 * 7;
	}

	.column-push-xlarge-10-12 {
		left: @column-1-12 * 10;
	}

	.column-push-xlarge-11-12 {
		left: @column-1-12 * 11;
	}
}


/*
 * Column Push Large Classes
 */

@media @large {

	//Column Push Reset
	.column-push-large-reset {
		left: 0;
	}

	.column-push-large-1 {
		left: @column-1;
	}

	.column-push-large-1-2,
	.column-push-large-2-4,
	.column-push-large-3-6,
	.column-push-large-4-8,
	.column-push-large-5-10,
	.column-push-large-6-12  {
		left: @column-1-2;
	}

	.column-push-large-1-3,
	.column-push-large-2-6,
	.column-push-large-3-9,
	.column-push-large-4-12 {
		left: @column-1-3;
	}

	.column-push-large-2-3,
	.column-push-large-4-6,
	.column-push-large-6-9,
	.column-push-large-8-12 {
		left: @column-1-3 * 2;
	}

	.column-push-large-1-4,
	.column-push-large-2-8,
	.column-push-large-3-12 {
		left: @column-1-4;
	}

	.column-push-large-3-4,
	.column-push-large-6-8,
	.column-push-large-9-12 {
		left: @column-1-4 * 3;
	}

	.column-push-large-1-5,
	.column-push-large-2-10 {
		left: @column-1-5;
	}

	.column-push-large-2-5,
	.column-push-large-4-10 {
		left: @column-1-5 * 2;
	}

	.column-push-large-3-5,
	.column-push-large-6-10 {
		left: @column-1-5 * 3;
	}

	.column-push-large-4-5,
	.column-push-large-8-10 {
		left: @column-1-5 * 4;
	}

	.column-push-large-1-6,
	.column-push-large-2-12 {
		left: @column-1-6;
	}

	.column-push-large-5-6 {
		left: @column-1-6 * 5;
	}

	.column-push-large-1-7 {
		left: @column-1-7;
	}

	.column-push-large-2-7 {
		left: @column-1-7 * 2;
	}

	.column-push-large-3-7 {
		left: @column-1-7 * 3;
	}

	.column-push-large-4-7 {
		left: @column-1-7 * 4;
	}

	.column-push-large-5-7 {
		left: @column-1-7 * 5;
	}

	.column-push-large-6-7 {
		left: @column-1-7 * 6;
	}

	.column-push-large-1-8 {
		left: @column-1-8;
	}

	.column-push-large-3-8 {
		left: @column-1-8 * 3;
	}

	.column-push-large-5-8 {
		left: @column-1-8 * 5;
	}

	.column-push-large-7-8 {
		left: @column-1-8 * 7;
	}

	.column-push-large-1-9 {
		left: @column-1-9;
	}

	.column-push-large-2-9 {
		left: @column-1-9 * 2;
	}

	.column-push-large-4-9 {
		left: @column-1-9 * 4;
	}

	.column-push-large-5-9 {
		left: @column-1-9 * 5;
	}

	.column-push-large-7-9 {
		left: @column-1-9 * 7;
	}

	.column-push-large-8-9 {
		left: @column-1-9 * 8;
	}

	.column-push-large-1-10 {
		left: @column-1-10;
	}

	.column-push-large-3-10 {
		left: @column-1-10 * 3;
	}

	.column-push-large-7-10 {
		left: @column-1-10 * 7;
	}

	.column-push-large-9-10 {
		left: @column-1-10 * 9;
	}

	.column-push-large-1-11 {
		left: @column-1-11;
	}

	.column-push-large-2-11 {
		left: @column-1-11 * 2;
	}

	.column-push-large-3-11 {
		left: @column-1-11 * 3;
	}

	.column-push-large-4-11 {
		left: @column-1-11 * 4;
	}

	.column-push-large-5-11 {
		left: @column-1-11 * 5;
	}

	.column-push-large-6-11 {
		left: @column-1-11 * 6;
	}

	.column-push-large-7-11 {
		left: @column-1-11 * 7;
	}

	.column-push-large-8-11 {
		left: @column-1-11 * 8;
	}

	.column-push-large-9-11 {
		left: @column-1-11 * 9;
	}

	.column-push-large-10-11 {
		left: @column-1-11 * 10;
	}

	.column-push-large-1-12 {
		left: @column-1-12;
	}

	.column-push-large-5-12 {
		left: @column-1-12 * 5;
	}

	.column-push-large-7-12 {
		left: @column-1-12 * 7;
	}

	.column-push-large-10-12 {
		left: @column-1-12 * 10;
	}

	.column-push-large-11-12 {
		left: @column-1-12 * 11;
	}
}


/*
 * Column Push Medium Classes
 */

@media @medium {

	//Column Push Reset
	.column-push-medium-reset {
		left: 0;
	}

	.column-push-medium-1 {
		left: @column-1;
	}

	.column-push-medium-1-2,
	.column-push-medium-2-4,
	.column-push-medium-3-6,
	.column-push-medium-4-8,
	.column-push-medium-5-10,
	.column-push-medium-6-12  {
		left: @column-1-2;
	}

	.column-push-medium-1-3,
	.column-push-medium-2-6,
	.column-push-medium-3-9,
	.column-push-medium-4-12 {
		left: @column-1-3;
	}

	.column-push-medium-2-3,
	.column-push-medium-4-6,
	.column-push-medium-6-9,
	.column-push-medium-8-12 {
		left: @column-1-3 * 2;
	}

	.column-push-medium-1-4,
	.column-push-medium-2-8,
	.column-push-medium-3-12 {
		left: @column-1-4;
	}

	.column-push-medium-3-4,
	.column-push-medium-6-8,
	.column-push-medium-9-12 {
		left: @column-1-4 * 3;
	}

	.column-push-medium-1-5,
	.column-push-medium-2-10 {
		left: @column-1-5;
	}

	.column-push-medium-2-5,
	.column-push-medium-4-10 {
		left: @column-1-5 * 2;
	}

	.column-push-medium-3-5,
	.column-push-medium-6-10 {
		left: @column-1-5 * 3;
	}

	.column-push-medium-4-5,
	.column-push-medium-8-10 {
		left: @column-1-5 * 4;
	}

	.column-push-medium-1-6,
	.column-push-medium-2-12 {
		left: @column-1-6;
	}

	.column-push-medium-5-6 {
		left: @column-1-6 * 5;
	}

	.column-push-medium-1-7 {
		left: @column-1-7;
	}

	.column-push-medium-2-7 {
		left: @column-1-7 * 2;
	}

	.column-push-medium-3-7 {
		left: @column-1-7 * 3;
	}

	.column-push-medium-4-7 {
		left: @column-1-7 * 4;
	}

	.column-push-medium-5-7 {
		left: @column-1-7 * 5;
	}

	.column-push-medium-6-7 {
		left: @column-1-7 * 6;
	}

	.column-push-medium-1-8 {
		left: @column-1-8;
	}

	.column-push-medium-3-8 {
		left: @column-1-8 * 3;
	}

	.column-push-medium-5-8 {
		left: @column-1-8 * 5;
	}

	.column-push-medium-7-8 {
		left: @column-1-8 * 7;
	}

	.column-push-medium-1-9 {
		left: @column-1-9;
	}

	.column-push-medium-2-9 {
		left: @column-1-9 * 2;
	}

	.column-push-medium-4-9 {
		left: @column-1-9 * 4;
	}

	.column-push-medium-5-9 {
		left: @column-1-9 * 5;
	}

	.column-push-medium-7-9 {
		left: @column-1-9 * 7;
	}

	.column-push-medium-8-9 {
		left: @column-1-9 * 8;
	}

	.column-push-medium-1-10 {
		left: @column-1-10;
	}

	.column-push-medium-3-10 {
		left: @column-1-10 * 3;
	}

	.column-push-medium-7-10 {
		left: @column-1-10 * 7;
	}

	.column-push-medium-9-10 {
		left: @column-1-10 * 9;
	}

	.column-push-medium-1-11 {
		left: @column-1-11;
	}

	.column-push-medium-2-11 {
		left: @column-1-11 * 2;
	}

	.column-push-medium-3-11 {
		left: @column-1-11 * 3;
	}

	.column-push-medium-4-11 {
		left: @column-1-11 * 4;
	}

	.column-push-medium-5-11 {
		left: @column-1-11 * 5;
	}

	.column-push-medium-6-11 {
		left: @column-1-11 * 6;
	}

	.column-push-medium-7-11 {
		left: @column-1-11 * 7;
	}

	.column-push-medium-8-11 {
		left: @column-1-11 * 8;
	}

	.column-push-medium-9-11 {
		left: @column-1-11 * 9;
	}

	.column-push-medium-10-11 {
		left: @column-1-11 * 10;
	}

	.column-push-medium-1-12 {
		left: @column-1-12;
	}

	.column-push-medium-5-12 {
		left: @column-1-12 * 5;
	}

	.column-push-medium-7-12 {
		left: @column-1-12 * 7;
	}

	.column-push-medium-10-12 {
		left: @column-1-12 * 10;
	}

	.column-push-medium-11-12 {
		left: @column-1-12 * 11;
	}
}


/*
 * Column Push Small Classes
 */

@media @small {

	.column-push-small-1 {
		left: @column-1;
	}

	.column-push-small-1-2,
	.column-push-small-2-4,
	.column-push-small-3-6,
	.column-push-small-4-8,
	.column-push-small-5-10,
	.column-push-small-6-12  {
		left: @column-1-2;
	}

	.column-push-small-1-3,
	.column-push-small-2-6,
	.column-push-small-3-9,
	.column-push-small-4-12 {
		left: @column-1-3;
	}

	.column-push-small-2-3,
	.column-push-small-4-6,
	.column-push-small-6-9,
	.column-push-small-8-12 {
		left: @column-1-3 * 2;
	}

	.column-push-small-1-4,
	.column-push-small-2-8,
	.column-push-small-3-12 {
		left: @column-1-4;
	}

	.column-push-small-3-4,
	.column-push-small-6-8,
	.column-push-small-9-12 {
		left: @column-1-4 * 3;
	}

	.column-push-small-1-5,
	.column-push-small-2-10 {
		left: @column-1-5;
	}

	.column-push-small-2-5,
	.column-push-small-4-10 {
		left: @column-1-5 * 2;
	}

	.column-push-small-3-5,
	.column-push-small-6-10 {
		left: @column-1-5 * 3;
	}

	.column-push-small-4-5,
	.column-push-small-8-10 {
		left: @column-1-5 * 4;
	}

	.column-push-small-1-6,
	.column-push-small-2-12 {
		left: @column-1-6;
	}

	.column-push-small-5-6 {
		left: @column-1-6 * 5;
	}

	.column-push-small-1-7 {
		left: @column-1-7;
	}

	.column-push-small-2-7 {
		left: @column-1-7 * 2;
	}

	.column-push-small-3-7 {
		left: @column-1-7 * 3;
	}

	.column-push-small-4-7 {
		left: @column-1-7 * 4;
	}

	.column-push-small-5-7 {
		left: @column-1-7 * 5;
	}

	.column-push-small-6-7 {
		left: @column-1-7 * 6;
	}

	.column-push-small-1-8 {
		left: @column-1-8;
	}

	.column-push-small-3-8 {
		left: @column-1-8 * 3;
	}

	.column-push-small-5-8 {
		left: @column-1-8 * 5;
	}

	.column-push-small-7-8 {
		left: @column-1-8 * 7;
	}

	.column-push-small-1-9 {
		left: @column-1-9;
	}

	.column-push-small-2-9 {
		left: @column-1-9 * 2;
	}

	.column-push-small-4-9 {
		left: @column-1-9 * 4;
	}

	.column-push-small-5-9 {
		left: @column-1-9 * 5;
	}

	.column-push-small-7-9 {
		left: @column-1-9 * 7;
	}

	.column-push-small-8-9 {
		left: @column-1-9 * 8;
	}

	.column-push-small-1-10 {
		left: @column-1-10;
	}

	.column-push-small-3-10 {
		left: @column-1-10 * 3;
	}

	.column-push-small-7-10 {
		left: @column-1-10 * 7;
	}

	.column-push-small-9-10 {
		left: @column-1-10 * 9;
	}

	.column-push-small-1-11 {
		left: @column-1-11;
	}

	.column-push-small-2-11 {
		left: @column-1-11 * 2;
	}

	.column-push-small-3-11 {
		left: @column-1-11 * 3;
	}

	.column-push-small-4-11 {
		left: @column-1-11 * 4;
	}

	.column-push-small-5-11 {
		left: @column-1-11 * 5;
	}

	.column-push-small-6-11 {
		left: @column-1-11 * 6;
	}

	.column-push-small-7-11 {
		left: @column-1-11 * 7;
	}

	.column-push-small-8-11 {
		left: @column-1-11 * 8;
	}

	.column-push-small-9-11 {
		left: @column-1-11 * 9;
	}

	.column-push-small-10-11 {
		left: @column-1-11 * 10;
	}

	.column-push-small-1-12 {
		left: @column-1-12;
	}

	.column-push-small-5-12 {
		left: @column-1-12 * 5;
	}

	.column-push-small-7-12 {
		left: @column-1-12 * 7;
	}

	.column-push-small-10-12 {
		left: @column-1-12 * 10;
	}

	.column-push-small-11-12 {
		left: @column-1-12 * 11;
	}
}

/*
 * Column Push Extra Small Classes
 */

@media @xsmall {

	[class*='column-push-'] {
		left: auto;
	}

	.column-push-xsmall-1 {
		left: @column-1;
	}

	.column-push-xsmall-1-2,
	.column-push-xsmall-2-4,
	.column-push-xsmall-3-6,
	.column-push-xsmall-4-8,
	.column-push-xsmall-5-10,
	.column-push-xsmall-6-12  {
		left: @column-1-2;
	}

	.column-push-xsmall-1-3,
	.column-push-xsmall-2-6,
	.column-push-xsmall-3-9,
	.column-push-xsmall-4-12 {
		left: @column-1-3;
	}

	.column-push-xsmall-2-3,
	.column-push-xsmall-4-6,
	.column-push-xsmall-6-9,
	.column-push-xsmall-8-12 {
		left: @column-1-3 * 2;
	}

	.column-push-xsmall-1-4,
	.column-push-xsmall-2-8,
	.column-push-xsmall-3-12 {
		left: @column-1-4;
	}

	.column-push-xsmall-3-4,
	.column-push-xsmall-6-8,
	.column-push-xsmall-9-12 {
		left: @column-1-4 * 3;
	}

	.column-push-xsmall-1-5,
	.column-push-xsmall-2-10 {
		left: @column-1-5;
	}

	.column-push-xsmall-2-5,
	.column-push-xsmall-4-10 {
		left: @column-1-5 * 2;
	}

	.column-push-xsmall-3-5,
	.column-push-xsmall-6-10 {
		left: @column-1-5 * 3;
	}

	.column-push-xsmall-4-5,
	.column-push-xsmall-8-10 {
		left: @column-1-5 * 4;
	}

	.column-push-xsmall-1-6,
	.column-push-xsmall-2-12 {
		left: @column-1-6;
	}

	.column-push-xsmall-5-6 {
		left: @column-1-6 * 5;
	}

	.column-push-xsmall-1-7 {
		left: @column-1-7;
	}

	.column-push-xsmall-2-7 {
		left: @column-1-7 * 2;
	}

	.column-push-xsmall-3-7 {
		left: @column-1-7 * 3;
	}

	.column-push-xsmall-4-7 {
		left: @column-1-7 * 4;
	}

	.column-push-xsmall-5-7 {
		left: @column-1-7 * 5;
	}

	.column-push-xsmall-6-7 {
		left: @column-1-7 * 6;
	}

	.column-push-xsmall-1-8 {
		left: @column-1-8;
	}

	.column-push-xsmall-3-8 {
		left: @column-1-8 * 3;
	}

	.column-push-xsmall-5-8 {
		left: @column-1-8 * 5;
	}

	.column-push-xsmall-7-8 {
		left: @column-1-8 * 7;
	}

	.column-push-xsmall-1-9 {
		left: @column-1-9;
	}

	.column-push-xsmall-2-9 {
		left: @column-1-9 * 2;
	}

	.column-push-xsmall-4-9 {
		left: @column-1-9 * 4;
	}

	.column-push-xsmall-5-9 {
		left: @column-1-9 * 5;
	}

	.column-push-xsmall-7-9 {
		left: @column-1-9 * 7;
	}

	.column-push-xsmall-8-9 {
		left: @column-1-9 * 8;
	}

	.column-push-xsmall-1-10 {
		left: @column-1-10;
	}

	.column-push-xsmall-3-10 {
		left: @column-1-10 * 3;
	}

	.column-push-xsmall-7-10 {
		left: @column-1-10 * 7;
	}

	.column-push-xsmall-9-10 {
		left: @column-1-10 * 9;
	}

	.column-push-xsmall-1-11 {
		left: @column-1-11;
	}

	.column-push-xsmall-2-11 {
		left: @column-1-11 * 2;
	}

	.column-push-xsmall-3-11 {
		left: @column-1-11 * 3;
	}

	.column-push-xsmall-4-11 {
		left: @column-1-11 * 4;
	}

	.column-push-xsmall-5-11 {
		left: @column-1-11 * 5;
	}

	.column-push-xsmall-6-11 {
		left: @column-1-11 * 6;
	}

	.column-push-xsmall-7-11 {
		left: @column-1-11 * 7;
	}

	.column-push-xsmall-8-11 {
		left: @column-1-11 * 8;
	}

	.column-push-xsmall-9-11 {
		left: @column-1-11 * 9;
	}

	.column-push-xsmall-10-11 {
		left: @column-1-11 * 10;
	}

	.column-push-xsmall-1-12 {
		left: @column-1-12;
	}

	.column-push-xsmall-5-12 {
		left: @column-1-12 * 5;
	}

	.column-push-xsmall-7-12 {
		left: @column-1-12 * 7;
	}

	.column-push-xsmall-10-12 {
		left: @column-1-12 * 10;
	}

	.column-push-xsmall-11-12 {
		left: @column-1-12 * 11;
	}
}