/*
 * Definitions
 */

// Pre
@pre-margin: 2rem 0;
@pre-padding: 1rem;
@pre-color: @body-color;
@pre-tab-size: 4;
@pre-hyphens: none;
@pre-line-height: @line-height-medium;
@pre-background: @light;
@pre-border: 1px solid @light-grey;

// Pre Code
@pre-code-color: @code-color;


/*
 * Code
 */

pre {
	margin: @pre-margin;
	padding: @pre-padding;
	overflow: auto;
	color: @pre-color;
	direction: ltr;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	.tab-size(@pre-tab-size);
	.hyphens(@hyphens: none);
	background: @pre-background;
	border: @pre-border;

	code {
		color: @pre-code-color;
	}
}