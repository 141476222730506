#page-buyback{
  padding-top: 3rem;

  .sidebar{
    width: 48.7rem;
  }

  .container{
    height: 100%;
    overflow: auto;

    .alert{
      padding: 1.5rem 5rem 1.5rem 1.5rem;
      .font-size-large;
      .bold-font();
      margin-bottom: 2rem;
      position: relative;

      .close{
        position: absolute;
        right: 2rem;
        color: @dark;

        &:before{
          content: '✕';
        }
      }

      &--success{
        background: fade(@success, 20%);
        border: 1px solid @success;
      }

      &--error{
        background: fade(@error, 20%);
        border: 1px solid @error;
      }
    }
  }
}
