// Documents
.generate-report-warning {
    float: right;
    background-color: #FED9E0;
    font-size: small;
    padding: 0 1rem;

    span {
        display: inline-block;
        padding: 1rem 1rem;
    }

    &__title {
        color: red;
        font-weight: 700;
    }

    &__close-advice {
        color: red;
        font-size: medium;
        cursor: pointer;
    }
}

// Project groups
#page-dashboard .project__main {
    padding-right: 0 !important;

    .project__extra_actions {
        padding-top: 0;
        margin-top: 0;
        line-height: 2em;
        display: flex;
        flex-direction: column;
    }

}

a.switch_fav {
    font-size: 3.2em !important;
    font-weight: 500;

    &:hover {
        color: white;
    }
}

.project-group-info__right {
    flex-direction: column;
    vertical-align: top;
    justify-content: start !important;

    .switch_fav {
        font-size: 2em !important;
        background-color: @dark;
        font-weight: 500;
        padding: 0px 15px;
        margin: 0px 15px;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        font-size: 3.2em;
    }
}

#page-dashboard {

    .project__meta {
        flex-wrap: wrap;

        >span {
            margin-bottom: 3px;
        }

        .project__contacts,
        .project__commercials {
            width: 90%;
        }

        .project__contacts {
            margin-top: 8px;
        }
    }
}

.erp-offer-form {
    display: flex;
    align-items: flex-end;
    column-gap: 0.8rem;
    margin-bottom: 3.5rem;


    .field {
        margin-bottom: 0;
    }

    input {
        height: 4.8rem;
        margin-bottom: 0;
    }
}

.calendar-error {
    bottom: 2rem;
    position: relative;
}

.offer-lines-modal>.nsm-content {
    max-width: 900px;
}

.link-under-input {
    margin-top: -2rem;
    display: block;
}

.link-dark {
    color: @dark;
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.search-container {
    max-width: 32rem;
}

.mark-as-current-trial {
    border: 1px solid @dark;
}

.table td .icon.disabled {
    opacity: .5;
    cursor: default;

    :hover {
        background-color: transparent;
    }
}


.project-group-report-assistant {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .assistant-header {
        height: 80px;
        border-bottom: 1px solid gray;
    }

    .assistant-body {
        overflow: auto;
        height: 100%;

        .file-selector-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .file-selector-project-list {
                width: 300px;
                border-right: 1px solid gray;

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    li {
                        cursor: pointer;
                        border-left: 10px solid #484549;
                        border-bottom: 1px solid #f4f4f4;
                        margin: 5px 0;
                        padding: 5px 5px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;

                        .subproject_logo_container {
                            width: 80px;
                        }

                        :hover {
                            border-color: @dark;
                        }

                        &.active {
                            border-color: @primary;
                        }
                    }

                }

            }

            .file-selector-explorer {
                width: 100%;
            }

        }
    }

    .assistant-footer {
        height: 80px;
        border-top: 1px solid gray;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }

    .project-check {
        display: block;
        position: absolute;
        margin-top: -15px;
        width: 25px;
        height: 25px;
        background-color: @alt-grey;
        font-size: 1.5em;
        text-align: center;
        font-weight: 700;
        border: 1px solid black;

        &.checked {
            background-color: @primary;
        }
    }
}

.iframe-container {
    width: 100%;
    height: 100%;
    border-left: 3px solid black;
}

.icon-close {
    display: none !important;
}

.menu-cerrar {
    display: none !important;
}

.component-documents {
    display: flex;
    height: 100%;
    width: 100%;
}

.component-documents {
    .container {
        height: 100%;
        overflow-y: auto !important;
        overflow-x: hidden !important;
        max-width: 800px;
    }

    .action-bar {
        display: none;
    }
}

.upload-documents,
.uploaded-documents {

    h2 {
        font-size: 1.6rem;
        color: @dark;
    }

    .text-left a {
        color: @dark;

        &:hover {
            color: @primary;
        }
    }
}

.project-warning {
    &__container {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 10px;
        color: @white;
        width: 50px;
        gap: .5rem;
    }

    &__text {
        font-size: .9em;
        font-weight: 800;
    }

    &__warning {
        display: flex;
        gap: .3rem;
        background-color: @warning;
        padding: 3px 4px;
        border-radius: 3px;
        justify-content: space-around;

        span {
            line-height: 1.2em;
        }
    }

}

.on-card {
    .project-warning {
        &__container {
            margin: 5px 0;
            flex-direction: row;
        }
    }
}

.project-profitability {
    float: left;
    margin-left: 4.7rem;
}

.c-project-profitability {

    .c-title {
        font-size: 2em;
    }

    .c-data-container {

        display: flex;
        flex-direction: column;

        .c-data {
            display: flex;
            background-color: @primary;
            border-radius: 4px;
            margin: 5px 0;
            font-size: 1.2em;
            line-height: 2em;
            justify-content: space-between;

            span {
                padding: 10px 10px;
            }

            span:first-child {
                font-weight: 900;
            }
        }
    }

}

.input-button {
    input {
        margin: 0;
    }
}

.notification-delivery {
    margin-top: 1rem;
    margin-left: 4rem;
}

.modal--yes-no .modal-footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.warning-message {
    background-color: @warning;
    color: @white;
    font-size: large;
    padding: .5rem 1rem;
    margin: 0 0 .7rem 0;
    border-radius: .4rem;
}

.total-label--percentage {
    background-color: rgba(255, 255, 255, .3);
    border: 1px solid gray;
    border-radius: .3rem;
    padding: .3rem .5rem;
    margin: 0 .4rem;
}

.connection-status {
    background-color: rgba(255, 255, 255, .5);
    padding: .6rem 1rem;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: .6rem;
    border-radius: .4rem;

    a {
        color: black;
    }
}

.sidebar--connection_status {
    z-index: 1;
}

.notification__extended-info {
    font-size: 1.4rem;
    opacity: .7;
}

.notification__hour {
    font-size: 1.4rem;
}

table.ranking-difference-table tr td:first-child {
    padding: 0;
}

.new__label {
    color: @white;
    background-color: @secondary;
    padding: 0.4rem;
    border-radius: 0.5rem;
}

.days-ago {
    color: @primary;
    font-size: 1.2rem;
}

.hover-underline {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.ng-dropdown-panel-items {
    min-height: auto;
}

.no-errors .field.error input{
  border: 1px solid @dark-grey;
}
