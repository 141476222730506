/*
 * Text
 */


.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-justify {
	text-align: justify;
}

.text-center {
	text-align: center;
}

.truncate {
	.truncate();
}

.no-truncate {
	.no-truncate();
}

.text-hide {
	.text-hide();
}

.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.capitalize {
	text-transform: capitalize;
}

.special-font {
	font-family: @secondary-font-family;
	font-weight: bold;
}
