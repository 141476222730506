#page-search{
  .container{
    height: 100%;

    .section{
      .section-item();
      .flex-display();
      .flex-wrap(wrap);
      .justify-content(flex-start);
      padding-top: 2rem;

      app-historic-list{
        .flex-display();
        .flex-wrap(wrap);
        .justify-content(flex-start);
        width: 100%;
      }

      &__item{
        .calc(width; "25% - 2.4rem");
        margin: 1.2rem;
      }
    }
  }
}
