/*
 * Flex
 */

.flex {
	.flex-display(flex);
}

.flex-inline {
	.flex-display(flex-inline);
}

// Flex Wrap
.flex-wrap {
	.flex-wrap(wrap);
}

.flex-nowrap {
	.flex-wrap(nowrap);
}

// Flex Direction
.flex-row {
	.flex-direction(row);
}

.flex-column {
	.flex-direction(column);
}

// Flex
.flex-1 {
	.flex(1);
}

.flex-1-1-auto {
	.flex(1 1 auto);
}

.flex-1-0-auto {
	.flex(1 0 auto);
}

.flex-0-0-auto {
	.flex(0 0 auto);
}

// Justify Content
.justify-content-flex-start {
	.justify-content(flex-start);
}

.justify-content-flex-end {
	.justify-content(flex-end);
}

.justify-content-center {
	.justify-content(center);
}

.justify-content-space-between {
	.justify-content(space-between);
}

.justify-content-space-around {
	.justify-content(space-around);
}

// Align Content
.align-content-flex-start {
	.align-content(flex-start);
}

.align-content-flex-end {
	.align-content(flex-end);
}

.align-content-center {
	.align-content(center);
}

.align-content-space-between {
	.align-content(space-between);
}

.align-content-space-around {
	.align-content(space-around);
}

.align-content-stretch {
	.align-content(stretch);
}

// Align Items
.align-items-flex-start {
	.align-items(flex-start);
}

.align-items-flex-end {
	.align-items(flex-end);
}

.align-items-center {
	.align-items(center);
}

.align-items-baseline {
	.align-items(baseline);
}

.align-items-stretch {
	.align-items(stretch);
}

// Align Self
.align-self-flex-start {
	.align-self(flex-start);
}

.align-self-flex-end {
	.align-self(flex-end);
}

.align-self-center {
	.align-self(center);
}

.align-self-baseline {
	.align-self(baseline);
}

.align-self-stretch {
	.align-self(stretch);
}

/* tailwind classes */
.justify-between {
	justify-content: space-between;
}