/*
 * Definitions
 */

// Table
@table-margin: 0;
@table-background: none;
@table-border: 1px solid @grey;
@table-border-radius: 0; //@bor0der-radius

// Table Caption
@table-caption-margin: 1rem 0;
@table-caption-font-family: @regular-font-family;
@table-caption-font-weight: @regular-font-weight;
@table-caption-font-size: @font-size-medium;
@table-caption-color: @body-color;
@table-caption-text-align: left;

// Table Head
@table-head-padding: 0.7rem; //1rem;
@table-head-font-family: @medium-font-family;
@table-head-font-weight: @bold-font-weight;
@table-head-font-size: @font-size-small;
@table-head-text-align: left;
@table-head-text-transform: none;
@table-head-color: @body-color;
@table-head-border: @table-border;
@table-head-background: transparent;

// Table Cell
@table-cell-padding: 1rem;
@table-cell-font-family: @regular-font-family;
@table-cell-font-weight: @regular-font-weight;
@table-cell-font-size: @font-size-small;
@table-cell-color: @body-color;
@table-cell-line-height: @line-height-medium;
@table-cell-vertical-align: middle;
@table-cell-border: @table-border;

// Table Reponsive
@table-responsive-breakpoint: @xsmall;


/*
 * Table
 */

table,
.table {
  margin: @table-margin;
  width: 100%;
  background: @table-background;
  border: none;
  border-radius: @table-border-radius;
  border-collapse: collapse;

  caption {
    margin: @table-caption-margin;
    font-family: @table-caption-font-family;
    font-weight: @table-caption-font-weight;
    .font-size(@table-caption-font-size);
    color: @table-caption-color;
    text-align: @table-caption-text-align;
  }

  tr {
    background: @light-grey;

    &:nth-child(odd) td {
      background: @white;

      &.different {
        background: @dark;
        color: @white;
      }
    }

    &.background-dark td {
      background: @dark;
      color: @white;
    }

  }

  th,
  td {
    &.cell-center {
      text-align: center;
    }
  }

  th {
    padding: @table-head-padding;
    font-family: @table-head-font-family;
    font-weight: @table-head-font-weight;
    .font-size(@table-head-font-size);
    color: @table-head-color;
    text-align: @table-head-text-align;
    text-transform: @table-head-text-transform;
    background: @table-head-background;
    border: none;
  }

  td {
    padding: @table-cell-padding;
    font-family: @table-cell-font-family;
    font-weight: @table-cell-font-weight;
    .font-size(@table-cell-font-size);
    color: @table-cell-color;
    line-height: @table-cell-line-height;
    vertical-align: @table-cell-vertical-align;
    .text-left;

    &.different {
      background: @dark;
      color: @white;
    }

    &.actions {
      .text-right;
    }

    .icon {
      color: @dark;
      width: 3.7rem;
      height: 3.7rem;
      //background: @grey;
      position: relative;
      display: inline-block;
      border-radius: 0.5rem;
      margin-bottom: -0.5rem;

      &:before {
        .center();
        .font-size(1.8); //!important;
      }

      &:hover {
        //color: @primary;
        color: @darker;
        background-color: @primary;
        border-radius: 50%;
      }

      &+.icon {
        margin-left: 1rem;
      }

      &--trash:hover {
        color: @danger;
        background-color: transparent;
      }

      &.success:after {
        background-color: @success;
        .fonticon_check();
      }

      &.error:after {
        background-color: @error;
        .fonticon_close();
      }

      &.success:after,
      &.error:after {
        position: absolute;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        color: @white;
        font-size: 0.7rem;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        right: 0;
        transform: translate(-0.1rem, -0.3rem);
      }
    }
  }
}

// Table underline
.table-underline {

  thead,
  tbody {
    tr {
      background: transparent;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid;
    }
  }

  tr {

    &:nth-child(odd) td {
      background: transparent;
    }
  }

  th,
  td {
    &.cell-center {
      text-align: center;
    }
  }

  th {
    text-transform: uppercase;
  }

  td {
    line-height: 1.25;

    .icon {
      color: @darker;
      width: auto;
      height: auto;
      background: transparent;
      padding-bottom: 1.2rem;


      &:before {
        position: static;

      }

      &:hover {
        color: @darker;
        background-color: @primary;
        border-radius: 50%;
      }

      &+.icon {
        margin-left: 1rem;
      }

      &--trash:hover {
        color: @error
      }
    }
  }

}

// Table Responsive
.table-responsive {
  margin: @table-margin;
  overflow-x: auto;
  min-height: 0.01%;

  table {
    //table-layout: fixed;
  }

  @media @small-down {
    border: 0;

    table {
      border: 0;

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tbody {
        padding: 0;
        display: block;
        background: none;
        margin-bottom: 1rem;
        box-shadow: none;
        border: 0;

        tr {
          padding: 0;
          display: block;
          background: @white;
          margin-bottom: 1rem;
          border: 0;
        }

        td {
          text-align: right !important;
          display: block;
          position: relative;

          &:not(:last-child) {
            border-bottom: 1px solid @body-background;
            min-height: 4rem;
          }

          &:before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

// Table Dark
.table-dark {

  th {
    background-color: @dark;
    color: @primary;
  }

  td {
    .icon {
      //width: auto;
      //height: auto;
      width: 3rem;
      height: 3rem;
      background: transparent;
      padding-bottom: 1.2rem;
      //min-width: 2.4rem;
    }
  }
}

// Table responsive
.table-responsive-small {
  margin: @table-margin;
  overflow-x: auto;
  min-height: 0.01%;

  table {
    //table-layout: fixed;
  }

  @media @medium-small-down {
    border: 0;

    table {
      border: 0;

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tbody {
        padding: 0;
        display: block;
        background: none;
        margin-bottom: 1rem;
        box-shadow: none;
        border: 0;

        tr {
          padding: 0;
          display: block;
          background: @white;
          margin-bottom: 1rem;
          border: 0;
        }



        td {
          text-align: right !important;
          display: block;
          position: relative;

          &:not(:last-child) {
            border-bottom: 1px solid @body-background;
            min-height: 4rem;
          }

          &:before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
            // text-transform: uppercase;
          }
        }
      }
    }
  }
}

// Table with dropdown rows

.table-dropdown-rows {

  table {
    table-layout: fixed;
  }

  &.table-dark th {
    color: @white;
    font-size: 1.6rem;
  }

  td:first-child,
  th:first-child {
    width: 8%;
    //min-width: 50px;
  }

  td:nth-child(2) {
    width: 40%;
    //min-width: 300px;
    //padding-left: 0;
  }

  td:nth-child(3) {
    width: 20%;
  }

  td:nth-child(4) {
    width: 20%;
  }

  td:last-child {
    width: 12%;
  }

  tr.sub-header {

    td:first-child {
      width: 8%;
    }

    td:nth-child(2) {
      width: 40%;
    }

    td:nth-child(3) {
      width: 20%;
    }

    td:nth-child(4) {
      width: 20%;
    }

    td:last-child {
      width: 12%;
    }

  }


  tr {

    background: @white;

    &.sub-header td {
      background-color: @white;
      .bold-font;
    }

    &.dropdown-rows {

      td {
        border-bottom: 1px solid @border-color;

        .icon {
          padding-bottom: 2rem;

          &:hover {
            color: @dark;
          }
        }
      }

      td:first-child {
        //padding: 0;
        text-align: center;
        border-bottom: 0;

        .icon--small-chevron-down:before {
          font-size: 1.2rem;
        }
      }

      td:nth-child(2) {
        text-transform: uppercase;
      }

      td:last-child {
        text-align: right;

        .icon--check {
          border: 1px solid @dark;
          width: 2.5rem;
          height: 2.5rem;
          text-align: center;
          border-radius: 99px;

          &:before {
            .font-size(1.2);
          }

          &:hover {
            background-color: @primary;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;

            :before {
              color: @dark;
            }
          }
        }

        .icon {

          &.checked {
            background-color: @primary;
            border: 0;
          }

          &:hover {
            color: @dark;
          }

          &:before {
            .font-size(1.2);
          }

          &.empty {

            &:before {
              display: none;
            }
          }

        }
      }

      &.opened {

        td {

          border-bottom: 1px solid @dark;
          background: @grey;

          &:first-child {
            border-bottom: 0;

            .icon--small-chevron-down:before {
              .fonticon_small-chevron-up();
            }
          }
        }
      }
    }



    &.toogle-content {
      display: none;
    }

    &.toogle-content {

      >td {
        padding: 0;
      }

      td {
        background: @grey;

        td {
          padding: 0.5rem 1rem;
        }

        td:last-child {
          //width: 15%;
          //min-width: 120px;
          text-align: right;
          text-transform: initial;
          padding-left: 0;
          padding-right: 1rem;


          .icon--check {
            border: 1px solid @dark;
            width: 2rem;
            height: 2rem;
            min-width: 2rem;
            text-align: center;
            border-radius: 99px;

            &:before {
              .font-size(1);
            }

            &:hover {
              background-color: @primary;
              -webkit-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;

              :before {
                color: @dark;
              }
            }
          }

          .icon {
            &.checked {
              background-color: @primary;
              border: 0;
            }

            &:hover {
              color: @dark;
            }

            &:before {
              .font-size(1);
            }

            &.empty {

              &:before {
                display: none;
              }
            }

          }
        }
      }
    }
  }

  tr.opened+tr.toogle-content {
    display: table-row;
  }

}
// Table Graph
.table-graph {
  margin-bottom: 2rem;
  margin-top: 1rem;

  tr {
    background-color: transparent;
    border-bottom: .1rem solid @dark-grey;
  }
  th {
    background-color: @dark-grey;
    color: @dark;
    text-align: right;
    text-transform: uppercase;
    padding:1rem .7rem;
    line-height: 1.6rem;

    &:first-child {
      width: 85px;
      padding-right: 0;
    }
  }

  td {
    text-align: right;

    &:first-child {
      text-transform: uppercase;
      font-weight:@regular-font-weight;
      text-align: left;
      .truncate();
      width: 85px;
      padding-right: 0;
    }
  }
}

.table-lined {
  th {
    padding: 1.4rem 0.7rem;
    border-bottom: 1px solid;
  }
}

/* table goals */

.table-goals {
  margin: 2.4rem 0;
  width: 100%;
  border-collapse: collapse;

  .th-past {
    background-color: @alt-grey;
  }

  .th-current {
    background-color: @dark;

    .select--year {

      &:after {
        border-top: 0.6rem solid @white;
      }

      select {
        color: @white;

        option {
          color: @dark;
        }
      }
    }
  }

  tr {
    background: @white;

    &:nth-child(odd) td {
      background: inherit;
    }
  }

  .col-header {
    text-transform: uppercase;
    font-weight: bold;
    background-color: @white !important;
    border-right: 10px solid @white !important;
    width: 15rem;
  }

  tbody {

    .col-header {
      border-bottom: 1px solid @dark  !important;
      &.sub-category{
        -webkit-box-shadow: inset 5px 0px 0px 0px @dark;
        -moz-box-shadow: inset 5px 0px 0px 0px @dark;
        box-shadow: inset 5px 0px 0px 0px @dark;
      }
    }

    tr:nth-child(odd), tr:nth-child(even) {
      th, td {
        border: 1px solid #FFF;
        background-color: fade(@dark, 4%);
        font-size: 1.2rem;
        text-align: center;
      }
    }
  }

  th.cell-dark {
    background-color: @dark !important;
    color: @white;
  }
  td.cell-dark {
    background-color: @alt-grey !important;
  }

  th.cell-primary {
    background-color: @primary !important;
  }
  td.cell-primary {
    background-color: fade(@primary, 49%) !important;
    text-align: right !important;
  }
}

/* Other elements inside table-goals */

.header-collapse-action {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .collapse-trigger {
    padding: 0.5rem;
    cursor: pointer;

    .icon:before {
      .fonticon_collapse-left();
    }

    &.collapsed {
      .icon:before {
        .fonticon_collapse-right();
      }
    }
  }
}

.collapse-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .icon:before {
    font-size: 1rem;
  }

  &.expanded {
    .icon:before {
      .fonticon_collapse-up();
      font-size: 1rem;
    }
  }
}

.select--year {
  width: 9rem;
  margin: 0.25rem 0;

  &:after {
    border-left: 0.6rem solid transparent;
    border-right: 0.6rem solid transparent;
    border-top: 0.6rem solid @dark;
    margin-top:  -0.4rem;
  }

  select {
    background-color: transparent;
    border: none;
    width: 9rem;
    height: 3rem;
    font-weight: bold;

    &:hover,
    &:focus {
      background-color: fade(@white, 30%);
      border: none;
    }
  }
}

td.trimester {
  height: 12rem;
}

.detailed  {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;

  .detail-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    //row-gap: 0.2rem;
  }

  .detail-month {
    font-size: 0.7rem;
    text-transform: uppercase;
  }

  .detail-amount{
    line-height: 1;
  }
}

.button-icon-right {

  .icon:before {
    font-size: 1.8rem;
    margin-left: 0.5rem;
  }
}


// page reports resume
.reports-table-groups {
  display: flex;
  column-gap: 1.6rem;
  row-gap: 2.4rem;
  flex-wrap: wrap;

  .table {
      width: ~"calc(50% - 0.8rem)";

      @media @medium-down {
          width: 100%;
      }
  }
}

/* table  resume */
.table-resume {

  thead {
     th {
      padding-left: 1.6rem;
      text-transform: uppercase;
      font-size: 1.6rem;
      color: @white;
      border: 2px solid #FFF;
     }
  }

  // reset striped rows
  tr:nth-child(odd), tr:nth-child(even) {
    td {
      border: 2px solid #FFF;
      background-color: @light-grey;
      font-size: 1.4rem;
    }
  }

  tbody {
    td {
      text-align: center;

      &:first-child {
        text-align: left;
        padding-left: 1.6rem;
      }
    }
  }

  // colored cells
  td.cell-primary,
  tr.row-primary td {
    background-color: @primary !important;
  }
  td.cell-grey,
  tr.row-grey td {
    background-color: fade(@dark, 10%) !important;
  }

}
