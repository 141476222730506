.roi-result{
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;

  &.success{
    background-color: @success;
    color: @white;
  }

  &.error{
    background-color :@error;
    color: @white;
  }
}
