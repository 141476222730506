.subproject-list {
    display: flex;
    column-gap: 2.4rem;
    margin: 3.5rem 0;
    flex-wrap: wrap;
    row-gap: 2rem;
}

.subproject {
    width: 19rem;
    background-color: fade(@dark, 4%);
    padding: 2.4rem;
    position: relative;
    display: flex;
    flex-direction: column;

    &__logo {
        width: 55%;
        height: 4.5rem;
        .fleximage;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    &__checkbox {
        width: 3rem;
        height: 3rem;
        border: .1rem solid @dark;
        background: @white;
        position: absolute;
        top: 1.2rem;
        right: 1rem;
        cursor: pointer;
    }

    &__checkbox--finalize {
        border: .1rem solid @primary;
        background: @primary;

    }

    &__checkbox--finalize:before {
        .center();
        .fonticon_check();
        .font-size(1.8);
        color: @darker;
      }

    &__name {
        font-size: 1.6rem;
        color: @dark;
        text-transform: uppercase;
        margin: 1.8rem 0;
        word-break: break-all;
        .bold-font;
    }

    &__meta {
        font-size: 1.4em;
        display: flex;
        flex-direction: column;
        margin-bottom: 2.4rem;

        .icon:before {
          margin-right:.8rem;
          vertical-align: middle;
        }

        .icon--sync:before {
            font-size: 1.8rem;
        }
    }

    &__date, &__update  {
        display: flex;
        align-items: center;
    }

    &__date {

        .icon--chevron-right:before {
            margin-left: .2rem;
            margin-right: 1rem;
        }
    }

    &__status {
        display: flex;
        margin-top: auto;

        span {
            width: 3rem;
            height: 1.2rem;
            display:block;
            margin-right: 0.7rem;
            background-color: @dark;

            &:last-child {
                margin-right: 0;
            }

            &.done {
                background-color: @primary;
            }
        }
    }
}