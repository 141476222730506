#page-actions {
  background-color: #FFF;
  padding-top: 1.8rem;

  @media @xsmall{
    height: auto;
    margin-top: 14rem;
    padding-bottom: @action-bar-height + 2;
  }

  // table
  .actions-row {
    @media @medium-small-down {

      & td:nth-child(odd) {
        background: #F8F8F8;
      }

      &__user, & td:first-child  {
        background-color: @dark;
        color: @primary;
      }
    }



    &__type {
      position: relative;

      .icon-container {
        height: 2.4rem;
      }

      .icon--phone {
        @media @medium-small-down {
          position: relative;
        }
      }

      span:before {
        font-size: 1.6rem;
        padding: 0.5rem;
        border-radius: 50%;
        border: 1px solid;
        text-align: center;
      }

      .letter {
        position: relative;
        bottom: 1rem;
        left: 1.75rem;
        background-color: @dark;
        color: #fff;
        .circle-small;

        @media @medium-small-down {
          position: absolute;
          top: 1rem;
          right: 1.75rem;
        }
      }
    }

    &__actions {
      min-width: 20rem;
      //max-width: 25rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media @small-down {
        display: inline-flex;
        max-width: 100%;
      }

    }

    &__action {
      border-right: 1px solid #E9E7E7;
      padding-right: 0.5rem;
      padding: 0.5rem 0.5rem 0;
      position: relative;
      height: 3rem;

      &.inactive > *{
        opacity: 0.2;
        cursor: default;
      }

      @media @medium-small-down {
        border-right: 0;
      }

      .icon {
        width: 3rem;
        height:3rem;
        padding-bottom: 0;

        &:before {
          font-size: 1.5rem;
        }
      }

      &:last-child {
        border: 0;
        padding-right: 0;
        margin-right: 0;
      }

      .number {
        position: relative;
        bottom: 1.25rem;
        left: 1.25rem;
        background-color: @dark;
        color: #fff;
        .circle-small;
      }
    }
  }
}

// filters (form)
#actions-filters {

  .form-filters {

    &__fields {
      width:82%;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
    }

    &__submit {
        width:18%;
    }

    &__column5 {
      width: 17.5%;
      margin-right: 2.5%;
      max-width: 29.5rem;

      :last-child {
        margin-right: 0;
      }
    }
  }

  @media @medium-small-down {
    .form-filters {

      &__column5 {
        width: 22%;
        margin-right: 3%;
      }

      &__column5:nth-child(5) {
        order:8;
        margin-bottom: 0;
      }
    }
  }

  .total-cost {
    text-align: right;
  }
}