/*
 * Display
 */

.display-block {
	display: block;
}

.display-inline-block {
	display: inline-block;
}

.display-table {
	display: table;
}

.display-table-cell {
	display: table-cell;
	vertical-align: middle;
}

.display-flex {
  display: flex;
}
