/*
 * Definitions
 */

// Headings
@headings-margin: 0 0 1em 0;
@headings-font-family: @medium-font-family;
@headings-font-weight: @medium-font-weight;
@headings-color: @dark-grey;
@headings-line-height: @line-height-small;

// Headings Small
@headings-small-font-family: @medium-font-family;
@headings-small-font-weight: @medium-font-weight;
@headings-small-size: 0.6em;
@headings-small-color: @grey;

// Heading Font Sizes
@h1-font-size: 3.2;
@h2-font-size: 2.4;
@h3-font-size: 2.0;
@h4-font-size: 1.8;
@h5-font-size: 1.6;
@h6-font-size: 1.4;


/*
 * Headings
 */

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: @headings-margin;
	padding: 0;
	font-family: @headings-font-family;
	font-weight: @headings-font-weight;
	color: @headings-color;
	line-height: @headings-line-height;
}

// Heading Small
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
	font-family: @headings-small-font-family;
	font-weight: @headings-small-font-weight;
	font-size: @headings-small-size;
	color: @headings-small-color;
}

// Heading Font Sizes
h1 {
	.font-size(@h1-font-size);
}

h2 {
	.font-size(@h2-font-size);
}

h3 {
	.font-size(@h3-font-size);
}

h4 {
	.font-size(@h4-font-size);
}

h5 {
	.font-size(@h5-font-size);
}

h6 {
	.font-size(@h6-font-size);
}