/*
 * Definitions
 */

// Container
@container-xsmall: 100%;
@container-small: 100%;
@container-medium: 1024px;
@container-large: 1280px;
@container-xlarge: 1440px;
@container-padding-large: 4.7rem;
@container-padding-small: 2.7rem;

/*
 * Container
 */

.container {
	position: relative;
	margin: 0 auto;
	width: 100%;
	.clearfix();
  padding-left: @container-padding-large;
  padding-right: @container-padding-large;

  @media @medium-down {
    padding-left: @container-padding-small;
    padding-right: @container-padding-small;
  }
}
