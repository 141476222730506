@field-margin: 3.5rem;
@data-icon-width: 6rem;

.field{
  margin-bottom: @field-margin;
  position: relative;

  .box-error{
    display: none;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -1.8rem;

    p.error{
      .text-error;
      .medium-font;
      .font-size(1.2) ;
      margin: 0;
      .text-left;
    }
  }

  &.error{

    &.field--textarea:before{
      bottom: inherit;
      top: 4rem
    }

    &.field--file {

      &:before{
        display: none;
      }

      input{
        //color: red;
        border: 0;
      }
    }

    input, select, textarea{
      border: 1px solid @error;
    }

    .box-error{
      display: block;

      p.error{

        &:not([hidden]){
          display: block;
        }

        &:not([hidden]) ~ p{
          display: none;
        }
      }
    }
  }

  &--checkbox{
    margin-bottom: 0;
  }

  &--checkbox-tag{
    .label{
      width: 100%;
      display: block;
      .font-size(@label-font-size);
    }

    input:checked + label{
      background: @dark;
      color: @white;
    }

    label{
      border: @input-border;
      border-radius: @input-border-radius;
      padding: 1rem!important;
      margin-bottom: 0.5rem!important;

      &:before, &:after{
        display: none;
      }
    }
  }

  &--icon{
    &:before{
      content: ''!important;
      width: @data-icon-width;
      height: (@input-height - 0.2rem);
      background: @dark;
      display: block;
      right: 0.1rem;
      position: absolute;
      bottom: 2.55rem;
      .transform(translateY(50%));
      border-radius: 0 @border-radius @border-radius 0;
    }

    &:after{
      content: attr(data-icon);
      position: absolute;
      bottom: 1.5rem;
      right: 0;
      color: @primary;
      .font-size-small;
      width: @data-icon-width;
      .text-center;
    }
  }

  &--copy{

    &:before{
      pointer-events: none;
      content: ''!important;
      width: @data-icon-width;
      height: (@input-height - 0.2rem);
      background: @dark;
      display: block;
      right: 0.1rem;
      position: absolute;
      bottom: 2.55rem;
      .transform(translateY(50%));
      border-radius: 0 @border-radius @border-radius 0;
    }

    &:after{
      pointer-events: none;
      .fonticon_copy();
      position: absolute;
      bottom: 1.5rem;
      right: 0;
      color: @primary;
      .font-size-large;
      width: @data-icon-width;
      .text-center;
    }

    input{
      cursor: pointer;
    }
  }

  &--search{
    position: relative;

    &:after{
      pointer-events: none;
      .fonticon_search();
      position: absolute;
      bottom: 1.5rem;
      right: 1rem;
      color: fade(@dark, 70%);
      .font-size-large;
    }

    input{
      padding-right: 3.2rem;
    }
  }

  &--roughness-value{
    margin-top: 4rem;
    margin-left: 1rem;
    width: 100%;
  }

  &-input-small{
    input{
      max-width: 7rem;
    }
  }

  &--read-only{
    .ng-select.ng-select-single .ng-select-container{
      background: @dark-grey;
      pointer-events: none;
    }

    .ng-select .ng-select-container{
      color: @white!important;
      background: @dark-grey!important;
      pointer-events: none;
    }
  }

  &--uppercase{
   input{
     text-transform: uppercase;
   }
  }
}
