#page-commercial-actions{

  // app-commercial-actions-table-shared{
  //   //.calc(width; "100% - 40rem");
  //   //padding-right: @gutter*2;
  // }

  // app-edit-commercial-actions-shared{
  //  // max-width: 40rem;
  // }

  .container{
    height: 100%;
    overflow: hidden;

    @media @xsmall{
      height: auto;
    }

    .title{
      margin-top: 3rem;
    }

    .table{
      overflow: auto;
      //.calc(height; "100vh - 31rem");
      padding-top: 3rem;

      @media @xsmall{
        height: auto;
      }

      table{

        th,td{
          white-space: nowrap;
        }

        td{
          &:last-of-type,
          &:nth-child(4){
            white-space: nowrap;
          }
        }
      }
    }

    .form{
      overflow: auto;
      height: 100%;
      background: @white;

      .title{
        margin-bottom: 3rem;
        .font-size-large;
        .bold-font();
      }
    }
  }
}


#page-dashboard-actions{
  position: fixed;

  .sidebar--action{
    z-index: 999;
  }
}

#page-global-commercial-actions{
  #page-commercial-actions{
    .container .table{
      //.calc(height; "100vh - 25rem");
    }

    .action-bar{
      z-index: -1;
    }
  }
}
