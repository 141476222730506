/*
 * Definitions
 */

// Input
@input-height: 5rem;
@input-margin: @label-margin;
@input-padding: 0 1.6rem;
@input-color: @body-color;
@input-font-family: @regular-font-family;
@input-font-weight: @regular-font-weight;
@input-font-size: @font-size-medium;
@input-line-height: @line-height-medium;
@input-background: @white;
@input-border: 1px solid @dark-grey;
//@input-border-radius: .8rem;
@input-border-radius: 0;

// Input Hover
@input-hover-color: @input-color;
@input-hover-background: darken(@input-background, 2%);
@input-hover-border: 1px solid darken(@light-grey, 10%);

// Input Focus
@input-focus-color: @input-color;
@input-focus-background: darken(@input-background, 2%);
@input-focus-border: 1px solid @info;

// Input Placeholder
@input-placeholder-font-family: @input-font-family;
@input-placeholder-font-size: @input-font-size;
@input-placeholder-color: fade(@input-color, 50%);
@input-placeholder-line-height: @input-line-height;
@input-placeholder-letter-spacing: 0px;
@input-placeholder-text-transform: none;

// Input Inverse
@input-inverse-color: @white;
@input-inverse-background: @inverse;
@input-inverse-border: none;

// Input Inverse Hover
@input-inverse-hover-color: @input-inverse-color;
@input-inverse-hover-background: darken(@input-inverse-background, 2.5%);
@input-inverse-hover-border: @input-inverse-border;

// Input Inverse Focus
@input-inverse-focus-color: @input-inverse-color;
@input-inverse-focus-background: darken(@input-inverse-background, 5%);
@input-inverse-focus-border: @input-focus-border;

// Input Disabled
@input-disabled-color: @light;
@input-disabled-background: @light;
@input-disabled-border: 1px solid darken(@light, 10%);

// Input Search
@input-search-border-radius: 2rem;

// Textarea
@textarea-padding: 1.6rem;
@textarea-line-height: @line-height-medium;


/*
 * Input
 */

input,
textarea {
	.input();

	.appearance(none);

	// Override Normalise for Input Types
	.box-sizing(border-box);

	// Input Size
	.input-size(@input-padding, @input-height, @input-font-size);

	// Input Style
	.input-style(@input-color, @input-background, @input-border);

	// Input Placeholder
	.placeholder(@input-placeholder-color, @input-placeholder-font-size, @input-placeholder-font-family, @input-placeholder-letter-spacing, @input-placeholder-text-transform);

	&:hover {
		.input-style(@input-hover-color, @input-hover-background, @input-hover-border);
	}

	&:focus {
		.input-style(@input-focus-color, @input-focus-background, @input-focus-border);
	}

	// Input Disabled
	&:disabled {
		cursor: no-drop;
		.input-style(@input-disabled-color, @input-disabled-background, @input-disabled-border);
	}

  &:read-only{
    background: @dark-grey;
    color: @white;
    border: 0;
    .placeholder(@white, @input-placeholder-font-size, @input-placeholder-font-family, @input-placeholder-letter-spacing, @input-placeholder-text-transform);

    &:focus{
      &::-moz-placeholder {
        color: @white;
      }
      &:focus::-moz-placeholder {
        color: @white;
      }
      &:-ms-input-placeholder {
        color: @white;
      }
      &:focus:-ms-input-placeholder {
        color: @white;
      }
      &::-webkit-input-placeholder {
        color: @white;
      }
      &:focus::-webkit-input-placeholder {
        color: @white;
      }
    }
  }
}


/*
 * Textarea
 */

textarea {
	max-width: 100%;
	.transition(~"color @{transition-fast} ease-in-out, background @{transition-fast} ease-in-out, border @{transition-fast} ease-in-out");
}

/*
 * Input Styles
 */

// Input Inverse
.input-inverse {

	// Input Inverse Style
	.input-style(@input-inverse-color, @input-inverse-background, @input-inverse-border);

	// Input Inverse Placeholder
	.placeholder(@input-inverse-color, @input-placeholder-font-size, @input-placeholder-font-family, @input-placeholder-letter-spacing, @input-placeholder-text-transform);

	&:hover {
		.input-style(@input-inverse-hover-color, @input-inverse-hover-background, @input-inverse-hover-border);
	}

	&:focus {
		.input-style(@input-inverse-focus-color, @input-inverse-focus-background, @input-inverse-focus-border);
	}
}

/*
 * Input Search
 */

// Input Search
input[type="search"] {
	border-radius: @input-search-border-radius;
	-webkit-appearance: none;

	// Override Normalise for Input Types
	.box-sizing(border-box);
}


/*
 * Input File
 */

// Input File
input[type="file"] {
	padding: 0;
	height: auto;
	background: transparent;
	border: none;

	// Override Normalise for Input Types
	.box-sizing(border-box);
}



/*
 * Input Number
 */

// Input Number

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  .appearance(none);
}

/*
 * Checkbox and Radio
 */

// Checkbox and Radio Buttons
input[type="checkbox"],
input[type="radio"] {
	display: inline-block;
	margin-right: 10px;
	width: auto;
	height: auto;

	& + p {
		margin: 0;
		display: inline-block;
	}

	& + label {
		display: inline-block;
	}
}

input[type="checkbox"] {
  .appearance(none);
  z-index: -9999999;
  margin: 0;
  padding: 0;
  opacity: 0;
  position: absolute;
  //left: 0;
  //top: 0;

  &:checked+label:after{
    content: '';
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .4rem;
    position: absolute;
    left: 0.5rem;
    background: @dark;
    top: -.2rem
  }

  &:disabled+label:before{
    background: @dark-grey;
  }

  +label{
    padding-left: 5rem;
    position: relative;
    margin-right: 1rem;
    margin-bottom: 2rem;

    &:before{
      content: '';
      border: @input-border;
      border-radius: @input-border-radius;
      height: 3.6rem;
      width: 3.6rem;
      position: absolute;
      left: 0;
      top: -.8rem;
      background: @white;
    }
  }
}

input[type="radio"] {
	.appearance(none);
  z-index: -9999999;
  margin: 0;
  padding: 0;
  opacity: 0;
  position: absolute;

  &:checked+label:after{
    content: '';
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .4rem;
    position: absolute;
    left: 0.5rem;
    background: @dark;
    top: -.2rem
  }

  +label{
    padding-left: 5rem;
    position: relative;
    margin-right: 1rem;
    margin-bottom: 2rem;

    &:before{
      content: '';
      border: @input-border;
      border-radius: @input-border-radius;
      height: 3.6rem;
      width: 3.6rem;
      position: absolute;
      left: 0;
      top: -.8rem;
      background: @white;
    }
  }
}


/*
 * Textarea
 */

// Reset height for `textarea`s
textarea {
	padding: @textarea-padding;
	height: auto;
	line-height: @textarea-line-height;
}
