#page-prospecting{
  padding-top: 1.8rem;

  .interested{
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border: 1px solid @dark;
    border-radius: @border-radius;
    position: relative;
    margin: auto;

    &.active:before{
      content: '';
      .center();
      background: @dark;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: @border-radius;
    }
  }

  td[data-label="Teléfono"],
  td.phone {
    min-width: 10.5rem;
  }
}

#prospecting-filters {
  margin-bottom: 0;

  .fields {

    .field {
      align-items: flex-start;
      flex-direction: column;
      margin: 0;
    }

    select, .ng-select {
      min-width: 0;
      width: 100%;
    }

    button {
      margin-bottom: 0;

      @media @small-down {
        height: 4rem;
        line-height: 4rem;
      }
    }
  }

}
