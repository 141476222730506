#page-form{
  padding-top: 5rem;

  .form{
    max-width: 79rem;
    margin: auto;

    &--small{
      max-width: 36.5rem;
    }

    h2{
      .text-center;
      .font-size-large;
      color: @dark;
    }

    .title{
      max-width: 36.5rem;
      margin: auto auto 2rem;
    }

    .subtitle{
      .font-size-medium;
      .bold-font();
      .text-center;
      border-bottom: .8rem solid @grey;
      padding-bottom: 1rem;
      margin-top: 3rem;
      margin-bottom: 2rem;
    }

    .info{
      .font-size(2.2);
      .bold-font();
    }

    .time-change{
      .flex-display();
      .align-items-center;
      color: @dark;
      .font-size(1.4);
      margin-bottom: 2rem;

      &.active .toggle-container{
        background: @primary;

        .dialog-button{
          left: 3.9rem;
        }
      }

      .toggle-container{
        width: 7rem;
        background-color: @grey;
        cursor: pointer;
        user-select: none;
        border-radius: 4rem;
        padding: .2rem;
        height: 3.2rem;
        position: relative;
        margin-left: 1rem;

        .dialog-button{
          background-color: @dark;
          left: .2rem;
          box-shadow: 0px 4px 4px fade(black, 0.25);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.8rem;
          height: 2.8rem;
          border-radius: 50%;
          position: absolute;
          transition: all 0.3s ease;
        }
      }
    }
  }
}
