.roi-progress-bar{
  top: @header-small-height;
  .progress-bar()
}

.machining-study-progress-bar{
  top: @header-large-height;
  .progress-bar()
}

.trial-progress-bar{
  top: @header-small-height;
  .progress-bar()
}

