@progress-height: 1rem;
@progress-background-color: #E6E7F3;

.progress{
  position: absolute;
  left: 0;
  height: 1rem;

  &--error{
    background: @error;
  }

  &--warning{
    background: @warning;
  }

  &--success{
    background: @success;
  }
}
