@modal-body-padding: 4rem;

// Antiguo

// ngx-smart-modal{

// }

.nsm-overlay-open{
  z-index: 999!important;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: fade(@dark,83%);
}

/* .nsm-dialog{
  top: 50%;
  min-height: inherit!important;
  .transform(translateY(-50%));
} */

.modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top:0;
  padding: @gutter;
  z-index: 9999;
}

.modal,.nsm-dialog{

  .modal-content,.nsm-content{
    .center();
    position: fixed;
    background: @white;
    max-width: 420px;
    width: 98vw;
    min-height: 4rem;
    box-shadow: 0 2px 15px rgba(0,0,0,0.5);
    border-radius: @border-radius;
    padding: 0;
    margin: 0;

    .modal-header{
      padding: @gutter @gutter*2;
      position: relative;
      .font-size-large;
      .bold-font;
      background: @light-grey;
      border-radius: @border-radius @border-radius 0 0;

      h4,p{
        .font-size-large;
        color: @dark;
        font-weight: @bold-font-weight;
        margin: 0;
      }

      .close{
        position: absolute;
        right:2rem;
        top: 50%;
        .transform(translateY(-50%));
        .font-size(1.8);
        margin: 0;
        color: @dark;

        // &:before{

        // }

        &:hover{
          .text-primary;
        }
      }
    }

    .modal-body{
      padding: @gutter*2;
      overflow: auto;
      max-height: calc(100vh - 20rem);
      .calc(max-height;"100vh - 20rem");
    }

    .modal-footer{
      border-top: 0.1rem solid @border-color;
      .text-center;
      padding: @gutter*2;
      width: auto!important;

      .button,a{
        margin: 0 @gutter/2;
        width: auto!important;
        min-width: 13rem;
      }
    }
  }
}

.modal--small{
  .modal-content,.nsm-content {
    max-width: 50rem!important;
  }
}

.modal--medium{
  .modal-content,.nsm-content {
    max-width: 75rem;
  }
}

.modal--large{
  .modal-content,.nsm-content {
    max-width: 100rem;
      .preview-img {
        display: block;
        margin: 0 auto;
      }
  }
}

.modal--confirmation{
  .modal-header{
    .text-center;
  }
}

.modal--preview{
  .modal-content,.nsm-content {
    width: 98vw;
    //height: 98vh;
    //max-width: inherit!important;
    max-width: 75rem !important;

    .modal-header{
      position: relative;
      z-index: 2;
      .text-center;
      background: transparent!important;

      h3{
        .text-dark;
        .bold-font();
      }

      .actions{
        position: absolute;
        right: 4rem;

        button{
          position: relative;
          margin: 0 1rem;

          span:before{
            .font-size(2);
            .center();
          }
        }
      }
    }

    .modal-body{
      .text-center;
      .calc(max-height;"100vh - 28rem");
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;

      &.is-pdf{
        overflow: auto;
      }

      .icon-navigation{
        position: absolute;
        top: 50%;
        .transform(translateY(-50%));
        z-index: 2;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;

        &.icon--arrow-left{
          left: 4rem;
        }

        &.icon--arrow-right{
          right: 4rem;
        }
      }

      drag-scroll{
        .calc(height;"100vh - 32rem");
        width: 100%;
        cursor: pointer;

        .drag-scroll-content{
          width:100%!important;
          height: 100%!important;
          .flex-display();
          .justify-content-center();
        }
      }

      .image{
        .calc(height;"100vh - 32rem");
        width: 100%;
        margin: auto;
        overflow: auto;
        position: relative;
        cursor: grab;

        img{
          width: auto;
          display: block;
          margin: auto;
          flex: 0 0 auto;
        }
      }

      img{
        width: 100%;
        display: block;
        margin: auto;
      }

      .button{
        margin: 2rem auto 0;
      }
    }
  }
}

.modal--comments,
.modal--notes{
  .modal-content,.nsm-content {
    max-width: 120rem;

    p{
      white-space: pre-line;
    }
  }
}

.modal--add-project{
  .modal-content,.nsm-content {
    max-width: 50rem!important;

   .modal-body{
     overflow: inherit;
   }
  }
}

.modal--customer-actions{
  .modal-content,.nsm-content {
    max-width: 114rem!important;
  }
}

// Nuevo (Víctor)

.modal--text {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top:0;
  z-index: 9999;
  background: fade(@darker,20%);

  * {
    font-size: 1.4rem;
  }

  .modal {

    &__content {
      .center();
      position: fixed;
      background: #FFF;
      min-height: 4rem;
      width: 90%;
      max-width: 58.5rem;
      padding: 4.5rem 6.25rem 3rem;
      margin: 0;
    }


    &__close {
      position: fixed;
      top: 1.5rem;
      right: 1.45rem;
      color: @darker;
      border: 1px solid;
      width: 2.4rem;
      height: 2.4rem;
      line-height: 1;
      .center-flex;

      .icon {
        height: 1.4rem;
      }

      .icon::before {
        margin-right: 0;
        font-size: 1.2rem;
      }
    }

    &__header {
      padding-bottom: 1.2rem;
      border-bottom: 4px solid @dark;
      margin-bottom: 2.4rem;
    }

    &__header-content {
      display: flex;
      align-items: flex-end;
    }

    &__title {
      font-size: 1.6rem;
      text-transform: uppercase;
      color: @darker;
      .bold-font;
    }

    &__subtitle {
      margin-bottom: 0;
    }

    &__action-icon {
      margin: 0 0 0.5rem 1.4rem;

      span:before {
        color: @darker;
        font-size: 1.6rem;
        padding: 0.8rem;
        border-radius: 50%;
        border: 1px solid;
        text-align: center;
      }
    }

    &__body {
      padding-bottom: 2.4rem;
      border-bottom: 1px solid @dark;
      margin-bottom: 2.4rem;
    }

    &__footer {
      text-align: center;
    }
  }
}

.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      width: 95vw;
      height: 95vh;
      overflow: hidden;
  }

}