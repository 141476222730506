#app{
  .flex-display();
  height: 100%;

  .main{
    //height: 100vh;
    overflow: auto;
    height: 100%;
    width: 100%;  // (Sin esto a veces no ocupa el ancho completo)
    background-color: #FFF;
  }
}