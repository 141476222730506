#page-trial{

  @media @xsmall{
    margin-top: 14rem;
  }

  .sidebar{
    width: 48.7rem;

    .fields{
      overflow: hidden;
      padding: 0.1rem;
    }
  }

  .container{
    padding-top: 3rem;
    height: 100%;

    .table-responsive{
      @media @xsmall{
        max-height: inherit!important;
      }
    }

    table{

      tr+tr{
        background: @grey;

        td{
          .calc(max-width; "100vw - 14rem");

          @media @xsmall{
            max-width: inherit;
          }

          .table-responsive{
            max-height: inherit!important;
          }
        }
      }

      th{
        &.no-wrap{
          white-space: nowrap;
        }
      }

      td{
        white-space: nowrap;

        span.icon{
          cursor: pointer;
        }
      }
    }

    .table-responsive:not(.calculator){
      //.calc(max-height;"100vh - 45rem");
      //margin-bottom: 3rem;
    }

    .calculator{
      h2{
        .font-size-medium;
        .text-center;
        .text-dark;
        margin: 0;
      }

      td{
        .text-white;

        &.input{
          padding: 0;

          input{
            border-radius: 0;
            height: 4.3rem;
            margin: 0;
          }
        }
      }
    }
  }

  .action-bar {

    .container {
      padding-top: 0;
      height: auto;
    }
  }
}
