#create-action {
  background-color: #FFF;

  @media @xsmall{
    height: auto;
    margin-top: 14rem;
    padding-bottom: @action-bar-height + 2;
  }

  .form-filters {
    max-width: 120rem;
    margin: auto;

    .fields {
      width: 100%;
    }
  }

  .actions_panels {
    display: flex;
    justify-content: space-between;
    max-width: 120rem;
    margin: auto;

    &__action-list {
      width: 55%;
      display: flex;
    }

    &__documents {
      width: 40%;

      h2 {
        font-size: 1.6rem;
        color: @dark;
      }

      .text-left a {
        color: @dark;

        &:hover {
          color: @primary;
        }
      }
    }

    &__column {
      width: 47.5%;

      @media @medium-small-down {
        width: 100%;
        margin-bottom: 1.5rem;
      }
    }

    @media @medium-small-down {
      flex-wrap: wrap;

      &__container {
        flex-direction: column;
      }
    }

    &__title {
      text-transform: uppercase;
      font-size: 1.6rem;
      color: @darker;
      .bold-font;
    }

    &__list {
      margin: 0 0 0.8rem;
      background-color: fade(@dark, 4%);
      list-style-type: none;
      padding: 1rem 1.2rem;
      font-size: 1.4rem;
      .flex;
      .align-items-center;

      &:hover {
        background-color: fade(@dark, 10%);
      }

      @media @medium-small-down {
        flex-wrap: wrap;
        padding: 1rem;
      }

      li {
        margin: 0;
        padding: 0 1.2rem 0 0;

        @media @medium-down {
          font-size: 1.2rem;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    &__tel {
      width: 20%;
      min-width: 3rem;

      span:before {
        font-size: 1.4rem;
        padding: 0.4rem;
        border-radius: 50%;
        border: 1px solid;
        text-align: center;
      }
    }

    &__date, &__hour {
      display: flex;
      align-items: center;
    }

    &__date {
      width: 50%;
    }

    &__hour {
      width: 30%;
    }

  }

  .text-button {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;


    &__text {
      width: 50%;

      @media @small-down {
        width: 55%;
      }
    }

    &__main-text {
      color: @darker;
      font-size: 1.4rem;
      margin: 0;
    }

    &__secondary-text {
      color: fade(@dark, 50%);
      font-size: 1rem;
      line-height: 1;
    }

    &__button {
      width: 45%;

      @media @small-down {
        width: 40%;
      }

      .button {
        @media @medium-down {
          max-width: 100%;
          line-height: 1.35;
          white-space: normal;
          padding: 1rem 2.2rem;
          height: auto;
        }
      }
    }
  }

  .upload-list {
    margin: 1rem 0 0;
    padding:0;
    list-style-type: none;
    border-top: 1px solid #D0D0D0;

    &__file {
      margin: 0;
      padding: 0.8rem 0;
      border-bottom: 1px solid #D0D0D0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 1.3rem;
      color: @darker;
    }

    &__file-name {
      //display: inline-block;
      padding-right: 2.4rem;
    }
  }
}

// Mover más adelante a carpeta elements
// si se va a reutilizar en otras páginas

.text_group {
  margin-bottom: 2.4rem;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0;
    line-height: 1.25;
  }

  &__title {
    .bold-font;
  }
}

.icon__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 1.4rem;
  .flex;
  .align-items-center;

  li {
    margin: 0;
    padding: 0 4rem 0 0;
    display: flex;
    align-items: center;


    &:last-child {
      padding-right: 0;
    }
  }
}

.modal--text {
  .upload-list {
    margin: 0;
    padding:0;
    list-style-type: none;

    &__file {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }

    &__file-name {
      display: inline-block;
      padding-right: 4rem;
    }
  }
}
