#not-found-404{

    .not-found-container{
        padding-top: 12rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .logo{
            width: 28rem;
        }

        .img-404{
            margin-top: 6rem;
            width: 40rem;
        }

        .title{
            margin: 6rem 0 0 0;
            color: @dark;
            font-weight: bold;
            .font-size(5);
        }

        .subtitle{
            margin-top: 7rem;
            .font-size(3);

            span{
                color: @dark;
                font-weight: bold;

                &:hover{
                    color: @border-color;
                }
            }
        }


    }
}