/*
 * Definitions
 */

// Button
@button-padding: 0 2.2rem;
@button-height: 4.8rem;  // antes 4rem
@button-font-family: @medium-font-family;
@button-font-weight: @bold-font-weight;
@button-font-size: @font-size-small;
@button-color: @primary;
@button-line-height: @button-height;
@button-letter-spacing: 0.05rem;
@button-text-align: center;
@button-text-transform: none;
@button-background: @dark;
@button-border: 1px solid @dark;
@button-border-radius: .4rem;

// Button Hover
@button-hover-color: @primary;
@button-hover-background: darken(@button-background, 10%);
@button-hover-border: 1px solid darken(@primary, 10%);

// Button Disabled
@button-disabled-opacity: 0.5;

// Button Block
@button-block-text-align: center;


/*
 * Button
 */

// Button
button,
.button {
	.button();
	.button-size(@button-padding, @button-height, @button-font-size);
	.button-theme(@button-color, @button-background, @button-border, @button-hover-color, @button-hover-background, @button-hover-border);
}

// Button Disabled
button:disabled {
	cursor: no-drop;
	opacity: @button-disabled-opacity;
}

// iOS "clickable elements" fix for role="button"
[role="button"] {
	cursor: pointer;
}

/*
 * Button Modifiers
 */

// Button Block
.button-block {
	display: block;
	width: 100%;
	text-align: @button-block-text-align;
}

// Button Center
.button-center {
	display: inline-block;
	margin: 0 auto;
}
