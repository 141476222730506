#page-report{
  .flex-display();

  .report-content{
    width: 25%;

    &:not(:last-of-type){
      border-right: 1px solid @border-color;
    }

    ul{
      .list-unstyled;
      margin: 0;
      padding: 0 0 8rem;
      height: 100%;
      overflow: auto;

      li{
        .flex-display();
        .font-size-small;
        .justify-content(space-between);
        padding: 1rem;
        border-bottom: 1px solid @border-color;
      }
    }
  }

  .totals{
    background: @dark;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: @white;
    .flex-display();
    .align-items-center;

    span{
      width: 25%;
      .text-center;
      .bold-font();
      .font-size(2);
      height: 8rem;
      line-height: 8rem;

      &:not(:last-of-type){
        border-right: 1px solid @border-color;
      }
    }
  }
}
