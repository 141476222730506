/*
 * Definitions
 */

// Pullout
@pullout-margin: @blockquote-margin;
@pullout-padding: @blockquote-padding;
@pullout-font-family: @blockquote-quote-font-family;
@pullout-font-weight: @blockquote-quote-font-weight;
@pullout-font-size: @blockquote-quote-font-size;
@pullout-color: @blockquote-quote-color;
@pullout-border: solid @primary;
@pullout-border-width: 0 0 0 0.4rem;


/*
 * Pullout
 */

.pullout {
	margin: @pullout-margin;
	padding: @pullout-padding;
	font-family: @pullout-font-family;
	font-weight: @pullout-font-weight;
	.font-size(@pullout-font-size);
	color: @pullout-color;
	border: @pullout-border;
	border-width: @pullout-border-width;
}