owl-date-time {
  position: absolute;
  //display: none;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: hidden;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-out {
  opacity: .5;
}

.owl-dt-container{
  width: 30rem!important;
  background: @white;
  border-radius: 4px;

  .owl-dt-calendar{
    height: auto!important;

    .owl-dt-calendar-control{
      color: @dark;

      button{
        min-height: inherit;
        box-shadow: none;
        background: transparent;
        height: auto!important;
        min-width: inherit;
        font-size: 1.3rem;

        &:not(:disabled):hover{
          border:0;
          //color: @primary;
          color: @darker !important;
          background-color: transparent !important;

          > span {
            background-color: transparent !important;
          }
        }
      }
    }

    .owl-dt-calendar-main{
      .owl-dt-calendar-multi-year-view{
        button{
          min-height: inherit;
          box-shadow: none;
          background: transparent;
          height: auto!important;
          min-width: inherit;
          .font-size-small
        }
        .owl-dt-calendar-table th {
          font-size: 1.2rem;
        }
      }

      .owl-dt-calendar-view{
        .owl-dt-calendar-table{
          thead.owl-dt-calendar-header{
            tr.owl-dt-weekdays{
              th{
                padding: 0 0.4rem;
                .font-size-small;
                border: 0!important;
              }
            }

            tr{
              th.owl-dt-calendar-table-divider{
                border: 0;
                padding: 0;
              }
            }
          }

          tbody{
            tr{
              td{
                background: transparent;
              }

              .owl-dt-calendar-cell-selected{
                background: @dark;
              }

              .owl-dt-calendar-cell-content{
                .font-size-xsmall;
              }

              .owl-dt-calendar-cell-in-range{
                //background: lighten($primary, 40%);
                background: transparent;
              }

              .owl-dt-calendar-cell{
                color: @dark;
              }

              .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){
                border-color: @primary;
              }
            }
          }
        }
      }
    }
  }

  .owl-dt-container-buttons{
    height: 4rem!important;

    .owl-dt-container-control-button{
    }
  }
}

.owl-dt-container-info{
  display: none;
}
