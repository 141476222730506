.heading{
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--underline {
        padding-bottom: 1.2rem;
        border-bottom: 1px solid;
    }

    &__title {
        width: 60%;
        margin: 0;
    }
    &__link {
        width: 35%;
        text-align: right;
        .font-size-small;
        .bold-font;

        &:after {
            content: "\25B8";
            display: inline-block;
            margin-left: 1.2rem;
            transform: scale(0.75, 1.5);
        }
    }
}


.heading--dark {

	.heading {
		&__title {
			font-size: 2.4rem;
			color: @primary;
            line-height: 1;
			.bold-font;
			.special-font;
		}
	}
}