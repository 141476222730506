/*
 * Definitions
 */

// Select
@select-margin: @input-margin;
@select-padding: @input-padding;
@select-height: @input-height;
@select-font-family: @input-font-family;
@select-font-weight: @input-font-weight;
@select-font-size: @input-font-size;
@select-color: @input-color;
@select-line-height: auto;
@select-background: @input-background;
@select-border: @input-border;
@select-border-radius: 0.8rem;

// Select Arrow
@select-arrow-size: 0.4rem;
@select-arrow-background: @dark;

// Select Hover
@select-hover-color: @input-hover-color;
@select-hover-background: @input-hover-background;
@select-hover-border: @input-hover-border;

// Select Focus
@select-focus-color: @input-focus-color;
@select-focus-background: @input-focus-background;
@select-focus-border: @input-focus-border;

// Select Inverse
@select-inverse-color: @input-inverse-color;
@select-inverse-border: @input-inverse-border;
@select-inverse-background: @input-inverse-background;
@select-inverse-arrow-background: @white;

// Select Inverse Hover
@select-inverse-hover-color: @input-inverse-hover-color;
@select-inverse-hover-background: @input-inverse-hover-background;
@select-inverse-hover-border: @input-inverse-hover-border;

// Select Inverse Focus
@select-inverse-focus-color: @input-inverse-focus-color;
@select-inverse-focus-background: @input-inverse-focus-background;
@select-inverse-focus-border: @input-inverse-focus-border;


/*
 * Select
 */

select {
	display: block;
	width: 100%;
}

.select {
	position: relative;
	display: block;
	margin: @select-margin;

	// Select
	select {
		margin: 0;
		padding: @select-padding;
		height: @select-height;
		line-height: @select-line-height;
		font-family: @select-font-family;
		font-weight: @select-font-weight;
		.font-size(@select-font-size);
		color: @select-color;
		cursor: pointer;
		outline: 0;
		background: @select-background;
		border: @select-border;
		border-radius: @select-border-radius;
		.transition(all @transition-fast ease-in-out);
		.appearance(none);

		// Undo the Firefox inner focus ring
		&::-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #000;
		}

		// Hide the arrow in IE10 and up
		&::-ms-expand {
			display: none;
		}

		&:hover {
			.input-style(@select-hover-color, @select-hover-background, @select-hover-border);
		}

		&:focus {
			.input-style(@select-focus-color, @select-focus-background, @select-focus-border);
		}
	}

	// Dropdown Arrow
	&:after {
		position: absolute;
		top: 53%;
		right: 15px;
		margin-top: -(@select-arrow-size / 2);
		width: 0;
		height: 0;
		content: '';
		pointer-events: none;
		border-left: @select-arrow-size solid transparent;
		border-right: @select-arrow-size solid transparent;
		border-top: @select-arrow-size solid @select-arrow-background;
	}
}


/*
 * Select Inverse
 */

.select-inverse {

	select {
		color: @select-inverse-color;
		border: @select-inverse-border;
		background: @select-inverse-background;

		&:hover {
			.input-style(@select-inverse-hover-color, @select-inverse-hover-background, @select-inverse-hover-border);
		}

		&:focus {
			.input-style(@select-inverse-focus-color, @select-inverse-focus-background, @select-inverse-focus-border);
		}
	}

	&:after {
		border-top: @select-arrow-size solid @select-inverse-arrow-background;
	}
}


/*
 * Select Icon
 */

// Preparar clase para usar icono de chevron-down para el select
// https://www.heapcoding.com/how-to-change-select-dropdown-arrow-in-css/
// https://stackoverflow.com/questions/56593500/change-the-dropdown-arrow-design

// .select-icon {}