#page-dashboard{
  background-color: #FFF;

  @media @xsmall{
    height: auto;
    margin-top: 14rem;
    padding-bottom: @action-bar-height + 2;
  }

  .dashboard-sections {
    display: flex;
    justify-content: space-between;

    @media @medium-small-down {
      flex-wrap: wrap;
    }

    &__section {
      width: 22.5%;

      @media @medium-down {
        width: 24%;
      }

      @media @medium-small-down {
        width: 48%;
      }

      &:nth-child(3), &:nth-child(4) {
        @media @medium-small-down {
          margin-top: 2rem;
        }
      }
    }

    &__title {
      text-transform: uppercase;
      text-align: center;
      font-size: 1.6rem;
      padding-top: 1.4rem;
      border-top: 7px solid @primary;
      color: @darker;
      .bold-font;

      @media @medium-down {
        font-size: 1.4rem;
      }
    }

    &__column {
      .calc(height; "100vh - 30rem");
      overflow: auto;

      @media @medium-small-down {
        .calc(height; "100vh - 70rem");
      }
    }
  }

  .project {
    margin-bottom: 0.8rem;

    &__main {
      background-color: @dark;
      //min-height: 13.5rem;
      padding: 1.25rem;
      border-radius: 4px;
      color: #FFF;
      display: flex;

      @media @medium-down {
        font-size: 1.2rem;
        position: relative;
      }
    }

    &__letter {
      width: 12%;
      min-width: 3rem;
      margin-right: 5%;

      img {
        width: 3rem;
        height: 3rem;
        object-fit: cover;
        border-radius: 50%;
      }

      @media @medium-down {
        width: 10%;
        min-width: 2.5rem;
        margin-right: 4%;
      }

      span {
        font-size: 1.8rem;
        text-transform: uppercase;
        background-color: @primary;
        color: @dark;
        width: 3rem;
        height: 3rem;
        .bold-font;
        .circle;

        @media @medium-down {
          font-size: 1.6rem;
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    &__content {
      width: 65%;
      margin-right: 6%;
      .font-size-small;

      @media @medium-down {
        width: 85%;
        margin-right: 5%;
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
    }

    &__title {
      color: #FFF;
      line-height: 1.25;
      .font-size-small;
    }

    &__product {
      display: block;
      margin-bottom: 0.5rem;
      .bold-font;
    }

    &__meta {
      font-size: 1rem;
      display: flex;
      margin-bottom: 1.2rem;

      @media @medium-down {
        font-size: 1rem;
      }

      .icon:before {
        font-size: 1rem;

        @media @medium-down {
          font-size: 0.9rem;
          margin-right: 0;
        }
      }

      .icon--sync:before {
        @media @medium-down {
          margin-right: 0.1rem;
        }
      }

    }

    &__date {
      width: 40%;
      margin-right: 5%;

      @media @medium-down {
        width: 45%;
      }

      .icon--chevron-right {
        margin-right: 0;
      }
    }

    &__update  {
      width: 55%;
      @media @medium-down {
        width: 50%;
      }
    }

    &__date, &__update {
      @media @medium-down {
        width: auto;
      }
    }

    &__res {
      @media @medium-down {
        padding-right: 1.8rem;
      }
    }


    &__notification {
      width: 12%;
      min-width: 2rem;
      position: relative;
      align-self: flex-end;

      @media @medium-down {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
      }


      .icon--alert:before {
        font-size: 2.4rem;

      }

      .notifications-number {
        font-size: 0.8rem;
        font-weight: @bold-font-weight;
        position: absolute;
        top: 0rem;
        left: 1rem;
        background-color: @primary;
        border: 2px solid @dark;
        color: @darker;
        width: 1.8rem;
        height: 1.8rem;
        .circle;
      }


    }

    &__child {
      background-color: fade(@dark, 4%);
      min-height: 4rem;
      margin-top: 0.8rem;
      padding: 1.25rem 0.8rem;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__logo {
      width: 20%;
      margin-right: 5%;
      .fleximage;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    &__child-product {
      width: 40%;
      margin-right: 5%;
      font-size: 1.4rem;
      color: @dark;
      .truncate();
    }

    &__status {
      width:30%;
      min-width: 7rem;

      span {
        width: 1.5rem;
        height: 0.5rem;
        display:inline-block;
        margin-right: 0.3rem;
        background-color: @dark;

        @media @medium-down {
          width: 1rem;
          height: 0.4rem;
          margin-right: 0.2rem;
        }

        &:last-child {
          margin-right: 0;
        }

        &.done {
          background-color: @primary;
        }
      }
    }
  }
}

