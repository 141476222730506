@action-bar-height: 7.7rem;
@action-bar-padding-top: 1.4rem;

.action-bar{
  position: fixed;
  background-color: #FFF;
  .calc(width; "100vw - @{main-sidebar-small-width}");
  min-height: @action-bar-height;
  bottom: 0;
  z-index: 10;
  transition: all ease-in-out 0.5s;
  left: @main-sidebar-small-width;
  padding-top: @action-bar-padding-top;


  @media @large-up{
    left: @main-sidebar-width;
    .calc(width; "100vw - @{main-sidebar-width}");
  }

  @media @xsmall{
    left: 0;
    width: 100%;
  }
  /*
  &__progress-container{
    background: @progress-background-color;
    height: @progress-height;
  }
  */

 &__actions {
   .flex-display();
   .align-items-center;
   justify-content: flex-end;
   height: @action-bar-height;
   border-top: 1px solid @darker;

   .button + .button{
    margin-left: 1.6rem;
   }

 }

 // Dropdown for action bar
 .options-content{
  position: relative;
  margin-left: 1rem;

    &:hover .options-dropdown{
      display: block;
    }

    > .button{
      position: relative;
    }

    .options-dropdown {
      position: absolute;
      background: @white;
      right: 0;
      bottom: 4.8rem;
      box-shadow: 0 .2rem 1.7rem fade(@dark, 30%);
      display: none;

      ul{
        list-style: none;
        margin: 0;
        padding: 1rem 0;

        li{
          margin: 0;
          padding: 0 0.5rem;
          .font-size(1.4);
          .text-left;
          white-space: nowrap;

          &:last-child{
            margin: 0;
          }

          a:not(.button){
            color: @dark;
            padding: 1rem 2rem;
            display: block;

            &:hover {
              background-color: fade(@primary, 50%);
            }

            span{
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}

#page-documents,
#page-machining-study {

  .action-bar {

    .container {
      overflow: visible;
    }
  }
}