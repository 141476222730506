/*
 * Definitions
 */

// Link
@link-color: @primary;
@link-text-decoration: none;

// Link Hover
@link-hover-color: @dark;
@link-hover-decoration: none;


/*
 * Link
 */

a {
	color: @link-color;
	text-decoration: @link-text-decoration;
	.transition(color @transition-fast ease-in-out);
  cursor: pointer;

	&:not(.button):hover,
	&:not(.button):focus {
		color: @link-hover-color;
		text-decoration: @link-hover-decoration;
	}
}
