/*
 * Definitions
 */

// Gutter
@gutter: 1.6rem;
@gutter-xlarge: @gutter;
@gutter-large: @gutter;
@gutter-medium: @gutter;
@gutter-small: @gutter;
@gutter-xsmall: @gutter;


/*
 * Gutter
 */

.no-gutter {
	padding: 0 !important;
}

.gutter-top {
	padding-top: (@gutter * 2) !important;
}

.gutter-bottom {
	padding-bottom: (@gutter * 2) !important;
}

.gutter-left {
	padding-left: @gutter !important;
}

.gutter-right {
	padding-right: @gutter !important;
}

@media @xlarge {

	.no-gutter-xlarge {
		padding: 0 !important;
	}

	.gutter-top {
		padding-top: (@gutter-xlarge * 2) !important;
	}

	.gutter-bottom {
		padding-bottom: (@gutter-xlarge * 2) !important;
	}

	.gutter-left {
		padding-left: @gutter-xlarge !important;
	}

	.gutter-right {
		padding-right: @gutter-xlarge !important;
	}
}

@media @large {

	.no-gutter-large {
		padding: 0 !important;
	}

	.gutter-top {
		padding-top: (@gutter-large * 2) !important;
	}

	.gutter-bottom {
		padding-bottom: (@gutter-large * 2) !important;
	}

	.gutter-left {
		padding-left: @gutter-large !important;
	}

	.gutter-right {
		padding-right: @gutter-large !important;
	}
}

@media @medium {

	.no-gutter-medium {
		padding: 0 !important;
	}

	.gutter-top {
		padding-top: (@gutter-medium * 2) !important;
	}

	.gutter-bottom {
		padding-bottom: (@gutter-medium * 2) !important;
	}

	.gutter-left {
		padding-left: @gutter-medium !important;
	}

	.gutter-right {
		padding-right: @gutter-medium !important;
	}
}

@media @small {

	.no-gutter-small {
		padding: 0 !important;
	}

	.gutter-top {
		padding-top: (@gutter-small * 2) !important;
	}

	.gutter-bottom {
		padding-bottom: (@gutter-small * 2) !important;
	}

	.gutter-left {
		padding-left: @gutter-small !important;
	}

	.gutter-right {
		padding-right: @gutter-small !important;
	}
}

@media @xsmall {

	.no-gutter-xsmall {
		padding: 0 !important;
	}

	.gutter-top {
		padding-top: (@gutter-xsmall * 2) !important;
	}

	.gutter-bottom {
		padding-bottom: (@gutter-xsmall * 2) !important;
	}

	.gutter-left {
		padding-left: @gutter-xsmall !important;
	}

	.gutter-right {
		padding-right: @gutter-xsmall !important;
	}
}