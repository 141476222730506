.route{
  .font-size(1.5);
  margin-bottom: 2rem;

  a{
    .bold-font();
    color: @dark;
  }

  a,span{

    &:not(:last-child):after{
      content: "/";
    }

    &:after{
      margin: 0 0.5rem
    }
  }
}
