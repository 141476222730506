@main-sidebar-small-width: 8.4rem;
@main-sidebar-width: 20rem;

.sidebar{

  &--main{
    background: @primary;
    min-width: @main-sidebar-small-width;
    height: 100%;
    position: relative;

    .logo-container {
      display: block;
      padding-top: 2.2rem;

      @media @large-up, @xsmall {
        padding-top: 0;
      }
    }

    .logo{
      display: none;
      margin: auto;
      padding-top: 1rem;

      @media @large-up{
        width:  56%;
        display: block;
      }
    }


    .logo-letter {
      font-size: 2.4rem;
      text-transform: uppercase;
      color: @primary;
      background-color: @darker;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 2.4rem;
      padding-top: 0.2rem;
      margin: 0 auto;
      .special-font;


      // Only show on collapsed sidebar
      @media @large-up, @xsmall {
        display: none;
      }
    }

    @media @xsmall{
      position: fixed;
      top: 0;
      width: 100%;
      height: 7rem;
      z-index: 999;

      .nav-menu-active &{
        height: 100vh;

        nav{
          display: block;
          margin-top: 8rem;

          li{
            a{
              .font-size(1.6);
              width: 70%;
              .flex-display();
              .align-items-center;
              .justify-content-flex-start;
              padding: 0 1rem;
              line-height: 1.2;

              &:before{
                position: relative;
                left: inherit;
                top: inherit;
                transform: inherit;
                margin-right: 1rem;
              }
            }
          }
        }
      }

      .logo{
        display: block;
        position: absolute;
        top: 2rem;
        left: 2rem;
      }
    }

    @media @large-up{
      min-width: @main-sidebar-width;
    }

    nav{
      margin-top: 3rem;

      @media @xsmall{
        display: none;
      }

      li{
        .text-center;
        padding: 0;
        margin: 2rem auto;

        a{
          color: @darker;
          width: 5.7rem;
          height: 5.7rem;
          border-radius: 0.4rem;
          display: block;
          margin: auto;
          position: relative;
          .font-size(0);
          border: 1px solid @primary;

          @media @large-up{
            width: 90%;
            line-height: 5.7rem;
            text-align: left;
            padding-left: 5rem;
            .font-size(1.5);
            display: flex;
            align-items: center;
            line-height: 1.2;
          }

          &:hover{
            border: 1px solid @darker;
          }

          &:before{
            .font-size(3);
            margin: 0;
            .center();

            @media @large-up{
              left: 2.5rem;
            }
          }

          &.icon--roi:before{
            .font-size(2.2)
          }

          .notifications-number {
            font-size: 0.9rem;
            font-weight: @bold-font-weight;
            position: absolute;
            top: 0.8rem;
            left: 2.75rem;
            background-color: @darker;
            //border: 2px solid @primary;
            color: @primary;
            width: 1.6rem;
            height: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.2rem 0 0  0.1rem;
            .circle()
          }
        }

        &.active a{
          background: @darker;
          color: @primary;
          border: 1px solid @dark;

          .notifications-number {
            background-color: @primary;
            //border: 2px solid @darker;
            color: @darker;
          }
        }

        // &.gear{
        //   position: absolute;
        //   bottom: 1.5rem;
        //   left: 0;
        //   right: 0;
        // }

        // &.logout{
        //   position: absolute;
        //   bottom: 7rem;
        //   left: 0;
        //   right: 0;
        // }
      }
    }
  }

  &--action{
    background: @white;
    box-shadow: 0 .2rem .9rem @header-shadow-color;
    position: absolute;
    height: 100%;
    width: 37rem;
    top:0;
    z-index: 99;
    transition:all ease-in-out 0.5s;
    right: -100%;
    padding: 3rem;

    &--active{
      right: 0;
    }

    &__close{
      position: absolute;
      cursor:pointer;
      right: 2rem;
      top: 1rem;
      .font-size(2);
    }

    &__content{
      height: 100%;
      overflow: auto;

      .title{
        .font-size-large;
        .bold-font();
      }

      .documents{
        .flex-display();
        .flex-wrap(wrap);

        .document{
          padding: 1rem;
          display: block;
          width: 33%;

          img{
            width: 100%;
            height: auto;
            object-fit: contain;
          }

          span{
            display: block;
            color: @dark;
            .text-center;
            .font-size(1.1);
            line-height: 1.2;
            margin-top: 1rem;
            .truncate();
            max-width: 10rem;
          }
        }
      }

      .file{
        position: relative;

        .button{
          position: absolute;
          left: 50%;
          .transform(translateX(-50%));
          top: 3rem;
        }

        input{
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;

          &+label{
            border: 1px dashed @border-color;
            background: @body-background;
            border-radius: .4rem;
            .text-center;
            .font-size-medium;
            color: fade(@dark,50%);
            padding: 1rem;
            display: block;
            margin-bottom: 2rem;
            height: 15rem;

            span{
              position: absolute;
              bottom: 3rem;
              width: 100%;
              left: 0;
              .text-center;
            }
          }
        }
      }

      .file-folder{
        position: relative;

        .button{
          position: absolute;
          left: 50%;
          .transform(translateX(-50%));
          top: 3rem;
        }

        input{
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;

          &+label{
            border: 1px dashed @border-color;
            background: @body-background;
            border-radius: .4rem;
            .text-center;
            .font-size-medium;
            color: fade(@dark,50%);
            padding: 1rem;
            display: block;
            margin-bottom: 2rem;
            height: 15rem;

            span{
              position: absolute;
              bottom: 3rem;
              width: 100%;
              left: 0;
              .text-center;
            }
          }
        }
      }
    }
  }
}
