@header-small-height: 6.7rem;
@header-medium-sm-height: 10.8rem;
@header-medium-height: 14rem;
@header-large-height: 21.8rem;
@header-shadow-color: #CED7EC;

.header {
  background: @dark-grey; //fade(@dark, 6%);
  // box-shadow: 0 .2rem .8rem @header-shadow-color;
  left: @main-sidebar-small-width;
  width: 100%;
  top: 0;
  position: fixed;
  .calc(width; "100vw - @{main-sidebar-small-width}");
  z-index: 999;

  @media @large-up {
    .calc(width; "100vw - @{main-sidebar-width}");
    left: @main-sidebar-width;
  }

  @media @xsmall{
    width: 100%;
    left: 0;
    top: 7rem;
    z-index: 99;
  }

  &-static {
    position: static;
    background: @dark-grey;//fade(@dark, 6%);

    @media @xsmall {
      margin-top: 7rem;
    }
  }

  &--with-filters{
    @media @small-down{
      height: 36rem!important;
    }

    @media @xsmall{
      .calc(height; "100vh - 7rem")!important;
    }
  }

  &--small {
    height: @header-small-height;

    &.header--with-filters{
      height: @header-small-height!important;
    }
  }

  &--medium-sm {
    height: @header-medium-sm-height;

    .container {
      height: @header-medium-sm-height;
    }
  }

  &--medium {
    height: @header-medium-height;

    .container {
      height: @header-medium-height;
    }
  }

  &--large {
    height: @header-large-height;

    .container {
      height: @header-large-height;
    }
  }

  .tgl-button{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 0;
    padding-right: 4rem;

    h4{
      color: @black;
      margin-right: 2rem;
      margin-bottom: .9rem;
      .font-size(1.4);
    }
  }

  .filters {
    .flex-display();
    .align-items-center;
    .font-size-medium;
    margin-top: .5rem;

    .fields {
      .flex-display();
      .align-items-center;

      &--row{
        .flex-wrap(wrap);

        .field{
          margin-bottom: 2rem!important;
        }

        .button{
          margin-bottom: 2rem;
        }
      }

      .field {
        margin: 0 1rem;
        .flex-display();
        .align-items-center;

        label {
          margin-right: 1rem;
          white-space: nowrap;
        }

        select, .ng-select{
          min-width: 17rem;
        }

        input,.select{
          margin: 0;
        }

        input[type="checkbox"] + label{
          margin-bottom: 1rem;
        }
      }

      .field--calendar {
        max-width: 15rem;
      }

      .button {
        height: @input-height;
        line-height: @input-height;
      }
    }

    &--columns{
      .align-items(flex-start);

      >span{
        margin-top: 1.2rem;
      }

      form{
        width: 100%;
      }

      .fields{
        .align-items(flex-start);

        >div{
          width: 100%;
        }

        .field--calendar {
          max-width: 100%;
        }

        ng-select{
          width: 100%;
        }

        .button{
          margin-left: auto;
        }
      }
    }
  }

  .steps {
    .flex-display();
    justify-content: center;

    &__item {
      width: 10rem;
      //border-radius: 0.4rem;
      position: relative;
      color: @dark;

      &.disabled {
        pointer-events: none;
      }

      &:last-of-type:after {
        display: none;
      }

      &--finalize .steps__item__check-container:before {
        .center();
        .fonticon_check();
        .font-size(1.8);
        color: @darker;
      }

      &--active .steps__item__check-container {
        background: @primary;
        border-color: @primary;
      }

      &__check-container {
        height: 4.8rem;
        width: 4.8rem;
        position: relative;
        border: 1px solid @dark;
        //border-radius: 2rem;
        margin: auto;
      }

      &__name {
        height: 3.5rem;
        .flex-display();
        .align-items-center;
        align-items: center;
        .text-center;
        font-size: 1.3rem;
        color: @darker;
        line-height: 1;
        .justify-content-center;
      }
    }
  }

  .project-info {
    .flex-display();
    //.align-items-center;
    margin-top: 0rem;

    &__left {
      .flex-display();
      //.align-items-center;
      //flex: 1;

      img {
        width: 5.5rem;
        height: 5.5rem;
        object-fit: contain;
        margin-right: 1rem;
      }

      .placeholder {
        margin-right: 1rem;
        width: 5.5rem;
        height: 5.5rem;
        background-color: @primary;
        color: @dark;
        .text-center;
        border-radius: 50%;
        position: relative;

        span {
          .center();
          .font-size(2);
          .bold-font();
        }
      }

      .info {

        p {
          color: @darker;
          margin: 0 0 0.1rem;
        }

        &__title {
          .font-size-large;
          .bold-font;
          line-height: 1.1;
        }

        &__client, &__project, &__date {
          .font-size-xsmall;
        }

        &__client {
          text-transform: uppercase;
          .truncate();
          max-width: 20rem;
        }

        &__project {
          .icon--copy:before {
            font-size: 1rem;
            margin-left: 0.3rem;
            color: @dark;
          }

          .icon--copy:hover:before {
              color: @primary;
          }
        }


        &__date {
          .icon:before {
            font-size: 0.6rem;
            vertical-align: middle;
          }
        }
      }
    }

    &__center {
      flex: 1;
    }

    &__right {
      flex: 1;
      .text-right;
      .flex-display();
      .justify-content-flex-end;

      button{
        //border: @input-border;
        //background: transparent;
        //color: @dark;
        //height: 4.3rem;

        span{
          &.icon--arrow-right{
            margin-left: 1rem;
            margin-right: -1rem;
          }
        }
      }

      .options-content{
        position: relative;
        margin-left: 1rem;

        &:hover .options-dropdown{
          display: block;
        }

        > .button{
          max-width: 4.3rem;
          position: relative;
          margin-left: .8rem;

          span{

            &:before{
              .center();
              margin: 0;
              font-size: 2.4rem;
            }
          }
        }

        .options-dropdown {
          position: absolute;
          background: @white;
          right: 0;
          box-shadow: 0 .2rem 1.7rem fade(@dark, 30%);
          display: none;
          z-index: 9;

          ul{
            list-style: none;
            margin: 0;
            padding: 1rem 0;

            li{
              margin: 0;
              padding: 0 0.5rem;
              .font-size(1.4);
              .text-left;
              white-space: nowrap;

              &:last-child{
                margin: 0;
              }

              a:not(.button){
                color: @dark;
                padding: 1rem 2rem;
                display: block;

                &:hover {
                  background-color: fade(@primary, 50%);
                }

                span{
                  margin-right: 0.5rem;
                }

              }
            }
          }
        }
      }
    }
  }

  .tab {
    position: absolute;
    bottom: 0;

    &__item {
      .bold-font();
      .font-size-medium();
      color: @dark;
      margin-right: 3.5rem;
      padding-bottom: 1rem;
      display: inline-block;

      &--active {
        border-bottom: 0.7rem solid @dark;
      }
    }
  }

  .dashboard-sections {
    .flex-display();
    margin-top: 3rem;
    width: 100%;

    .section {
      width: 25%;
      .text-center;

      span {
        background: @grey;
        display: inline-block;
        min-width: 20.6rem;
        border-radius: 0.8rem;
        .font-size-medium();
        .bold-font();
        .text-center;
        padding: 1rem;
      }
    }
  }

  .report {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    .flex-display();


    span {
      width: 25%;
      .font-size-medium;
      .bold-font();
      padding: 1rem;
      .text-center;
    }
  }

  .toggle{
    position: absolute;
    right: 2rem;
    top: 50%;
    .transform(translateY(-50%));
    .flex-display();
    .font-size(1.4);
    .align-items-center();

    .label{
      margin-right: 1rem;
    }

    .options{
      .flex-display();
      background: @light-grey;
      border-radius: @border-radius;
      position: relative;
      cursor: pointer;

      &.active{
        .options__active-bg{
          left: 50%;
        }
      }

      &__label{
        padding: 1rem 2rem;
        display: block;
        width: 50%;
        position: relative;
        z-index: 2;
        opacity: 0.5;

        &.active{
          .bold-font();
          opacity: 1;
        }
      }

      &__active-bg{
        background: @primary;
        position: absolute;
        top: 50%;
        .transform(translateY(-50%));
        height: 85%;
        width: 45%;
        border-radius: @border-radius;
        left: 5%;
        box-shadow: 0 .2rem .6rem fade(@dark, 50%);
        transition: all ease-in-out 0.5s;
      }
    }
  }
}

.header-with-tab {
  border-bottom: 0.7rem solid #fff;

  .tab {

    &__item {

      &--active {
        border-bottom: 0.7rem solid @primary;
      }
    }
  }

  .collapse-toggle__icon {
    bottom: 0.7rem;
  }
}

.header-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1.4rem;

  .button {
    height: 4rem;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .button + .button {
    margin-left: -.5rem;
  }
}



app-home{
  .header{
    @media @xsmall{
      height: @header-small-height;
      //display: none;

      .container{
        height: @header-small-height;
      }

      .filters,
      .dashboard-sections{
        display: none;
      }
    }
  }
}

app-project-header-shared{
  @media @xsmall{
    .header{
      height: 16.8rem;

      .container{
        height: 16.8rem;
      }

      .steps{
        margin-top: 1rem;

        .steps__item{
          .steps__item__check-container{
            width: 3rem;
            height: 3rem;
            border-radius: 0.8rem;
          }

          .steps__item__name{
            .font-size(1.2);
            white-space: nowrap;
          }
        }
      }

      .breadcrumbs{
        display: none;
      }

      .project-info{
        margin-top: 1rem!important;
        .flex-direction(column);
        .align-items-flex-start()!important;

        .project-info__left{
          .img{
            display: none!important;
          }
        }

        .project-info__right{
          position: absolute;
          right: 1rem;

          >.button{
            display: none;
          }
        }
      }

      .tab{
        .flex-display();
        overflow-x: auto;
        .calc(width; "100% - 4rem");

        .tab__item{
          .font-size(1.2);
          white-space: nowrap;
        }
      }
    }
  }
}
