#page-documents, .component-documents{
  position: relative;

  .container{
    height: 100%;
    overflow: auto;
    padding-top: 3rem;

    .documents-title{
      .bold-font();
      .font-size-medium;
      margin-bottom: 1rem;
    }
    .documents-content{
      margin-bottom: 3rem;
      .flex-display();
      .flex-wrap(wrap);

      .document{
        padding: 1rem;
        display: block;
        .text-center;
        position: relative;
        width: 12rem;

        &--selected{
          background: fade(@primary, 30%);
          border-radius: 1rem;

          .field{
            display: block!important;
          }
        }

        .field{
          position: absolute;
          .transform(scale(0.8));
          left: 0.5rem;
          top: 0.5rem;
          display: none;

          input:checked + label:after{
            .fonticon_check();
            background: none;
            .font-size(1.8);
          }

          input.numbered-selected  + label:before{
            background-color: @primary;
          }

          input.numbered-selected:checked + label:after{
            content: attr(data-content);
          }
        }

        img{
          width: 7rem;
          height: 10rem;
          //object-fit: contain;
        }

        span{
          display: block;
          color: @dark;
          .text-center;
          .font-size(1.1);
          line-height: 1.2;
          margin-top: 1rem;
          .truncate();
          max-width: 10rem;
        }
      }
    }
  }
}
