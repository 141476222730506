/*
 * Column Pull Classes
 */

.column-pull-1 {
	right: @column-1;
}

.column-pull-1-2,
.column-pull-2-4,
.column-pull-3-6,
.column-pull-4-8,
.column-pull-5-10,
.column-pull-6-12  {
	right: @column-1-2;
}

.column-pull-1-3,
.column-pull-2-6,
.column-pull-3-9,
.column-pull-4-12 {
	right: @column-1-3;
}

.column-pull-2-3,
.column-pull-4-6,
.column-pull-6-9,
.column-pull-8-12 {
	right: @column-1-3 * 2;
}

.column-pull-1-4,
.column-pull-2-8,
.column-pull-3-12 {
	right: @column-1-4;
}

.column-pull-3-4,
.column-pull-6-8,
.column-pull-9-12 {
	right: @column-1-4 * 3;
}

.column-pull-1-5,
.column-pull-2-10 {
	right: @column-1-5;
}

.column-pull-2-5,
.column-pull-4-10 {
	right: @column-1-5 * 2;
}

.column-pull-3-5,
.column-pull-6-10 {
	right: @column-1-5 * 3;
}

.column-pull-4-5,
.column-pull-8-10 {
	right: @column-1-5 * 4;
}

.column-pull-1-6,
.column-pull-2-12 {
	right: @column-1-6;
}

.column-pull-5-6 {
	right: @column-1-6 * 5;
}

.column-pull-1-7 {
	right: @column-1-7;
}

.column-pull-2-7 {
	right: @column-1-7 * 2;
}

.column-pull-3-7 {
	right: @column-1-7 * 3;
}

.column-pull-4-7 {
	right: @column-1-7 * 4;
}

.column-pull-5-7 {
	right: @column-1-7 * 5;
}

.column-pull-6-7 {
	right: @column-1-7 * 6;
}

.column-pull-1-8 {
	right: @column-1-8;
}

.column-pull-3-8 {
	right: @column-1-8 * 3;
}

.column-pull-5-8 {
	right: @column-1-8 * 5;
}

.column-pull-7-8 {
	right: @column-1-8 * 7;
}

.column-pull-1-9 {
	right: @column-1-9;
}

.column-pull-2-9 {
	right: @column-1-9 * 2;
}

.column-pull-4-9 {
	right: @column-1-9 * 4;
}

.column-pull-5-9 {
	right: @column-1-9 * 5;
}

.column-pull-7-9 {
	right: @column-1-9 * 7;
}

.column-pull-8-9 {
	right: @column-1-9 * 8;
}

.column-pull-1-10 {
	right: @column-1-10;
}

.column-pull-3-10 {
	right: @column-1-10 * 3;
}

.column-pull-7-10 {
	right: @column-1-10 * 7;
}

.column-pull-9-10 {
	right: @column-1-10 * 9;
}

.column-pull-1-11 {
	right: @column-1-11;
}

.column-pull-2-11 {
	right: @column-1-11 * 2;
}

.column-pull-3-11 {
	right: @column-1-11 * 3;
}

.column-pull-4-11 {
	right: @column-1-11 * 4;
}

.column-pull-5-11 {
	right: @column-1-11 * 5;
}

.column-pull-6-11 {
	right: @column-1-11 * 6;
}

.column-pull-7-11 {
	right: @column-1-11 * 7;
}

.column-pull-8-11 {
	right: @column-1-11 * 8;
}

.column-pull-9-11 {
	right: @column-1-11 * 9;
}

.column-pull-10-11 {
	right: @column-1-11 * 10;
}

.column-pull-1-12 {
	right: @column-1-12;
}

.column-pull-5-12 {
	right: @column-1-12 * 5;
}

.column-pull-7-12 {
	right: @column-1-12 * 7;
}

.column-pull-10-12 {
	right: @column-1-12 * 10;
}

.column-pull-11-12 {
	right: @column-1-12 * 11;
}


/*
 * Column Pull Extra Large Classes
 */

@media @xlarge {

	//Column Pull Reset
	.column-pull-xlarge-reset {
		right: 0;
	}

	.column-pull-xlarge-1 {
		right: @column-1;
	}

	.column-pull-xlarge-1-2,
	.column-pull-xlarge-2-4,
	.column-pull-xlarge-3-6,
	.column-pull-xlarge-4-8,
	.column-pull-xlarge-5-10,
	.column-pull-xlarge-6-12  {
		right: @column-1-2;
	}

	.column-pull-xlarge-1-3,
	.column-pull-xlarge-2-6,
	.column-pull-xlarge-3-9,
	.column-pull-xlarge-4-12 {
		right: @column-1-3;
	}

	.column-pull-xlarge-2-3,
	.column-pull-xlarge-4-6,
	.column-pull-xlarge-6-9,
	.column-pull-xlarge-8-12 {
		right: @column-1-3 * 2;
	}

	.column-pull-xlarge-1-4,
	.column-pull-xlarge-2-8,
	.column-pull-xlarge-3-12 {
		right: @column-1-4;
	}

	.column-pull-xlarge-3-4,
	.column-pull-xlarge-6-8,
	.column-pull-xlarge-9-12 {
		right: @column-1-4 * 3;
	}

	.column-pull-xlarge-1-5,
	.column-pull-xlarge-2-10 {
		right: @column-1-5;
	}

	.column-pull-xlarge-2-5,
	.column-pull-xlarge-4-10 {
		right: @column-1-5 * 2;
	}

	.column-pull-xlarge-3-5,
	.column-pull-xlarge-6-10 {
		right: @column-1-5 * 3;
	}

	.column-pull-xlarge-4-5,
	.column-pull-xlarge-8-10 {
		right: @column-1-5 * 4;
	}

	.column-pull-xlarge-1-6,
	.column-pull-xlarge-2-12 {
		right: @column-1-6;
	}

	.column-pull-xlarge-5-6 {
		right: @column-1-6 * 5;
	}

	.column-pull-xlarge-1-7 {
		right: @column-1-7;
	}

	.column-pull-xlarge-2-7 {
		right: @column-1-7 * 2;
	}

	.column-pull-xlarge-3-7 {
		right: @column-1-7 * 3;
	}

	.column-pull-xlarge-4-7 {
		right: @column-1-7 * 4;
	}

	.column-pull-xlarge-5-7 {
		right: @column-1-7 * 5;
	}

	.column-pull-xlarge-6-7 {
		right: @column-1-7 * 6;
	}

	.column-pull-xlarge-1-8 {
		right: @column-1-8;
	}

	.column-pull-xlarge-3-8 {
		right: @column-1-8 * 3;
	}

	.column-pull-xlarge-5-8 {
		right: @column-1-8 * 5;
	}

	.column-pull-xlarge-7-8 {
		right: @column-1-8 * 7;
	}

	.column-pull-xlarge-1-9 {
		right: @column-1-9;
	}

	.column-pull-xlarge-2-9 {
		right: @column-1-9 * 2;
	}

	.column-pull-xlarge-4-9 {
		right: @column-1-9 * 4;
	}

	.column-pull-xlarge-5-9 {
		right: @column-1-9 * 5;
	}

	.column-pull-xlarge-7-9 {
		right: @column-1-9 * 7;
	}

	.column-pull-xlarge-8-9 {
		right: @column-1-9 * 8;
	}

	.column-pull-xlarge-1-10 {
		right: @column-1-10;
	}

	.column-pull-xlarge-3-10 {
		right: @column-1-10 * 3;
	}

	.column-pull-xlarge-7-10 {
		right: @column-1-10 * 7;
	}

	.column-pull-xlarge-9-10 {
		right: @column-1-10 * 9;
	}

	.column-pull-xlarge-1-11 {
		right: @column-1-11;
	}

	.column-pull-xlarge-2-11 {
		right: @column-1-11 * 2;
	}

	.column-pull-xlarge-3-11 {
		right: @column-1-11 * 3;
	}

	.column-pull-xlarge-4-11 {
		right: @column-1-11 * 4;
	}

	.column-pull-xlarge-5-11 {
		right: @column-1-11 * 5;
	}

	.column-pull-xlarge-6-11 {
		right: @column-1-11 * 6;
	}

	.column-pull-xlarge-7-11 {
		right: @column-1-11 * 7;
	}

	.column-pull-xlarge-8-11 {
		right: @column-1-11 * 8;
	}

	.column-pull-xlarge-9-11 {
		right: @column-1-11 * 9;
	}

	.column-pull-xlarge-10-11 {
		right: @column-1-11 * 10;
	}

	.column-pull-xlarge-1-12 {
		right: @column-1-12;
	}

	.column-pull-xlarge-5-12 {
		right: @column-1-12 * 5;
	}

	.column-pull-xlarge-7-12 {
		right: @column-1-12 * 7;
	}

	.column-pull-xlarge-10-12 {
		right: @column-1-12 * 10;
	}

	.column-pull-xlarge-11-12 {
		right: @column-1-12 * 11;
	}
}


/*
 * Column Pull Large Classes
 */

@media @large {

	//Column Pull Reset
	.column-pull-large-reset {
		right: 0;
	}

	.column-pull-large-1 {
		right: @column-1;
	}

	.column-pull-large-1-2,
	.column-pull-large-2-4,
	.column-pull-large-3-6,
	.column-pull-large-4-8,
	.column-pull-large-5-10,
	.column-pull-large-6-12  {
		right: @column-1-2;
	}

	.column-pull-large-1-3,
	.column-pull-large-2-6,
	.column-pull-large-3-9,
	.column-pull-large-4-12 {
		right: @column-1-3;
	}

	.column-pull-large-2-3,
	.column-pull-large-4-6,
	.column-pull-large-6-9,
	.column-pull-large-8-12 {
		right: @column-1-3 * 2;
	}

	.column-pull-large-1-4,
	.column-pull-large-2-8,
	.column-pull-large-3-12 {
		right: @column-1-4;
	}

	.column-pull-large-3-4,
	.column-pull-large-6-8,
	.column-pull-large-9-12 {
		right: @column-1-4 * 3;
	}

	.column-pull-large-1-5,
	.column-pull-large-2-10 {
		right: @column-1-5;
	}

	.column-pull-large-2-5,
	.column-pull-large-4-10 {
		right: @column-1-5 * 2;
	}

	.column-pull-large-3-5,
	.column-pull-large-6-10 {
		right: @column-1-5 * 3;
	}

	.column-pull-large-4-5,
	.column-pull-large-8-10 {
		right: @column-1-5 * 4;
	}

	.column-pull-large-1-6,
	.column-pull-large-2-12 {
		right: @column-1-6;
	}

	.column-pull-large-5-6 {
		right: @column-1-6 * 5;
	}

	.column-pull-large-1-7 {
		right: @column-1-7;
	}

	.column-pull-large-2-7 {
		right: @column-1-7 * 2;
	}

	.column-pull-large-3-7 {
		right: @column-1-7 * 3;
	}

	.column-pull-large-4-7 {
		right: @column-1-7 * 4;
	}

	.column-pull-large-5-7 {
		right: @column-1-7 * 5;
	}

	.column-pull-large-6-7 {
		right: @column-1-7 * 6;
	}

	.column-pull-large-1-8 {
		right: @column-1-8;
	}

	.column-pull-large-3-8 {
		right: @column-1-8 * 3;
	}

	.column-pull-large-5-8 {
		right: @column-1-8 * 5;
	}

	.column-pull-large-7-8 {
		right: @column-1-8 * 7;
	}

	.column-pull-large-1-9 {
		right: @column-1-9;
	}

	.column-pull-large-2-9 {
		right: @column-1-9 * 2;
	}

	.column-pull-large-4-9 {
		right: @column-1-9 * 4;
	}

	.column-pull-large-5-9 {
		right: @column-1-9 * 5;
	}

	.column-pull-large-7-9 {
		right: @column-1-9 * 7;
	}

	.column-pull-large-8-9 {
		right: @column-1-9 * 8;
	}

	.column-pull-large-1-10 {
		right: @column-1-10;
	}

	.column-pull-large-3-10 {
		right: @column-1-10 * 3;
	}

	.column-pull-large-7-10 {
		right: @column-1-10 * 7;
	}

	.column-pull-large-9-10 {
		right: @column-1-10 * 9;
	}

	.column-pull-large-1-11 {
		right: @column-1-11;
	}

	.column-pull-large-2-11 {
		right: @column-1-11 * 2;
	}

	.column-pull-large-3-11 {
		right: @column-1-11 * 3;
	}

	.column-pull-large-4-11 {
		right: @column-1-11 * 4;
	}

	.column-pull-large-5-11 {
		right: @column-1-11 * 5;
	}

	.column-pull-large-6-11 {
		right: @column-1-11 * 6;
	}

	.column-pull-large-7-11 {
		right: @column-1-11 * 7;
	}

	.column-pull-large-8-11 {
		right: @column-1-11 * 8;
	}

	.column-pull-large-9-11 {
		right: @column-1-11 * 9;
	}

	.column-pull-large-10-11 {
		right: @column-1-11 * 10;
	}

	.column-pull-large-1-12 {
		right: @column-1-12;
	}

	.column-pull-large-5-12 {
		right: @column-1-12 * 5;
	}

	.column-pull-large-7-12 {
		right: @column-1-12 * 7;
	}

	.column-pull-large-10-12 {
		right: @column-1-12 * 10;
	}

	.column-pull-large-11-12 {
		right: @column-1-12 * 11;
	}
}


/*
 * Column Pull Medium Classes
 */

@media @medium {

	//Column Pull Reset
	.column-pull-medium-reset {
		right: 0;
	}

	.column-pull-medium-1 {
		right: @column-1;
	}

	.column-pull-medium-1-2,
	.column-pull-medium-2-4,
	.column-pull-medium-3-6,
	.column-pull-medium-4-8,
	.column-pull-medium-5-10,
	.column-pull-medium-6-12  {
		right: @column-1-2;
	}

	.column-pull-medium-1-3,
	.column-pull-medium-2-6,
	.column-pull-medium-3-9,
	.column-pull-medium-4-12 {
		right: @column-1-3;
	}

	.column-pull-medium-2-3,
	.column-pull-medium-4-6,
	.column-pull-medium-6-9,
	.column-pull-medium-8-12 {
		right: @column-1-3 * 2;
	}

	.column-pull-medium-1-4,
	.column-pull-medium-2-8,
	.column-pull-medium-3-12 {
		right: @column-1-4;
	}

	.column-pull-medium-3-4,
	.column-pull-medium-6-8,
	.column-pull-medium-9-12 {
		right: @column-1-4 * 3;
	}

	.column-pull-medium-1-5,
	.column-pull-medium-2-10 {
		right: @column-1-5;
	}

	.column-pull-medium-2-5,
	.column-pull-medium-4-10 {
		right: @column-1-5 * 2;
	}

	.column-pull-medium-3-5,
	.column-pull-medium-6-10 {
		right: @column-1-5 * 3;
	}

	.column-pull-medium-4-5,
	.column-pull-medium-8-10 {
		right: @column-1-5 * 4;
	}

	.column-pull-medium-1-6,
	.column-pull-medium-2-12 {
		right: @column-1-6;
	}

	.column-pull-medium-5-6 {
		right: @column-1-6 * 5;
	}

	.column-pull-medium-1-7 {
		right: @column-1-7;
	}

	.column-pull-medium-2-7 {
		right: @column-1-7 * 2;
	}

	.column-pull-medium-3-7 {
		right: @column-1-7 * 3;
	}

	.column-pull-medium-4-7 {
		right: @column-1-7 * 4;
	}

	.column-pull-medium-5-7 {
		right: @column-1-7 * 5;
	}

	.column-pull-medium-6-7 {
		right: @column-1-7 * 6;
	}

	.column-pull-medium-1-8 {
		right: @column-1-8;
	}

	.column-pull-medium-3-8 {
		right: @column-1-8 * 3;
	}

	.column-pull-medium-5-8 {
		right: @column-1-8 * 5;
	}

	.column-pull-medium-7-8 {
		right: @column-1-8 * 7;
	}

	.column-pull-medium-1-9 {
		right: @column-1-9;
	}

	.column-pull-medium-2-9 {
		right: @column-1-9 * 2;
	}

	.column-pull-medium-4-9 {
		right: @column-1-9 * 4;
	}

	.column-pull-medium-5-9 {
		right: @column-1-9 * 5;
	}

	.column-pull-medium-7-9 {
		right: @column-1-9 * 7;
	}

	.column-pull-medium-8-9 {
		right: @column-1-9 * 8;
	}

	.column-pull-medium-1-10 {
		right: @column-1-10;
	}

	.column-pull-medium-3-10 {
		right: @column-1-10 * 3;
	}

	.column-pull-medium-7-10 {
		right: @column-1-10 * 7;
	}

	.column-pull-medium-9-10 {
		right: @column-1-10 * 9;
	}

	.column-pull-medium-1-11 {
		right: @column-1-11;
	}

	.column-pull-medium-2-11 {
		right: @column-1-11 * 2;
	}

	.column-pull-medium-3-11 {
		right: @column-1-11 * 3;
	}

	.column-pull-medium-4-11 {
		right: @column-1-11 * 4;
	}

	.column-pull-medium-5-11 {
		right: @column-1-11 * 5;
	}

	.column-pull-medium-6-11 {
		right: @column-1-11 * 6;
	}

	.column-pull-medium-7-11 {
		right: @column-1-11 * 7;
	}

	.column-pull-medium-8-11 {
		right: @column-1-11 * 8;
	}

	.column-pull-medium-9-11 {
		right: @column-1-11 * 9;
	}

	.column-pull-medium-10-11 {
		right: @column-1-11 * 10;
	}

	.column-pull-medium-1-12 {
		right: @column-1-12;
	}

	.column-pull-medium-5-12 {
		right: @column-1-12 * 5;
	}

	.column-pull-medium-7-12 {
		right: @column-1-12 * 7;
	}

	.column-pull-medium-10-12 {
		right: @column-1-12 * 10;
	}

	.column-pull-medium-11-12 {
		right: @column-1-12 * 11;
	}
}


/*
 * Column Pull Small Classes
 */

@media @small {

	.column-pull-small-1 {
		right: @column-1;
	}

	.column-pull-small-1-2,
	.column-pull-small-2-4,
	.column-pull-small-3-6,
	.column-pull-small-4-8,
	.column-pull-small-5-10,
	.column-pull-small-6-12  {
		right: @column-1-2;
	}

	.column-pull-small-1-3,
	.column-pull-small-2-6,
	.column-pull-small-3-9,
	.column-pull-small-4-12 {
		right: @column-1-3;
	}

	.column-pull-small-2-3,
	.column-pull-small-4-6,
	.column-pull-small-6-9,
	.column-pull-small-8-12 {
		right: @column-1-3 * 2;
	}

	.column-pull-small-1-4,
	.column-pull-small-2-8,
	.column-pull-small-3-12 {
		right: @column-1-4;
	}

	.column-pull-small-3-4,
	.column-pull-small-6-8,
	.column-pull-small-9-12 {
		right: @column-1-4 * 3;
	}

	.column-pull-small-1-5,
	.column-pull-small-2-10 {
		right: @column-1-5;
	}

	.column-pull-small-2-5,
	.column-pull-small-4-10 {
		right: @column-1-5 * 2;
	}

	.column-pull-small-3-5,
	.column-pull-small-6-10 {
		right: @column-1-5 * 3;
	}

	.column-pull-small-4-5,
	.column-pull-small-8-10 {
		right: @column-1-5 * 4;
	}

	.column-pull-small-1-6,
	.column-pull-small-2-12 {
		right: @column-1-6;
	}

	.column-pull-small-5-6 {
		right: @column-1-6 * 5;
	}

	.column-pull-small-1-7 {
		right: @column-1-7;
	}

	.column-pull-small-2-7 {
		right: @column-1-7 * 2;
	}

	.column-pull-small-3-7 {
		right: @column-1-7 * 3;
	}

	.column-pull-small-4-7 {
		right: @column-1-7 * 4;
	}

	.column-pull-small-5-7 {
		right: @column-1-7 * 5;
	}

	.column-pull-small-6-7 {
		right: @column-1-7 * 6;
	}

	.column-pull-small-1-8 {
		right: @column-1-8;
	}

	.column-pull-small-3-8 {
		right: @column-1-8 * 3;
	}

	.column-pull-small-5-8 {
		right: @column-1-8 * 5;
	}

	.column-pull-small-7-8 {
		right: @column-1-8 * 7;
	}

	.column-pull-small-1-9 {
		right: @column-1-9;
	}

	.column-pull-small-2-9 {
		right: @column-1-9 * 2;
	}

	.column-pull-small-4-9 {
		right: @column-1-9 * 4;
	}

	.column-pull-small-5-9 {
		right: @column-1-9 * 5;
	}

	.column-pull-small-7-9 {
		right: @column-1-9 * 7;
	}

	.column-pull-small-8-9 {
		right: @column-1-9 * 8;
	}

	.column-pull-small-1-10 {
		right: @column-1-10;
	}

	.column-pull-small-3-10 {
		right: @column-1-10 * 3;
	}

	.column-pull-small-7-10 {
		right: @column-1-10 * 7;
	}

	.column-pull-small-9-10 {
		right: @column-1-10 * 9;
	}

	.column-pull-small-1-11 {
		right: @column-1-11;
	}

	.column-pull-small-2-11 {
		right: @column-1-11 * 2;
	}

	.column-pull-small-3-11 {
		right: @column-1-11 * 3;
	}

	.column-pull-small-4-11 {
		right: @column-1-11 * 4;
	}

	.column-pull-small-5-11 {
		right: @column-1-11 * 5;
	}

	.column-pull-small-6-11 {
		right: @column-1-11 * 6;
	}

	.column-pull-small-7-11 {
		right: @column-1-11 * 7;
	}

	.column-pull-small-8-11 {
		right: @column-1-11 * 8;
	}

	.column-pull-small-9-11 {
		right: @column-1-11 * 9;
	}

	.column-pull-small-10-11 {
		right: @column-1-11 * 10;
	}

	.column-pull-small-1-12 {
		right: @column-1-12;
	}

	.column-pull-small-5-12 {
		right: @column-1-12 * 5;
	}

	.column-pull-small-7-12 {
		right: @column-1-12 * 7;
	}

	.column-pull-small-10-12 {
		right: @column-1-12 * 10;
	}

	.column-pull-small-11-12 {
		right: @column-1-12 * 11;
	}
}


/*
 * Column Pull  Extra Small Classes
 */

@media @xsmall {

	[class*='column-pull-'] {
		right: auto;
	}

	.column-pull-xsmall-1 {
		right: @column-1;
	}

	.column-pull-xsmall-1-2,
	.column-pull-xsmall-2-4,
	.column-pull-xsmall-3-6,
	.column-pull-xsmall-4-8,
	.column-pull-xsmall-5-10,
	.column-pull-xsmall-6-12  {
		right: @column-1-2;
	}

	.column-pull-xsmall-1-3,
	.column-pull-xsmall-2-6,
	.column-pull-xsmall-3-9,
	.column-pull-xsmall-4-12 {
		right: @column-1-3;
	}

	.column-pull-xsmall-2-3,
	.column-pull-xsmall-4-6,
	.column-pull-xsmall-6-9,
	.column-pull-xsmall-8-12 {
		right: @column-1-3 * 2;
	}

	.column-pull-xsmall-1-4,
	.column-pull-xsmall-2-8,
	.column-pull-xsmall-3-12 {
		right: @column-1-4;
	}

	.column-pull-xsmall-3-4,
	.column-pull-xsmall-6-8,
	.column-pull-xsmall-9-12 {
		right: @column-1-4 * 3;
	}

	.column-pull-xsmall-1-5,
	.column-pull-xsmall-2-10 {
		right: @column-1-5;
	}

	.column-pull-xsmall-2-5,
	.column-pull-xsmall-4-10 {
		right: @column-1-5 * 2;
	}

	.column-pull-xsmall-3-5,
	.column-pull-xsmall-6-10 {
		right: @column-1-5 * 3;
	}

	.column-pull-xsmall-4-5,
	.column-pull-xsmall-8-10 {
		right: @column-1-5 * 4;
	}

	.column-pull-xsmall-1-6,
	.column-pull-xsmall-2-12 {
		right: @column-1-6;
	}

	.column-pull-xsmall-5-6 {
		right: @column-1-6 * 5;
	}

	.column-pull-xsmall-1-7 {
		right: @column-1-7;
	}

	.column-pull-xsmall-2-7 {
		right: @column-1-7 * 2;
	}

	.column-pull-xsmall-3-7 {
		right: @column-1-7 * 3;
	}

	.column-pull-xsmall-4-7 {
		right: @column-1-7 * 4;
	}

	.column-pull-xsmall-5-7 {
		right: @column-1-7 * 5;
	}

	.column-pull-xsmall-6-7 {
		right: @column-1-7 * 6;
	}

	.column-pull-xsmall-1-8 {
		right: @column-1-8;
	}

	.column-pull-xsmall-3-8 {
		right: @column-1-8 * 3;
	}

	.column-pull-xsmall-5-8 {
		right: @column-1-8 * 5;
	}

	.column-pull-xsmall-7-8 {
		right: @column-1-8 * 7;
	}

	.column-pull-xsmall-1-9 {
		right: @column-1-9;
	}

	.column-pull-xsmall-2-9 {
		right: @column-1-9 * 2;
	}

	.column-pull-xsmall-4-9 {
		right: @column-1-9 * 4;
	}

	.column-pull-xsmall-5-9 {
		right: @column-1-9 * 5;
	}

	.column-pull-xsmall-7-9 {
		right: @column-1-9 * 7;
	}

	.column-pull-xsmall-8-9 {
		right: @column-1-9 * 8;
	}

	.column-pull-xsmall-1-10 {
		right: @column-1-10;
	}

	.column-pull-xsmall-3-10 {
		right: @column-1-10 * 3;
	}

	.column-pull-xsmall-7-10 {
		right: @column-1-10 * 7;
	}

	.column-pull-xsmall-9-10 {
		right: @column-1-10 * 9;
	}

	.column-pull-xsmall-1-11 {
		right: @column-1-11;
	}

	.column-pull-xsmall-2-11 {
		right: @column-1-11 * 2;
	}

	.column-pull-xsmall-3-11 {
		right: @column-1-11 * 3;
	}

	.column-pull-xsmall-4-11 {
		right: @column-1-11 * 4;
	}

	.column-pull-xsmall-5-11 {
		right: @column-1-11 * 5;
	}

	.column-pull-xsmall-6-11 {
		right: @column-1-11 * 6;
	}

	.column-pull-xsmall-7-11 {
		right: @column-1-11 * 7;
	}

	.column-pull-xsmall-8-11 {
		right: @column-1-11 * 8;
	}

	.column-pull-xsmall-9-11 {
		right: @column-1-11 * 9;
	}

	.column-pull-xsmall-10-11 {
		right: @column-1-11 * 10;
	}

	.column-pull-xsmall-1-12 {
		right: @column-1-12;
	}

	.column-pull-xsmall-5-12 {
		right: @column-1-12 * 5;
	}

	.column-pull-xsmall-7-12 {
		right: @column-1-12 * 7;
	}

	.column-pull-xsmall-10-12 {
		right: @column-1-12 * 10;
	}

	.column-pull-xsmall-11-12 {
		right: @column-1-12 * 11;
	}
}