 /*
 * Position
 */

// Position Top Left
.position-top-left {
	position: absolute !important;
	top: 0;
	left: 0;

	&.gutter {
		top: @gutter;
		left: @gutter;
	}
}

// Position  Top Right
.position-top-right {
	position: absolute !important;
	top: 0;
	right: 0;

	&.gutter {
		top: @gutter;
		right: @gutter;
	}
}

// Position Bottom Left
.position-bottom-left {
	position: absolute !important;
	bottom: 0;
	left: 0;

	&.gutter {
		bottom: @gutter;
		left: @gutter;
	}
}

// Position  Bottom Right
.position-bottom-right {
	position: absolute !important;
	bottom: 0;
	right: 0;

	&.gutter {
		bottom: @gutter;
		right: @gutter;
	}
}

// Position Left Center
.position-left-center {
	position: absolute !important;
	top: 50%;
	left: 0;
	.translateY(-50%);

	&.gutter {
		left: @gutter;
	}
}

// Position Right Center
.position-right-center {
	position: absolute !important;
	top: 50%;
	right: 0;
	.translateY(-50%);

	&.gutter {
		right: @gutter;
	}
}

// Position Center
.position-center {
	.center();
}
