.icon--gear:before{
  .fonticon_gear();
}

.icon--alert:before{
  .fonticon_alert();
}

.icon--buy-back:before{
  .fonticon_buy-back();
}

.icon--calendar:before{
  .fonticon_calendar();
}

.icon--check:before{
  .fonticon_check();
}

.icon--comment:before{
  .fonticon_comment();
}

.icon--dashboard:before{
  .fonticon_dashboard();
}

.icon--documents:before{
  .fonticon_documents();
}

.icon--edit:before{
  .fonticon_edit();
}

.icon--eye:before{
  .fonticon_eye();
}

.icon--phone:before{
  .fonticon_phone();
}

.icon--report:before{
  .fonticon_report();
}

.icon--roi:before{
  .fonticon_roi();
}

.icon--trash:before{
  .fonticon_trash();
}

.icon--cloud:before{
  .fonticon_cloud();
}

.icon--logout:before{
  .fonticon_logout();
}

.icon--arrow-right:before{
  .fonticon_arrow-right();
}

.icon--options:before{
  .fonticon_options();
}

.icon--time:before{
  .fonticon_time();
}

.icon--zoom-in:before{
  .fonticon_zoom-in();
}

.icon--zoom-out:before{
  .fonticon_zoom-out();
}

.icon--salesman:before{
  .fonticon_salesman();
}

.icon--collapse-down:before{
  .fonticon_collapse-down();
}

.icon--collapse-up:before{
  .fonticon_collapse-up();
}

.icon--collapse-left:before{
  .fonticon_collapse-left();
}

.icon--collapse-right:before{
  .fonticon_collapse-right();
}

.icon--upload:before{
  .fonticon_upload();
}

.icon--trial:before{
  .fonticon_trial();
}

.icon--copy:before{
  .fonticon_copy();
}

.icon--users:before{
  .fonticon_users();
}

.icon--visit:before{
  .fonticon_visit();
}

.icon--internal-action:before{
  .fonticon_internal-action();
}

.icon--supplier:before{
  .fonticon_supplier();
}

.icon--studio:before{
  .fonticon_studio();
}

.icon--machine:before{
  .fonticon_machine();
}

.icon--lab:before{
  .fonticon_lab();
}

.icon--envelope:before{
  .fonticon_envelope();
}

.icon--arrow-left:before{
  .fonticon_arrow-left();
}

.icon--prospecting:before{
  .fonticon_prospecting();
}

.icon--plus:before{
  .fonticon_plus();
}

.icon--invoiced:before{
  .fonticon_invoiced();
}

.icon--sync:before{
  .fonticon_sync();
}

.icon--file:before{
  .fonticon_file();
}

.icon--chevron-up:before{
  .fonticon_chevron-up();
}

.icon--chevron-down:before{
  .fonticon_chevron-down();
}

.icon--chevron-left:before{
  .fonticon_chevron-left();
}

.icon--chevron-right:before{
  .fonticon_chevron-right();
}

.icon--hour:before{
  .fonticon_hour();
}

.icon--close:before{
  .fonticon_close();
}

.icon--options:before{
  .fonticon_options();
}

.icon--small-chevron-right:before{
  .fonticon_small-chevron-right();
}

.icon--zoom-out:before{
  .fonticon_zoom-out();
}

.icon--small-chevron-down:before{
  .fonticon_small-chevron-down();
}

.icon--small-chevron-up:before{
  .fonticon_small-chevron-up();
}

.icon--export-xls:before{
  .fonticon_export-xls();
}

.icon--report-alt:before{
  .fonticon_report-alt();
}

.icon--dashboard-alt:before{
  .fonticon_dashboard-alt();
}

.icon--actions:before{
  .fonticon_actions();
}

.icon--budget:before{
  .fonticon_budget();
}

.icon--diamond:before{
  .fonticon_diamond();
}

.icon--logout-alt:before{
  .fonticon_logout-alt();
}

.icon--gear-alt:before{
  .fonticon_gear-alt();
}

.icon--alert-alt:before{
  .fonticon_alert-alt();
}

.icon--search:before{
  .fonticon_search();
}

.icon--result:before{
  .fonticon_result();
}

.icon--result-filled:before{
  .fonticon_result-filled();
}

.icon--calculator:before{
  .fonticon_calculator();
}

.icon--offline:before{
  .fonticon_offline();
}

.icon--online:before{
  .fonticon_online();
}

.icon--star:before{
  .fonticon_star();
}

.icon--star-filled:before{
  .fonticon_star-filled();
}

.icon--lock:before{
  .fonticon_lock();
}

.icon--unlock:before{
  .fonticon_unlock();
}

