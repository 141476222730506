.box-list {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;

  .box {

    &__item {
      border: 3px solid @primary;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding:4.5rem;
      min-height: 25rem;
    }

    &__link {
      text-align: center;
      color: @primary;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon:before {
        font-size: 3.6rem;
        display: inline-block;
        margin-right: 0;
        margin-bottom: 1.5rem;
      }
    }

    &__title {
      font-size: 2.4rem;
      color: @primary;
      .bold-font;
    }
  }
}

.box-list--two-columns {
  column-gap: 5%;
  row-gap: 4.2rem;

  .box__item {
    width: 47.5%;
  }
}

