.collapse-toggle {
    position: relative;

    &__icon {
        width: 4rem;
        height: 4rem;
        border: 1px solid;
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        .center-flex;
    }

    .icon--collapse-down::before {
        margin-right: 0;
    }

    &.active .icon--collapse-down::before {
       .fonticon_collapse-up;
       margin-right: 0;
    }
}

.tab + .collapse-toggle {
    position: absolute;
    bottom: 0;
    right: @container-padding-large;

    @media @medium-down {
      right: @container-padding-small;
    }
}

/*
.collapsible {
    display: none;
}
*/