#page-login{
  background: @primary;
  height: 100vh;
  background-image: url('/assets/images/home-Iraupen.jpg');
  background-size: cover;

  article{
    width: 100%;
    max-width: 28rem;
    .center();

    img{
      width: 24.8rem;
      margin: auto;
      display: block;
    }

    form{
      //min-width: 28rem;
      margin-top: 4rem;

      .field:first-child {
        margin-bottom: 2.6rem;
      }

      .button {
        width: 100%;
        display: block;
        padding: 1.8rem;
        height: auto;
        line-height: 1;
        background-color: @darker;
      }

      .recover-password{
        .text-center;

        a{
          color: @darker;
        }
      }
    }
  }
}
