#page-machining-study{
  position: relative;

  .container{
    height: 100%;
    overflow: auto;
    padding-top: 3rem;

    .documents-title{
      .bold-font();
      .font-size-medium;
      margin-bottom: 1rem;
    }

    table{
      border-spacing: 0;

      tr{
        th{
          background: @dark;
          color: @white;
        }

        td{
          border: 0;
        }

        &:nth-child(odd){
          td{
            background: @white;
          }
        }
      }
    }
  }
}
