#page-reports {
	background: @dark-grey;

	#reports-filters {

		/* Form filters */
		&.form-filters {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-bottom: 1rem;
			gap: 1.8rem;

			@media (max-width:@screen-medium-small) {
				margin-bottom: 1.5rem;
				justify-content: flex-start;
			}

			&__fields {
				display: flex;
				justify-content: flex-end;
				column-gap: 0.8rem;
			}
		}
	}


	#budgets {
		.cards {
			display: flex;
			justify-content: space-between;
			gap: 1.8rem;
			width: 100%;
			margin-top: 2rem;
		}

		.cards__card {
			width: 25%;
			padding: 1.5rem 2rem;
			.font-size(1.6);
		}

		.cards__card--1 {
			color: @white;
			background: @dark;
		}

		.cards__card--2 {
			background: @dark-grey;
		}

		.cards__card--3 {
			background-color: fade(@dark, 20%);
		}

		.cards__card--4 {
			background: @primary;
		}

		.card__title {
			font-size: 1.6rem;
			line-height: 1.8rem;
			.semibold-font;
			text-transform: uppercase;
			border-bottom: 1px solid @dark;
			padding-bottom: 0.5rem;
			margin-bottom: 1rem;
		}

		.card__title--light {
			border-bottom: 1px solid @white;
		}

		.card__line {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 1.4rem;
		}

		.tables {
			margin-top: 2rem;
			display: flex;
			align-items: flex-start;
			gap: 1.8rem;

			@media @small-down {
				flex-wrap: wrap;
			}

		}

		.tables-column {
			width: 50%;

			@media @small-down {
				width: 100%;
			}

			.table-dropdown-rows {

				margin-bottom: 3rem;

				th:first-child {
					width: 8%;
				}

				th:nth-child(2) {
					width: 40%;
					min-width: 0;
				}

				th:last-child {
					width: 50px;
					min-width: 0;
					text-align: right;

					>div {
						justify-content: flex-end;

						.th-actions {
							border-left: 1px solid fade(@white, 20%);
							padding-left: 1.5rem;
							line-height: 1.3rem;
						}

						.icon--export-xls {
							padding-right: 1rem;
						}

						.icon.icon--chevron-right {
							&:before {
								font-size: 1.5rem;
							}
						}
					}

				}

				th:last-child {
					text-align: center;
					text-transform: initial;

					>div {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						font-size: 1.2rem;
					}

					.icon:before {
						margin-left: 1rem;

						&:hover {
							color: @white;
						}
					}

					.icon.icon--export-xls {
						&:before {
							font-size: 2.5rem;
						}
					}

				}

				/* Tabla ofertas*/
				.offers {
					th {

						&:last-child {
							.th-actions {
								border-left: 1px solid fade(@black, 20%);
							}
						}

						color:@dark;
						background:@dark-grey;
					}
				}

				/* Tabla no previstos */
				.no-planned {

					th {

						&:last-child {
							.th-actions {
								border-left: 1px solid fade(@black, 20%);
							}
						}

						color:@dark;
						background-color: fade(@dark, 20%);
					}

					th:nth-child(2) {
						width: 40%;
					}

					td:nth-child(2) {
						width: 40%;
						min-width: 0;
					}

					td:nth-child(3),
					td:nth-child(4) {
						width: 15%;
						min-width: 0;
					}

					td:nth-child(4) {
						text-align: center;
					}

					td td:last-child {
						text-align: center;
						min-width: 0;
						//padding-right: 0;
						padding-left: 1rem;
						padding-right: 1rem;
					}

					.sub-header td:last-child {
						text-align: center;
					}
				}
			}

			.toogle-content {
				td td {
					&:nth-child(2) {
						width: 40%;
						min-width: 0;
					}

					&:last-child {
						//width: 50px;
						min-width: 0;
						padding-right: 1.25rem;
					}
				}
			}


		}

		.action-bar__actions {
			.icon.icon--export-xls:before {
				font-size: 2rem;
			}
		}


	}

	#delivery-notes {
		//height: 100vh;
		padding-bottom: 3rem;

		.container {
			background-color: @white;
		}

		/* Table  */

		.table-dropdown-rows {
			margin-top: 3rem;

			@media @medium-up {

				td:first-child,
				th:first-child {
					width: 4%;
					//min-width: 50px;
				}

				td:nth-child(2) {
					width: 50%;
					//min-width: 300px;
					//padding-left: 0;
				}

				td:nth-child(3) {
					width: 18%;
				}

				td:nth-child(4) {
					width: 18%;
				}

				td:last-child {
					width: 10%;
				}

			}

			th:last-child {
				width: 15%;
				min-width: 120px;
				text-align: center;
				text-transform: initial;

				>div {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					padding-right: 2rem;
					font-size: 1.2rem;
				}

				.icon:before {
					margin-left: 1rem;

					&:hover {
						color: @white;
					}
				}

				.icon.icon--export-xls {
					&:before {
						font-size: 2.5rem;
					}
				}
			}
		}
	}
}


// (Estilos fuera de ID para poder reutilizarlos)
.reports-heading {
	display: flex;
	align-items: center;
	margin-top: 2rem;


	&__title {
		margin: 0 1rem 0 0;
		font-size: 1.9rem;
		line-height: 1;
		color: @darker;
	}

	.tag {
		padding: 0.8rem 1.2rem;
		font-size: 1.4rem;
		line-height: 1;
		color: @darker;
		border: 1px solid @darker;
		.bold-font;

		&--primary {
			background-color: @primary;
			border-color: @primary;
			height: 3.2rem;
			display: flex;
			align-items: center;
		}

    &--secondary {
			background-color: @secondary;
			border-color: @secondary;
      color: @white;
			height: 3.2rem;
			display: flex;
			align-items: center;
		}

		&--percentage {
			background-color: @dark;
			color: @primary;
		}
	}

	.tag+.tag {
		margin-left: 0.8rem;
	}
}

.reports-charts {
	display: flex;
	align-items: stretch;
	column-gap: 5%;
	margin-top: 2.4rem;
	.calc(height; "100vh - 32rem");

	&__column {
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 4%;
	}

	&__column:first-child {
		width: 40%;
	}

	&__column:last-child {
		width: 55%;

		.reports-charts__section {
			//height: 48%;
		}
	}

	&__section {
		//margin-top: 2.4rem;
		flex: 1 auto;
		display: flex;
		flex-direction: column;

		.reports-charts__title {
			margin: 0 0 1rem !important;
		}
	}

	&__title {
		color: @darker;
		font-size: 1.8rem;
		//margin: 0 0 1rem;
		margin: 1.6rem 0 0.8rem !important;
		.bold-font;

		.title {
			display: block;
			margin: 0 0 0.5rem;
			font-size: 1.8rem;
			.bold-font;
		}

		.subtitle {
			display: block;
			margin: 0 0 1.5rem;
			font-size: 1.6rem;
			.regular-font;
		}
	}

	&__subtitle {
		display: block;
		color: @darker;
		text-transform: uppercase;
		margin: 0;
	}

	&__graph {
		flex: 1 auto;
		//background-color: @dark;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		column-gap: 2rem;

		.button-grey {
			height: 3rem;
			background-color: @alt-grey;
			color: @darker;
			height: 3rem;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border: none;
		}

	}

	&__header--sm-column {
		@media @small-down {
			flex-direction: column;
			row-gap: 1rem;
		}
	}

	&__filters {
		flex: 1 auto;
	}

	img {
		max-width: 100%;
		height: auto;
	}
}

.reports-charts--customers {
	margin-top: 3.6rem;
	.calc(height; "100vh - 34rem");

	@media @small-down {
		.calc(height; "100vh - 36rem");
		column-gap: 4%;
	}

	.reports-charts {

		&__column {
			height: 100%;
			display: flex;
			flex-direction: column;
			gap: 4%;
		}

		&__column:first-child {
			width: 63%;

			@media @small-down {
				width: 60%;
			}

			/*.reports-charts__section:first-child {
				height: 57%;
			}

			.reports-charts__section:last-child {
				height: 38%;
			}*/
		}

		&__column:last-child {
			width: 32%;

			@media @small-down {
				width: 36%;
			}
		}

		&__section {
			//margin-top: 2.4rem;
			flex: 1 auto;
			display: flex;
			flex-direction: column;
		}

		&__title {
			color: @darker;
			font-size: 1.8rem;
			margin: 0 0 1rem;
			.bold-font;
		}

		&__subtitle {
			display: block;
			color: @darker;
			text-transform: uppercase;
			margin: 0;
		}

		&__graph {
			flex: 1 auto;
			//background-color: @grey;
		}

		img {
			max-width: 100%;
			height: auto;
		}
	}
}

.report-graph {
	padding: 10px 20px;
	margin-top: 10px;


	.reports-charts {
		&__title {
			margin: 1rem 0 0 !important;
		}
	}

	&--dark {
		background-color: #484549;

		.reports-charts {
			&__title {
				color: white;
			}
		}

	}
}

#reports-filters {

	// field checkbox (up to)
	.field--checkbox {
		min-width: 4.8rem;
		min-height: 7.1rem;

		@media @small-down {
			min-width: 4rem;
			min-height: 6.3rem;

		}

		input[type="checkbox"] {
			&+label {
				padding: 0;
				margin: 0;
				min-height: 4.8rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				line-height: 1.5;
			}

			&+label:before {
				top: 2.3rem;
			}

			&:checked+label:after {
				top: 2.3rem;
			}
		}
	}
}

.form-client-box {
	margin-top: 2rem;
	justify-content: flex-start;
	margin-bottom: 0;
	gap: 1.2rem;

	@media @small-down {
		margin-top: 0.5rem;
		flex-direction: column;
		gap: 0.8rem;
		align-items: flex-start;

		.field--checkbox {
			min-height: 4rem !important;

			input[type="checkbox"] {
				&+label {
					padding-left: 5.2rem !important;
					min-height: 4rem  !important;
					flex-direction: row !important;
					align-items: center !important;
				}

				&+label:before {
					top: 0 !important;
				}

				&:checked+label:after {
					top: 0 !important;
				}
			}
		}
	}
}

.reports-charts--providers {
  height: auto;
}


.report-percentajes-component {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px;
  background-color: #f4f4f4;

  .title {
    margin-right: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }

  .item {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 10px;
    margin-right: 10px;
    background-color: #f9f9f9;

    span:first-child {
      margin-right: 5px;
      color: #555;
      font-weight: 500;
    }

    span:last-child {
      color: #000;
      font-weight: 600;
    }
  }
  .item:last-child {
    margin-right: 0;
  }
}
