// mixins for iraupen forms
.chevron-down() {
	background-image: url('/assets/images/chevron-down.svg');
	background-repeat: no-repeat;
	background-position: right 1.6rem top 50%, 0 0;
	background-size: 1.4rem;
}

.form-filters {
	display: flex;
	align-items: flex-end;
	margin-bottom: 1.8rem;

	&__column3 {
		width: 30%;
		margin-right: 1.6rem;
		max-width: 29.5rem;

		&:last-child {
			margin-right: 0;
		}
	}

	&__column4 {
		width: 23.125%;
		margin-right: 2.5%;
		max-width: 29.5rem;
	}

	div.form-filters__column4:nth-of-type(4n) {
		margin-right: 0;
	}

	// .fields {}

	.field {
		margin-bottom: 0;
	}

	.field--margin {
		margin-bottom: 2.4rem;
	}

	.field--small-margin {
		margin-bottom: 1rem;
	}

	label {
		margin: 0;
		color: @darker;
	}

	input[type="text"],
	input[type="date"],
	input[type="time"],
	.field input,
	.field .select {
		margin: 0.2rem 0 0;
	}

	.ng-select .ng-select-container {
		border-radius: 0 !important;
	}

	select {
		height: 4.8rem !important;
		padding-right: 3.6rem;
		padding-left: 1.6rem;
		border: 1px solid @dark-grey;
		.appearance(none);
		background-color: #FFF;
		margin-top: 0.2rem;
		border-radius: 0 !important;
		.chevron-down;

		&::-ms-expand {
			/* For IE */
			display: none;
		}
	}

	.select {
		&:after {
			display: none;
		}

		select:hover,
		select:focus {
			.chevron-down;
		}
	}

	.ng-select.ng-select-single .ng-select-container {
		.chevron-down;
	}

	.ng-select .ng-arrow-wrapper {
		display: none;
	}

	input[type="date"],
	app-datepicker-field input {
		position: relative;
		padding-right: 3.6rem;
		background-image: url('/assets/images/calendar.svg');
		background-repeat: no-repeat;
		background-position: right 1.6rem top 50%, 0 0;
		background-size: 1.4rem;

		&::-webkit-calendar-picker-indicator {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			color: transparent;
			background: 0 0;
			margin: 0;
			opacity: 0;
			pointer-events: auto;
		}
	}

	input[type="time"] {
		background-image: url('/assets/images/hour.svg');
		background-repeat: no-repeat;
		background-position: right 1.6rem top 50%, 0 0;
		background-size: 1.4rem;

		&::-webkit-calendar-picker-indicator {
			background: none;
		}
	}

	input.duration {
		background-image: url('/assets/images/time.svg');
		background-repeat: no-repeat;
		background-position: right 1.6rem top 50%, 0 0;
		background-size: 1.4rem;

		&::-webkit-calendar-picker-indicator {
			background: none;
		}

	}

	input[type="checkbox"] {
		&+label {
			padding-left: 6rem;
			margin: 0;
			min-height: 4.8rem;
			display: flex;
			align-items: center;
			line-height: 1.25;

			@media @small-down {
				min-height: 4rem;
			}
		}

		&+label:before {
			content: '';
			border: 1px solid @dark-grey;
			border-radius: 0;
			height: 4.8rem;
			width: 4.8rem;
			position: absolute;
			left: 0;
			top: 0;
			background: #FFFFFF;

			@media @small-down {
				height: 4rem;
				width: 4rem;
			}
		}

		&:checked+label:after {
			background-color: transparent;
			content: '';
			width: 4.8rem;
			height: 4.8rem;
			border-radius: 0;
			position: absolute;
			left: 0;
			top: 0;
			background-image: url('/assets/images/check.svg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 50%;

			@media @small-down {
				height: 4rem;
				width: 4rem;
			}
		}
	}

	input,
	select,
	.ng-select .ng-select-container {
		@media @small-down {
			height: 4rem !important;
		}
	}


	input[type="submit"] {
		background-color: @primary;
		color: @darker;
		margin: 0;

		@media @small-down {
			height: 4rem;
			line-height: 4rem;
		}
	}

	.checkbox-group {
		display: flex;
		justify-content: center;
		padding: 1rem 1rem 0;
		border: 0;

		&__item {
			display: block;
			width: 9rem;
			margin: 0 2.4rem;
			text-align: center;

			@media @small-down {
				width: 6rem;
			}
		}

		input[type="checkbox"],
		input[type="radio"] {
			opacity: 1;
			position: static;
			z-index: 1;
			border: 1px solid @dark-grey;
			border-radius: 0;
			height: 4.8rem;
			width: 4.8rem;
			background: #FFFFFF;

			@media @small-down {
				width: 4rem;
				height: 4rem;
			}

			&:checked {
				background-image: url('/assets/images/check.svg');
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 50%;
				-webkit-transition: none;
				transition: none;
			}

			&+label {
				display: block;
				padding: 0.25rem;
			}

			&+label:before {
				display: none;
			}

			&:checked+label:after {
				display: none;
			}
		}
	}

	.field--small {
		input,
		select {
			height: 3rem !important;
		}

		select {
			padding: 0 2.4rem  0 0.8rem;
			min-width: 8rem;
		}

		.select select,
		.select select:hover,
		.select select:focus {
			background-position: right 0.6rem top 50%, 0 0;
		}
	}
}

.form-filters--rows {
	align-items: flex-start;

	.form-filters__submit {
		padding-top: 2.2rem;
	}
}

.form-filters--columns {
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
}

.field-range-group {
	display: flex;
	column-gap: 1.2rem;
	align-items: flex-end;
	flex: 1 auto;

	.form-filters__fields {
		display: flex;
		justify-content: flex-end;
		column-gap: 1.2rem;
	}

	// field checkbox (in range filter group)
	.field--checkbox {
		min-width: 4rem;
		min-height: 5.3rem;

		input[type="checkbox"] {
			&+label {
				padding: 0;
				margin: 0;
				min-height: 4rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				line-height: 1.5;
			}

			&+label:before {
				top: 2.3rem;
				width: 3rem;
				height: 3rem;
			}

			&:checked+label:after {
				top: 2.3rem;
				left: 0;
				background-size: 1.4rem 1.4rem;
				width: 3rem;
				height: 3rem;
			}
		}
	}
}

.form-filters--gap-small {
	margin-bottom: 0;
	gap: 1.8rem;

	@media (max-width:@screen-medium-small) {
		margin-bottom: 1.5rem;
		justify-content: flex-start;
	}

	.form-filters__fields {
		display: flex;
		justify-content: flex-end;
		column-gap: 0.8rem;
	}
}

.form-filters--gap {
	margin-bottom: 0;
	gap: 1.8rem;

	@media (max-width:@screen-medium-small) {
		margin-bottom: 1.5rem;
		justify-content: flex-start;
	}

	.form-filters__fields {
		display: flex;
		justify-content: flex-end;
		column-gap: 1.6rem;
	}
}

.form-filters--start {
	justify-content: flex-start;
}

.form-filters--end {
	justify-content: flex-end;
}

.compare-fields {

	&__label {
		font-size: 1.4rem;
		margin-top: 2rem;
		margin-left: 1.6rem;
		display: flex;
		align-items: center;
		.bold-font;
	}
}