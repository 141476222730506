h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dd,
dt,
ul > li,
ol > li {

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}