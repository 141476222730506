#page-admin-form{
  padding-top: 5rem;

  .form{
    max-width: 79rem;
    margin: auto;

    &--small{
      max-width: 36.5rem;
    }

    h2{
      .text-center;
      .font-size-large;
      color: @dark;
    }

    .title{
      max-width: 36.5rem;
      margin: auto auto 2rem;
    }

    .subtitle{
      .font-size-medium;
      .bold-font();
      .text-center;
      border-bottom: .8rem solid @grey;
      padding-bottom: 1rem;
      margin-top: 3rem;
      margin-bottom: 2rem;
    }

    .info{
      .font-size(2.2);
      .bold-font();
    }
  }

  .text-left a {
    color: @dark;
  }
}
