#page-create{
  padding-top: 3rem;

  .title{
    max-width: 36rem;
    margin: auto auto 2rem;
  }

  .fields{
    max-width: 95rem;
    margin: auto;

    .field{
      &--file{
        input[type="file"]{
          display: none;
        }

        label{
          .flex-display();

          span{
            &.filename{
              display: inline-block;
              width: 100%;
              border: @input-border;
              padding: @input-padding;
              .font-size(@input-font-size);
              height: @input-height;
              background-color: @input-background;
              border-radius: @input-border-radius 0 0 @input-border-radius;
              .calc(max-width;"100% - 18rem");
              .truncate;
              line-height: @input-height;
            }

            &.button{
              height: @input-height;
              border-radius: 0 @input-border-radius @input-border-radius 0;
              line-height: @input-height;
              width: 18rem;
            }
          }
        }
      }
    }

    .inline-create-button{
      .flex-display();
      position: relative;

      .button{
        position: absolute;
        right: 0;
        bottom: 3.4rem;
        height: @input-height;
        line-height: @input-height;
      }

      app-select-field{
        .calc(width; "100% - 10rem");
      }
    }
  }
}
