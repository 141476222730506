/*
 * Background
 */

.background-transparent {
	background: transparent !important;
}

//Shades
.background-white {
	background: @white !important;
}

.background-light-grey {
	background: @light-grey  !important;
}

.background-grey {
	background: @grey !important;
}

.background-dark-grey {
	background: @dark-grey !important;
}

.background-black {
	background: @black !important;
}

//Indicators
.background-error {
	background: @error !important;
}

.background-warning {
	background: @warning !important;
}

.background-success {
	background: @success !important;
}

.background-info {
	background: @info !important;
}

//Palette
.background-primary {
	background: @primary !important;
}

.background-secondary {
	background: @secondary !important;
}

.background-tertiary {
	background: @tertiary !important;
}

.background-dark {
	background: @dark !important;
}

.background-light {
	background: @light !important;
}

//Material

.ng-dropdown-panel-items .ng-option:not(-marked) {
	&.background-steel {
		background: @steel !important;
	}

	&.background-stainless {
		background: @stainless !important;
	}

	&.background-non-ferrous {
		background: @non-ferrous !important;
	}

	&.background-cast-iron {
		background: @cast-irons !important;
	}

	&.background-super-alloys {
		background: @super-alloys !important;
	}

	&.background-powder {
		background: @powder !important;
	}

	&.background-composite {
		background: @composite !important;
	}

	&.background-hardened-steel {
		background: @hardened-steel !important;
	}
}
