.title{
  .font-size-medium;
  .bold-font();
  .text-center;

  &--grey{
    background: @grey;
    padding: 1rem;
    border-radius: .8rem;
  }
}
