#page-admin-list{
  padding-top: 3rem;

  .table--permissions{
    .section td{
      background: #D7DCE8;
      height: 6rem;
      .bold-font();
      border-right: 0!important;
    }

    td{
      .field{
        margin-bottom: 0;
        max-width: 18rem;

        .ng-select .ng-select-container{
          height: 3.5rem!important;

          .ng-value-container .ng-input > input{
            height: 3.5rem;
          }
        }
      }

      &:first-of-type{
        border-right: 1px solid @dark;
        max-width: 8rem;
      }
    }
  }
}
