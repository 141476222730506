#page-notifications {
  //padding-top: 1rem;
  //height: 100vh;
  //width:100%;
  background-color: @dark-grey; //fade(@dark, 6%);

  .container {
    background-color: #FFF;
  }

  .container--tabs {
    padding-top: 1rem;
    background-color: transparent;
  }

  .filters {
    margin: 2.4rem 0 2rem;
    text-align: right;

    .button:last-child {
      .margin-left-xsmall;

      @media @xsmall {
        .margin-top-xsmall;
      }
    }
  }

  .notification {
    margin-bottom: 0.8rem;
    background-color: @dark;

    &__list {
      list-style-type: none;
      margin: 0;
      padding: 1.6rem 1.2rem;
      .flex;
      .align-items-center;

      @media @medium-small-down {
        flex-wrap: wrap;
        padding: 1rem 1.6rem;
      }


      li {
        color: #FFF;
        margin: 0;
        padding: 0 1.2rem 0 0;

        @media @medium-down {
          font-size: 1.4rem;
        }

        @media @medium-small-down {
          font-size: 1.3rem;
          margin: 1.2rem 0;
        }

        &:last-child {
          padding-right: 0;

          @media @medium-small-down {
            margin-top: -3.5rem;
          }

          @media @xsmall {
            margin-top: 0;
          }
        }

      }
    }

    &__tel {
      width: 5%;
      min-width: 4.8rem;

      span:before {
        color: @primary;
        font-size: 1.8rem;
        padding: 0.8rem;
        border-radius: 50%;
        border: 1px solid @primary;
        text-align: center;
      }
    }

    &__company {
      width: 25%;
      .truncate;

      @media @medium-small-down {
        width: 65%;
      }

      @media @xsmall {
        width: 100%;
      }
    }

    &__subject {
      width: 40%;
      font-weight: @bold-font-weight;
      .truncate();

      @media @medium-down {
        width: 36%;
      }

      @media @medium-small-down {
        width: 45%;
      }

      @media @xsmall {
        width: 100%;
        .no-truncate;
      }
    }

    &__person {
      width: 12%;

      @media @medium-down {
        width: 16%;
        .truncate();
        .text-center;
      }

      @media @medium-small-down {
        width: 20%;
      }

      @media @xsmall {
        width: 70%;
        text-align: left;
      }

    }

    &__hour {
      width: 8%;
      text-align: center;

      @media @medium-small-down {
        width: 14%;
      }

      @media @xsmall {
        width: 30%;
        text-align: right;
      }
    }


    &__state {
      width: 10%;

      @media @medium-small-down {
        width: 15%;
        min-width: 9rem;
      }

      @media @xsmall {
        width: 100%;

      }

      .button {
        width: 100%;
        padding: 0 1rem;
        border: 1px solid @primary;
        cursor: default;

        &:hover {
          background-color: transparent;
        }

        &-primary:hover {
          background-color: @primary;
        }
      }
    }

  }

  // modifier for old notifications
  .notification--old {

    .notification {
      &__date {
        width: 8%;
        min-width: 9rem;
        font-size: 1.2rem;

        date {
          color: @dark;
          background-color: @primary;
          // display: inline-block;
          padding: 0.2rem 0.5rem;
          height: 1.6rem;
        }
      }

      &__company {
        width: 22%;

        @media @medium-small-down {
          width: 65%;
        }

        @media @xsmall {
          width: 100%;
        }
      }

      &__subject {
        width: 35%;

        @media @medium-small-down {
          width: 50%;
        }

        @media @xsmall {
          width: 100%;
        }
      }

    }

  }

}
