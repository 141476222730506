.section-item(){
  &__item{
    height: 22.2rem;
    background: @white;
    .calc(width; "100% - 2rem");
    margin: 1.2rem auto;
    box-shadow: 0 .2rem .9rem rgb(206, 215, 236);
    border-radius: .8rem;
    padding: 1.5rem;
    position: relative;
    overflow: hidden;
    display: block;
    color: @dark;
    border: 1px solid transparent;

    @media @xsmall{
      width: 100%;
    }

    &:hover{
     border: 1px solid @dark;
    }

    &--error{
      &:before{
        content: '';
        width: 100%;
        height: 100%;
        border: 2px solid @error;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: .8rem;
        background: fade(@error,7%);
        z-index: 11;
      }
    }

    &--active{
      &:before{
        content: '';
        width: 100%;
        height: 100%;
        border: 2px solid @primary;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: .8rem;
        background: fade(@primary,7%);
        z-index: 11;
      }
    }

    &__header{
      .flex-display();
      .align-content-center;

      &__left{
        img{
          width: 4rem;
          height: 4rem;
          object-fit: cover;
        }

        .placeholder{
          width: 4rem;
          height: 4rem;
          background-color: @dark;
          color: @primary;
          .text-center;
          border-radius: 50%;
          position: relative;

          span{
            .center();
            .font-size(2);
            .bold-font();
          }
        }
      }

      &__right{
        margin-left: 1rem;
        .flex-display();
        .align-items-flex-start;
        height: 4rem;
        overflow: hidden;

        p{
          .font-size(1.3);
          line-height: 1.2;
          margin: 0 0 0.2rem;
          .flex-display();
          .justify-content-space-between;

          @media @medium-up{
            .font-size-medium;
          }
        }
      }
    }

    &__body{
      .text-center;
      margin-top: 1.5rem;
      color: @dark;

      .title{
        .font-size(1.4);
        height: 3.5rem;
        overflow: hidden;
        line-height: 1.2;
      }

      .inCharge{
        .font-size-small();
        margin-bottom: 1rem;
      }
    }

    .tools{
      .flex-display();
      .justify-content-center;

      div, a{
        width: 4.4rem;
        height: 4rem;
        border-radius: .8rem;
        background: @grey;
        margin: 0 0.5rem;
        position: relative;
        color: currentColor;
        display: block;

        .icon{
          .center();

          &:before{
            .font-size(2);
            margin: 0;
          }
        }

        .number{
          width: 3rem;
          height: 3rem;
          background: @grey;
          border: 2px solid @white;
          display: block;
          position: absolute;
          border-radius: 50%;
          .flex-display();
          .font-size-small();
          .text-center;
          .justify-content-center;
          .align-items-center;
          right: -1rem;
          bottom: -1rem;

          &--error{
            background: @error;
            color: @white;
          }
        }
      }

      a:hover{
        background: @primary;
        color: @dark;
      }
    }

    &__progress{
      position: absolute;
      background: @progress-background-color;
      height: @progress-height;
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }
}

.progress-bar() {
  height: 2.5rem;
  background: @grey;
  display: block;
  .calc(width; "100vw - @{main-sidebar-small-width}");
  position: fixed;
  z-index: 20;
  left: @main-sidebar-small-width;

  @media @medium-up{
    .calc(width; "100vw - @{main-sidebar-width}");
    left: @main-sidebar-width;
  }

  div{
    position: absolute;
    height: 100%;
    background: @success;
  }
}

.center-flex() {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle() {
  border-radius: 50%;
  .center-flex;
}

.circle-small() {
  font-size: 1rem;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  width: 1.8rem;
  height: 1.8rem;
  .circle;
}
