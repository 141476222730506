@responsive-header: 14rem;

.main-page{
  overflow: auto;
  overflow-x: hidden;
  .calc(width; "100vw - @{main-sidebar-small-width}");
  position: relative;

  &--static {
    overflow: hidden;

    &.main-page--action-bar-active{
      //margin-bottom: 7.7rem;
      margin-bottom: 9.1rem;
    }
  }

  @media @large-up{
    .calc(width; "100vw - @{main-sidebar-width}");
  }

  @media @xsmall{
    width: 100vw;
  }

  &--header-medium{
    margin-top: @header-medium-height;
    .calc(height; "100% - @{header-medium-height}");

    @media @xsmall{
      margin-top: @responsive-header;
      .calc(height; "100% - (@{responsive-header})");
    }

    &.main-page--action-bar-active{
      .calc(height; "100% - ( @{header-medium-height} + @{action-bar-height} + @{action-bar-padding-top})");
    }
  }

  &--header-large{
    margin-top: @header-large-height;
    .calc(height; "100% - @{header-large-height}");

    &.main-page--action-bar-active{
      .calc(height; "100% - ( @{header-large-height} + @{action-bar-height} + @{action-bar-padding-top})");
    }
  }

  &--header-medium-sm{
    margin-top: @header-medium-sm-height;
    .calc(height; "100% - @{header-medium-sm-height}");

    @media @xsmall{
      margin-top: @responsive-header;
      .calc(height; "100% - (@{responsive-header})")!important;
    }

    &.main-page--action-bar-active{
      .calc(height; "100% - ( @{header-medium-sm-height} + @{action-bar-height} + @{action-bar-padding-top})");
    }

    @media @xsmall{
      margin-top: @responsive-header;
      .calc(height; "100% - (@{responsive-header} + @{action-bar-height} + @{action-bar-padding-top})")!important;
    }
  }

  &--header-small,
  &--header-small-s{
    margin-top: @header-small-height;
    .calc(height; "100% - @{header-small-height}");

    @media @xsmall{
      margin-top: @responsive-header;
      .calc(height; "100% - (@{responsive-header})")!important;
    }

    &.main-page--action-bar-active{
      .calc(height; "100% - ( @{header-small-height} + @{action-bar-height} + @{action-bar-padding-top})");

      @media @xsmall{
        margin-top: @responsive-header;
        .calc(height; "100% - (@{responsive-header} + @{action-bar-height} + @{action-bar-padding-top})")!important;
      }
    }
  }

  .action-bar{
    bottom: -100%
  }

  &--action-bar-active{
    .action-bar{
      bottom: 0;
    }
  }
}
