/*
 * Utility Classes
 */

.clearfix {
	.clearfix();
}

.center-block {
	.center-block();
}

.cover {
	.cover();
}

.center {
	.center();
}

.full-height {
	height: 100%;
}

.fixed {
	position: fixed;
}

.pointer {
	cursor: pointer;
}

.no-margin {
	margin: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.no-border {
	border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.fleximage {
	flex-shrink: 0;
}

.units-box{
  font-size: .8em;
  color: gray
}

.point {
	display: block;
	width: 1.6rem;
	height: 1.6rem;
	background: fade(@dark, 40%);
	margin-left: auto;
	margin-right: auto;
	border-radius: 99px;

	&.point--checked {
		background: @primary;
	}
}

.line {
  height: 0;
  border-top: 7px solid @primary;
  margin: 0 auto 3rem;
}

.line--transparent {
  border-top: 7px solid transparent;
  background: transparent;
}

.empty-box {
	margin: 3.6rem auto;
	max-width: 52rem;
	text-align: center;

	img {
		margin-bottom: 2rem;
	}

	&__text {
		font-size: 2.4rem;
	}
}

.flex-wrap-md {
  @media @small-down {
      flex-wrap: wrap;
  }
}

// tailwind helpful utility classes

// margin utility classes
// margin classes
@iterations: 10;

.margin-loop (@i) when (@i >= 0) {	

	.mt-@{i} {
		margin-top: (@i*0.4rem) !important;
	}

	.mb-@{i} {
		margin-bottom: (@i*0.4rem) !important;
	}

	.ml-@{i} {
		margin-left: (@i*0.4rem) !important;
	}

	.mr-@{i} {
		margin-right: (@i*0.4rem) !important;
	}

	.margin-loop(@i - 1);
}

// call the mixin
.margin-loop (@iterations);

.mt-auto {
	margin-top: auto;
}

.mb-auto {
	margin-bottom: auto;
}

.ml-auto {
	margin-left: auto;
}

.mr-auto {
	margin-right: auto;
}

.p-0 {
	padding: 0 !important;
}

// flex classes
.flex {
	display: flex;
}

.inline-flex {
	display: inline-flex;
}	

// Flex Wrap
.flex-wrap	{
	flex-wrap: wrap;
}

.flex-wrap-reverse	{
	flex-wrap: wrap-reverse;
}

.flex-nowrap	{
	flex-wrap: nowrap;
}

// Flex Direction
.flex-row	{
	flex-direction: row;
}

.flex-row-reverse	{
	flex-direction: row-reverse;
}

.flex-col	{
	flex-direction: column;
}

.flex-col-reverse	{
	flex-direction: column-reverse;
}

// Flex

// allow a flex item to grow and shrink as needed, ignoring its initial size
.flex-1	{
	flex: 1 1 0%;
}

// allow a flex item to grow and shrink, taking into account its initial size
.flex-auto	{
	flex: 1 1 auto;
}

// allow a flex item to shrink but not grow, taking into account its initial size
.flex-initial	{
	flex: 0 1 auto;
}

// prevent a flex item from growing or shrinking
.flex-none	{
	flex: none;
}

// justify content
.justify-normal	{
	justify-content: normal;
}

.justify-start	{
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}	
.justify-center	{
	justify-content: center;
}

.justify-between	{
	justify-content: space-between;
}

.justify-around	{
	justify-content: space-around;
}

.justify-evenly	{
	justify-content: space-evenly;
}

.justify-stretch	{
	justify-content: stretch;
}

// align items
.items-start	{
	align-items: flex-start;
}

.items-end	{
	align-items: flex-end;
}

.items-center	{
	align-items: center;
}

.items-baseline	{
	align-items: baseline;
}

.items-stretch	{
	align-items: stretch;
}

// gap 

// margin classes
@iterations: 10;

.gap-loop (@i) when (@i >= 0) {	

	.gap-@{i} {
		gap: (@i*0.4rem);
	}

	.gap-x-@{i} {
		column-gap: (@i*0.4rem);
	}

	.gap-y-@{i} {
		row-gap: (@i*0.4rem);
	}

	.gap-loop(@i - 1);
}

// call the mixin
.gap-loop (@iterations);