/*
 * Definitions
 */

// Root Size
@root-size: 10px;
@root-size-xlarge: @root-size;
@root-size-large: @root-size;
@root-size-medium: @root-size;
@root-size-small: @root-size - 1;
@root-size-xsmall: @root-size - 1;


/*
 * Root Size
 */

:root {
	font-size: @root-size;

  @media @medium-down {
    // font-size: @root-size - 1;
  }
}
