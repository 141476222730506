.loader-content{
  position: fixed;
  //background: fade(@white, 60%);
  background: transparent;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: 999;

  .loader{
    width: 6em;
    height: 6em;
    margin: auto;
    position: absolute;
    .center();

    &__child {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: @primary;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-animation: sk-double-bounce 2s infinite ease-in-out;
      animation: sk-double-bounce 2s infinite ease-in-out;

      &--item2{
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
      }
    }
  }

  @-webkit-keyframes sk-double-bounce {
    0%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }

  @keyframes sk-double-bounce {
    0%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }
}
