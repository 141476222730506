#page-end{
  padding-top: 3rem;

  .content{
    max-width: 45rem;
    margin: auto;
  }

  .title{
    max-width: 36rem;
    margin: auto auto 4rem;
  }

  .field--file{
    position: relative;

    .button{
      position: absolute;
      left: 50%;
      .transform(translateX(-50%));
      top: 3rem;
    }

    input{
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;

      &+label{
        border: 1px dashed @border-color;
        background: @body-background;
        border-radius: .4rem;
        .text-center;
        .font-size-medium;
        color: fade(@dark,50%);
        padding: 1rem;
        display: block;
        margin-bottom: 2rem;
        height: 15rem;

        span{
          position: absolute;
          bottom: 3rem;
          width: 100%;
          left: 0;
          .text-center;
        }
      }
    }
  }

  .field--checkbox-tag{
    .text-center;
    margin-bottom: 4rem;
  }

  .auto-center-input{
    max-width: 25rem;
    margin: auto;
  }
}
