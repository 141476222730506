@header-breadcrumbs-font-size: @font-size-medium;

.breadcrumbs{

  &--header{
    text-transform: uppercase;
    padding: 1.3rem 0 1rem;
    min-height: 6.8rem;
    .font-size(@header-breadcrumbs-font-size);
    .flex-display();
    .align-items-center;


    .collapse{
      height: 4.3rem;
      width: 4.3rem;
      border: @input-border;
      display: inline-block;
      margin-right: 1rem;
      border-radius: 0.5rem;
      position: relative;
      color: @dark;
      cursor: pointer;

      &:before{
        .center();
      }

      &--up:before{
        .fonticon_collapse-up();
      }

      &--down:before{
        .fonticon_collapse-down();
      }
    }

    a.breadcrumbs__item{
      .regular-font();
    }

    .breadcrumbs__item{
      margin-right: 1rem;
      color: @darker;
      .regular-font();

      &.active,
      &:last-child {
        .bold-font();
      }

      &:last-child:after{
        display: none;
      }

      &:after{
        content: '/';
        margin-left: 1rem;
      }
    }

    .breadcrumbs__item--back {
      border: 1px solid;
      width: 4rem;
      height: 4rem;
      margin-right: 1.6rem;
      .center-flex;

      &:after{
        display: none;
      }

      .icon:before {
        margin-right: 0;
      }
    }
  }
}
