.tabs {
    height:auto;
    margin:0 auto;

    @media @xsmall {
       .margin-top-xsmall;
    }

    // lista de las pestañas
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        margin: 0;
        padding: 0;
        text-align: center;
        background-color: fade(@dark, 20%);
        margin-right: 1.2rem;

        @media @xsmall {
            font-size: 1.4rem;
            width: 45%;
        }

        &:hover {
            background-color: fade(@dark, 40%); ;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        text-decoration: none;
        color: @darker;
        display: inline-block;
        padding: 1.2rem 2.4rem;
        font-weight: @bold-font-weight;

        @media @xsmall {
            width: 100%;
            height: 100%;
        }
    }

    &__item.active a {
        background-color: @primary;
    }



    // contenido de las pestañas
    &__section {
        display:none;
        background-color:#FFF;
        overflow: auto;
        min-height:25rem;
        .calc(height; "100vh - 23.4rem");
    }

    // pestañas activas
    &__item.active {
        display: block;
        background-color:#FFF;
    }

    &__item.active a{
        color:#2f3648;
    }
    &__section.active {
        display: block;
    }
}