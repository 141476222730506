.tgl {
  display: none!important;

  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .tgl-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .tgl-btn {
    outline: 0;
    display: block;
    width: 6.1rem;
    height: 2.6rem;
    position: relative;
    cursor: pointer;
    user-select: none;
    background: @dark-grey;
    border-radius: 2em;
    padding: 2px;
    transition: all .4s ease;
    border: 1px solid #e8eae9;
    top:0;

    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 38%;
      height: 90%;
    }

    &:after {
      left: 2px;
      top: 1px;
      border-radius: 2em;
      background: @primary;
      transition:
              left .3s cubic-bezier(
                      0.175, 0.885, 0.320, 1.275
              ),
              padding .3s ease, margin .3s ease;
      box-shadow:
              0 0 0 1px rgba(0,0,0,.1),
              0 4px 0 rgba(0,0,0,.08);
    }

    &:before {
      display: none;
    }

    &:hover:after {
      will-change: padding;
    }

    &:active {
      box-shadow: inset 0 0 0 2em #e8eae9;
      &:after {
        padding-right: .8em;
      }
    }
  }

  &:checked + .tgl-btn:after {
    left: 62%;
    background-color: @white;
    top: 3px;
    border-radius: 2em;
    width: 33%;
    height: 75%;
  }

  &:checked + .tgl-btn {
    background: @primary;
    &:active {
      box-shadow: none;
      &:after {
        margin-left: -.8em;
      }
    }
  }
}